
import { connect } from 'react-redux';

import OrganizationService from '../services/OrganizationService';
import ParamOrganizations from '../components/Admin/ParamOrganisations';
import { setAdminGroups, removeAdminGroup } from '../redux/actions';

const mapStateToProps = state => ({
  items: state.aGroups,
});

const mapDispatchtoProps = dispatch => ({
  fetchData: () => {
    setAdminGroups(null);
    OrganizationService.fetchAll()
      .then(res => dispatch(setAdminGroups(res.data.Groups)))
      .catch(err => console.log('OrganizationService@fetchAll', err.message));
  },
  onDelete: id => {
    OrganizationService.delete(id)
      .then(() => dispatch(removeAdminGroup(id)))
      .catch(err => console.log('OrganizationService@delete', err.message));
  },
});

export default connect(mapStateToProps, mapDispatchtoProps)(ParamOrganizations);
