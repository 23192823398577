import { API } from "../api/request";

process.env["NODE_TLS_REJECT_UNAUTHORIZED"] = 0;

class UserService {
  fetchAll = () => API.get("front/users/all");

  getData = () => API.get("front/users/");

  register = (data) => API.post("register", data);

  getById = (id) => API.get(`front/users/${id}`);

  updateUserById = (id, data) =>
    API.patch(`front/users/edit/${id}`, data, {
      headers: { "Content-Type": "application/merge-patch+json" },
    });

  deleteUserById = (id) => API.delete(`front/users/${id}`);

  add = (data) => API.post("front/users/add", data);

  getMeasurePDF = (ids) => {
    return API.post(`front/plots/reports`, { ids });
  };

  // eslint-disable-next-line camelcase
  sendMeasure = (blob, exploitation_id, plot) => {
    const file = new FormData();
    file.append("file", blob);
    file.append("exploitation_id", exploitation_id);
    file.append("plot", plot);
    file.append("report_link", window.location.href);
    return API.post("front/send-measure", file, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  };
}

const instance = new UserService();

export default instance;
