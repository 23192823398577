/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PDFDownloadLink } from '@react-pdf/renderer';
import set from 'lodash/fp/set';

import Alert from '../../../Alert';
import EditPlot from './edit/EditPlot';
import InputSelect from '../../../InputSelect';
import Input from '../../../Input';
import PlotMeasures from './measures/PlotMeasures';
import { STONE_OPTIONS } from '../constants';
import MeasurePDF from '../../../MeasurePDF';
import TextureService from '../../../../services/TextureService'

function PlotContent({
  data,
  onSelectMeasure,
  setEditedMode,
  editedMode,
  handleChange,
  handleSubmit,
}) {
  const { t, i18n } = useTranslation('generics');
  const [measureSelected, setMeasureSelected] = useState([]);
  const [textures, setTextures] = useState([])
  const [textureOption, setTextureOption] = useState([])

  const formatData = () => (
    set('organization[0].exploitation[0].plot[0].pilotage[0].measureGroup', measureSelected, data)
  );

  useEffect(() => {
    console.log(data
      ?.organization[0]
      ?.exploitation[0]
      ?.plot[0]
      ?.texture)
    TextureService.fetchAll()
      .then((result) => {
        setTextures(result.data.data)
        formatTextureOption(result.data.data)
      })
      .catch((e) => {
        console.error(e.message)
        
      })
  }, [i18n])

  const formatTextureOption = (options) => {
    let currentLang = i18n.language.slice(0,2).toLowerCase(); 
    //let currentLang = i18n.language ? i18n.language : "en"
    //console.log(`${currentLang}_${currentLang.toUpperCase()}`);
    let opts = []
    options.forEach((opt) => {
      let objectEntries = new Map(Object.entries(opt))
      opts.push({value: objectEntries.get("id"), label: objectEntries.get(`${currentLang}_${currentLang.toUpperCase()}`)})
    })
    setTextureOption(opts)
  }

  const getNameTexture = (texture) => {
    if(!texture) return texture
    let currentLang = i18n.language.slice(0,2).toLowerCase();   
    //let currentLang = i18n.language ? i18n.language : "en"
    let textureEntries = new Map(Object.entries(texture))
    return textureEntries.get(`${currentLang}_${currentLang.toUpperCase()}`)
  }

  const getValueTexture = (texture) => {
    if(typeof texture === "number") {
      return texture
    } else {
      return texture?.id
    }
  }

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-lg-5'>
          <div className='border border-grey border-large p-4'>
            <div className='text-primary font-weight-bold'>{t('pages.plot.content.Details')}</div>
            <div className='row'>
              <div className='col-md-6'>
                <div className='row py-3 text-info align-items-center'>
                  <div className='col-md-4'>
                    {t('pages.plot.content.Ilot')}
                  </div>
                  <div className='col-md-8'>
                    {!editedMode ? <b className='ml-3'>{data.organization[0].exploitation[0].plot[0].ilot}</b> : (
                      <Input
                        className='plot-small-input'
                        type='number'
                        min={0}
                        name='ilot'
                        onChange={val => handleChange('organization[0].exploitation[0].plot[0].ilot', val, true)}
                        value={data.organization[0].exploitation[0].plot[0].ilot}
                      />
                    )}
                  </div>
                </div>
                <div className='row py-3 text-info align-items-center'>
                  <div className='col-md-4'>
                    {t('pages.plot.content.Texture')}
                  </div>
                  <div className='col-md-8'>
                    {!editedMode ? <b className='ml-3'>{getNameTexture(data.organization[0].exploitation[0].plot[0].texture) || '-'}</b> : (
                      <InputSelect
                        className='plot-small-input'
                        name='texture'
                        onChange={val => handleChange('organization[0].exploitation[0].plot[0].texture', +val)}
                        options={textureOption}
                        value={getValueTexture(data.organization[0].exploitation[0].plot[0].texture)}
                      />
                    )}
                  </div>
                </div>
                <div className='row py-3 text-info align-items-center'>
                  <div className='col-md-4'>
                    {t('pages.plot.content.Stone')}
                  </div>
                  <div className='col-md-8'>
                    {!editedMode ? <b className='ml-3'>{data.organization[0].exploitation[0].plot[0].stone || '-'}</b> : (
                      <InputSelect
                        className='plot-small-input'
                        name='stone'
                        onChange={val => handleChange('organization[0].exploitation[0].plot[0].stone', val)}
                        options={STONE_OPTIONS}
                        value={data
                          ?.organization[0]
                          ?.exploitation[0]
                          ?.plot[0]
                          ?.stone
                          ?.toUpperCase()}
                      />
                    )}
                  </div>
                </div>
                <div className='row py-3 text-info align-items-center'>
                  <div className='col-md-4'>
                    {t('pages.plot.content.Ground')}
                  </div>
                  <div className='col-md-8'>
                    {!editedMode ? <b className='ml-3'>{data.organization[0].exploitation[0].plot[0].groundDepth || '-'} cm</b> : (
                      <div className='d-flex align-items-center p-0'>
                        <div className='flex-1'>
                          <Input
                            className='plot-small-input'
                            type='number'
                            min={0}
                            name='groundDepth'
                            onChange={val => handleChange('organization[0].exploitation[0].plot[0].groundDepth', val, true)}
                            value={data.organization[0].exploitation[0].plot[0].groundDepth}
                          />
                        </div>
                        <div className='text-info ml-2'><b>cm</b></div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row py-3 text-info align-items-center'>
                  <div className='col-md-4'>
                    {t('pages.plot.content.Organic material')}
                  </div>
                  <div className='col-md-8'>
                    {!editedMode ? <b className='ml-3'>{data.organization[0].exploitation[0].plot[0].organicMaterial || '-'}%</b> : (
                      <div className='d-flex align-items-center p-0'>
                        <div className='flex-1'>
                          <Input
                            className='plot-small-input'
                            type='number'
                            min={0}
                            name='organicMaterial'
                            onChange={val => handleChange('organization[0].exploitation[0].plot[0].organicMaterial', val, true)}
                            value={data
                              ?.organization[0]
                              ?.exploitation[0]
                              ?.plot[0]
                              ?.organicMaterial}
                          />
                        </div>
                        <div className='text-info ml-2'><b>%</b></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='row py-3 text-info align-items-center'>
                  <div className='col-md-12'>
                    {t('pages.plot.content.Dose to be brougth according to balance sheet')}
                  </div>
                  <div className='col-md-12'>
                    {!editedMode ? <b>{data.organization[0].exploitation[0].plot[0].pilotage && data.organization[0].exploitation[0].plot[0].pilotage[0] ? data.organization[0].exploitation[0].plot[0].pilotage[0].overallDose : '0'} kgN/ha</b> : (
                      <Input
                        className='plot-small-input'
                        type='number'
                        min={0}
                        name='dose'
                        onChange={val => handleChange('organization[0].exploitation[0].plot[0].pilotage[0].overallDose', val, true)}
                        value={data.organization[0].exploitation[0].plot[0].pilotage
                          && data.organization[0].exploitation[0].plot[0].pilotage[0] ?
                            data.organization[0].exploitation[0].plot[0].pilotage[0].overallDose : '0'
                        }
                      />
                    )}
                  </div>
                </div>
                <div className='row py-3 text-info align-items-center'>
                  <div className='col-md-12'>
                    {t('pages.plot.content.Performance objective')}
                  </div>
                  <div className='col-md-12'>
                    {!editedMode ? <b>{data.organization[0].exploitation[0].plot[0].pilotage && data.organization[0].exploitation[0].plot[0].pilotage[0] ? data.organization[0].exploitation[0].plot[0].pilotage[0].performanceObjective : '0'} t/ha</b> :(
                      <Input
                        className='plot-small-input'
                        type='number'
                        min={0}
                        name='dose'
                        onChange={val => handleChange('organization[0].exploitation[0].plot[0].pilotage[0].performanceObjective', val, true)}
                        value={data.organization[0].exploitation[0].plot[0].pilotage
                          && data.organization[0].exploitation[0].plot[0].pilotage[0] ?
                            data.organization[0].exploitation[0].plot[0].pilotage[0].performanceObjective : '0'
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-7'>
          <div className='text-primary font-weight-bold mb-3'>{t('pages.plot.content.Measures')}</div>
          {data.organization[0]
            ?.exploitation[0]
            ?.plot[0]
            ?.pilotage?.length > 0 && data.organization[0]
            ?.exploitation[0]
            ?.plot[0]
            ?.pilotage[0]
            ?.measureGroup?.length > 0 ? (
              <PlotMeasures
                items={data}
                onSelectMeasure={measure => {
                  onSelectMeasure(measure, {
                    plot: data,
                  });
                }}
                measureSelected={measureSelected}
                setMeasureSelected={setMeasureSelected}
              />
          ) : (
            <Alert message={t('pages.plot.alerts.No measure for this plot')} theme='info' />
          ) }
        </div>
      </div>
      <div className='text-right pt-2'>
        {!editedMode && (measureSelected.length > 0) && (
          <>
            <PDFDownloadLink
              className='btn text-uppercase btn-yellow m-2'
              document={(
                <MeasurePDF data={formatData()} />
              )}
              fileName='measureReport.pdf'
            >
              {({ loading }) => (loading ? t('pages.reports.Loading document') : t('pages.reports.Export PDF'))}
            </PDFDownloadLink>
          </>
        )}
        <EditPlot
          setEditedMode={setEditedMode}
          editedMode={editedMode}
          handleSubmit={handleSubmit}
        />
      </div>
    </div>
  );
}

PlotContent.displayName = 'Borealis-Plot-Content';
PlotContent.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    surface: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ilot: PropTypes.string,
    texture: PropTypes.string,
    stone: PropTypes.string,
    groundDepth: PropTypes.string,
    organicMaterial: PropTypes.string,
    pilotage: PropTypes.arrayOf(PropTypes.shape({
      overallDose: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      performanceObjective: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      cropName: PropTypes.string,
      cropId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      univers: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      varietyClass: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      varietyName: PropTypes.string,
    })),
  }).isRequired,
  onSelectMeasure: PropTypes.func.isRequired,
  editedMode: PropTypes.bool.isRequired,
  setEditedMode: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default PlotContent;
