import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ReactLoading from 'react-loading';

import { useTranslation } from 'react-i18next';
import AddCultureEntity from './AddCultureEntity';
import CultureService from '../../../services/CultureService';
import SubscriptionsTable from './SubscriptionsTable';

const CultureStep1 = ({
  items,
  universId,
  changeActivation,
  addSubscription,
  onSelectSubscription,
  deleteSubscription
}) => {
  const { t } = useTranslation('generics');
  const getItem = () => items.find(univers => univers.id === universId);

  const culture_action = [
    {
      name: "delete_culture",
      func: (cultureId) => {
        deleteSubscription({
          yearId : cultureId,
          univers_id: getItem().id
        })
      }
    }
  ]

  useEffect(() => {
    CultureService.on('selectSubscription', idSubscription => onSelectSubscription(idSubscription));
  }, [onSelectSubscription]);

  const verifyHasOnlyOneSubscription = (data) => {
    changeActivation(data)
    getItem().subscriptions.forEach((s) => {
      if(s.id !== data.id && s.isActive) {
        s.isActive = false
        changeActivation(s)
      }
    })
  }

  return (
    <div>
      {items && getItem() ? (
        <div>
          <div className='text-info text-uppercase mb-3'>
            {t('pages.parameters.culture.Culture')} / <b>{t('pages.parameters.culture.Year')}</b>
          </div>
          <div className='text-primary font-weight-bold mb-2 text-uppercase'>{t('pages.parameters.culture.Year')}</div>
          <SubscriptionsTable
            items={getItem().subscriptions}
            onChangeActivation={data => changeActivation(data) & verifyHasOnlyOneSubscription(data)}
            action={culture_action}
          />
          <AddCultureEntity
            title={t('pages.parameters.culture.Add a subscription')}
            placeholder={t('pages.parameters.culture.Year')}
            name='subscription_name'
            onAdd={year => addSubscription({
              year,
              univers_id: getItem().id,
              isActive: '',
            })}
          />
        </div>
      ) : (<ReactLoading type='bubbles' color='#8f8f8f' />)}
    </div>
  );
};

CultureStep1.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    subscriptions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      year: PropTypes.number.isRequired,
      isActive: PropTypes.bool.isRequired,
    })).isRequired,
  })),
  universId: PropTypes.number,
  changeActivation: PropTypes.func.isRequired,
  addSubscription: PropTypes.func.isRequired,
};

export default CultureStep1;
