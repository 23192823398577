import EventEmitter from '../domain/EventEmitter';
import { API } from '../api/request';

class TextureService extends EventEmitter {
  fetchAll = () => API.get('front/textures');
}

const instance = new TextureService();

export default instance;
