import PropTypes from 'prop-types';
import React from 'react';
import ReactLoading from 'react-loading';

import { useTranslation } from 'react-i18next';
import PageTitle from '../PageTitle';
import EntityRelations from '../EntityRelations';

const ParamPilotUsers = ({ item, userList, handleOnAdd, handleOnDelete }) => {
  const { t } = useTranslation('generics');

  if (item) {
    return (
      <div>
        <div className='mb-3'>
          <PageTitle title={t('pages.parameters.pilots.users.Pilot management users')} />
        </div>
        <table className='table table-striped'>
          <thead className='thead-dark'>
            <tr>
              <th>{t('pages.parameters.pilots.Id')}</th>
              <th>{t('pages.parameters.pilots.Serial number')}</th>
              <th>{t('pages.parameters.pilots.Users')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{item.id}</td>
              <td>{item.serialNumber}</td>
              <td>
                <EntityRelations
                  name='users'
                  onAdd={user => handleOnAdd({
                    id: item.id,
                    user,
                  })}
                  onDelete={userId => handleOnDelete({
                    id: item.id,
                    user_to_remove: userId,
                  })}
                  options={userList}
                  values={item.users.map(user => ({
                    id: user.id,
                    name: `${user.firstname} ${user.lastname}`,
                  }))}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className='d-flex justify-content-center vh-100'>
      <ReactLoading type='bubbles' color='#8f8f8f' />
    </div>
  );
};

ParamPilotUsers.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    serialNumber: PropTypes.string.isRequired,
    subscriptions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      year: PropTypes.number.isRequired,
      isActive: PropTypes.bool.isRequired,
      universId: PropTypes.number.isRequired,
      universName: PropTypes.string.isRequired,
      universCode: PropTypes.string.isRequired,
    })),
  }),
  userList: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  })),
  handleOnAdd: PropTypes.func.isRequired,
  handleOnDelete: PropTypes.func.isRequired,
};

export default ParamPilotUsers;
