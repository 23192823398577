import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';

import { useTranslation } from 'react-i18next';
import AdvicesTable from './AdvicesTable';
import Alert from '../../Alert';
import CultureService from '../../../services/CultureService';
import PreviousButton from './PreviousButton';
import StagesTable from './StagesTable';
import Tabs from '../../Tabs';
import VarietiesTable from './VarietiesTable';
import InputImageWithLabel from '../../InputImageWithLabel';

const CultureStep3 = ({ cropId }) => {
  const { t } = useTranslation('generics');
  const [crop, setCrop] = useState(null);
  const [error, setError] = useState(null);
  const [importing, setImporting] = useState(false);

  useEffect(() => {
    async function fetch() {
      CultureService.fetchCropDetail(cropId)
        .then(res => setCrop(res.data.crop))
        .catch(err => setError(err.message));
    }
    fetch();
  }, [setError, setCrop, cropId]);

  const importCrop = data => {
    setImporting(true);
    CultureService.importCrop(data, cropId)
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        setImporting(false);
      });
  };

  const makeTabs = () => [
    {
      id: `crop_${cropId}_growth-stages`,
      title: t('pages.parameters.culture.Growth stages'),
      component: (<StagesTable items={crop.stages} />),
    },
    {
      id: `crop_${cropId}_varieties`,
      title: t('pages.parameters.culture.Varieties'),
      component: (<VarietiesTable items={crop.varieties} />),
    },
    {
      id: `crop_${cropId}_advices`,
      title: t('pages.parameters.culture.Advices'),
      component: (<AdvicesTable items={crop.advices} />),
    },
  ];

  return (
    <div>
      {error && (<Alert message={error} />)}
      {crop ? (
        <div>
          <div className='text-info text-uppercase mb-3'>
            {t('pages.parameters.culture.Culture')} / {t('pages.parameters.culture.Year')} / {t('pages.parameters.culture.Crop')} / <b>{t('pages.parameters.culture.Details')}</b>
          </div>
          {importing ? (<ReactLoading type='bubbles' color='#8f8f8f' />) : (
            <InputImageWithLabel
              label={t('pages.parameters.culture.Import')}
              name='image'
              onChange={file => importCrop(file)}
            />
          )
          }
          <div className='text-primary font-weight-bold mb-2 text-uppercase'>{t('pages.parameters.culture.Details')}</div>
          <Tabs elements={makeTabs()} />
          <div className='py-2'>
            <PreviousButton />
          </div>
        </div>
      ) : (<ReactLoading type='bubbles' color='#8f8f8f' />)}
    </div>
  );
};

CultureStep3.propTypes = {
  cropId: PropTypes.number.isRequired,
};

export default CultureStep3;
