import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import icPilot from '../../images/ic_pilot.png';

const Pilot = ({ serialNumber }) => {
  const { t } = useTranslation('generics');

  return (
    <div className='pilot text-info'>
      <div className='icon'>
        <img src={icPilot} alt='pilot icon' width='37' />
      </div>
      <div className='w-100'>
        {t('pages.profile.N-pilot serial number')}
        <b className='d-block'>{serialNumber}</b>
        <div className='line my-1' />
      </div>
    </div>
  );
};

Pilot.propTypes = {
  serialNumber: PropTypes.string.isRequired,
};

export default Pilot;
