import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactLoading from 'react-loading';

import { useTranslation } from 'react-i18next';
import Alert from '../Alert';
import InputImageWithLabel from '../InputImageWithLabel';
import InputSelectWithLabel from '../InputSelectWithLabel';

import Button from '../Button';

const AddTelepacForm = ({ onSubmit, onChange, error = null, exploitations = null, idCountry }) => {
  const [exploitationSelected, setExploitationsSelected] = useState(false);
  const { t } = useTranslation('generics');

  return (
    <div className='row'>
      {error && (<div className='col-12'><Alert message={error} /></div>)}
      <div className='col-md-12 mb-3 text-center'>
        {idCountry === 1 && (
          <InputImageWithLabel
            label={`${t('pages.plot.telepac.file')} .TXT`}
            name='txt'
            onChange={img => onChange({ champ: 'txt', value: img })}
          />
        )}
        <InputImageWithLabel
          label={`${t('pages.plot.telepac.file')} .XML`}
          name='xml'
          onChange={img => onChange({ champ: 'xml', value: img })}
        />
        { exploitations ? (

          <InputSelectWithLabel
            label={t('pages.plot.telepac.exploitation')}
            name='exploitation'
            options={exploitations}
            value={exploitationSelected}
            onChange={val => {
              onChange({ champ: 'exploitation_id', value: val });
              setExploitationsSelected(val);
            }}
          />
        ) : (
          <ReactLoading type='bubbles' color='#8f8f8f' />
        )}
        <Button title={t('pages.plot.telepac.add')} onClick={() => onSubmit()} />
      </div>
    </div>
  );
};

AddTelepacForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,

};

export default AddTelepacForm;
