import React from 'react';
import PropTypes from 'prop-types';

const Switch = ({ name, onChange, checked = false }) => {
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      onChange();
    }
  };

  return (
    <label className='hc-switch' htmlFor={name}>
      <input type='checkbox' name={name} checked={checked} onChange={onChange} />
      <span className='slider' onClick={onChange} role='button' tabIndex='0' onKeyPress={handleKeyPress} />
    </label>
  );
};

Switch.propTypes = {
  checked: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Switch;
