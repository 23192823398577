
import EventEmitter from '../domain/EventEmitter';
import { API } from '../api/request';

class OrganizationService extends EventEmitter {
  edit = data => {
    const formData = new FormData();
    Object.keys(data).forEach(item => {
      if (item === 'logo') {
        formData.append(item, data[item], data[item].name);
      }
      if (data[item].length) {
        formData.append(item, data[item]);
      }
    });

    return API.post(`front/groups/edit/${data.id}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  fetchAll = () => API.get('front/groups/');

  getById = id => API.get(`front/groups/${id}`);

  delete = id => API.delete(`front/groups/${id}`);
}

const instance = new OrganizationService();

export default instance;
