import PropTypes from 'prop-types';
import React from 'react';

const TitleOffset = ({ title }) => (
  <h2 className='has-offset text-uppercase text-info pt-5 mb-3' data-title={title}>
    { title }
  </h2>
);

TitleOffset.propTypes = {
  title: PropTypes.string.isRequired,
};

export default TitleOffset;
