import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  body: {
    fontFamily: 'Roboto',
    paddingTop: 35,
    paddingBottom: 100,
    paddingHorizontal: 35,
    fontSize: 13,
  },
  borders: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'grey',
    padding: 5,
  },
  title: {
    paddingBottom: 8,
    color: '#71BF44',
  },
  informationRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerTab: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'black',
    borderRight: 'none',
  },
  rowTab: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'black',
    borderTop: 'none',
    borderRight: 'none',
  },
  cell: {
    borderRight: 'solid',
    borderRightWidth: 1,
    borderRightColor: 'black',
    textAlign: 'center',
    justifyContent: 'center',
    paddingVertical: 2,
  },
  pinsContainer: {
    position: 'relative',
  },
  pins: {
    backgroundColor: 'red',
    width: 20,
    height: 20,
    borderBottomLeftRadius: 50,
    borderTopLeftRadius: 50,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 50,
    transform: 'rotate(45deg)',
    zIndex: 0,
  },
  pinsPurple: {
    backgroundColor: '#8000ff',
    width: 20,
    height: 20,
    borderBottomLeftRadius: 50,
    borderTopLeftRadius: 50,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 50,
    transform: 'rotate(45deg)',
    zIndex: 0,
  },
  pinsOrange: {
    backgroundColor: '#ff8400',
    width: 20,
    height: 20,
    borderBottomLeftRadius: 50,
    borderTopLeftRadius: 50,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 50,
    transform: 'rotate(45deg)',
    zIndex: 0,
  },
  pinsValue: {
    zIndex: 1,
    position: 'absolute',
    top: 5,
    width: 20,
    fontSize: 11,
    textAlign: 'center',
    color: 'white',
  },
  greenHighlight: {
    backgroundColor: '#71BF44',
    color: 'white',
    padding: 5,
    marginLeft: 3,
  },
});

export default styles;
