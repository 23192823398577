import React from 'react';

import { Text, Image, View, StyleSheet } from '@react-pdf/renderer';
import latLogo from '../images/latLogo.png';

const FooterPDF = () => {
  const styles = StyleSheet.create({
    footer: {
      position: 'absolute',
      fontSize: 12,
      bottom: 15,
      left: 0,
      right: 0,
      color: 'grey',
    },
  });

  return (
    <View style={styles.footer} fixed>
      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
        <View style={{ width: 200 }} />
        <Text
          render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )}
          fixed
        />
        <View style={{ width: 200 }}>
          <Image src={latLogo} />
        </View>
      </View>
    </View>
  );
};

export default FooterPDF;
