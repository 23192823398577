import { connect } from 'react-redux';

import AddCountry from '../components/Admin/AddCountry';
import CountryService from '../services/CountryService';
import { setAdminCountries } from '../redux/actions';

const mapDispatchToProps = dispatch => ({
  onSuccess: () => {
    dispatch(setAdminCountries(null));
    CountryService.fetchAll()
      .then(res => dispatch(setAdminCountries(res.data.countries)))
      .catch(() => console.log('Error while retrieving countries list'));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(AddCountry);
