import { connect } from 'react-redux';

import { addPlot } from '../redux/plots/actions';
import { cleanExploitations, getAllExploitations } from '../domain/utils';
import AddPlotForm from '../components/Forms/AddPlotForm';
import PlotService from '../services/PlotService';

const mapStateToProps = state => ({
  exploitations: cleanExploitations(getAllExploitations(state.exploitations)),
});

const mapDispatchToProps = dispatch => ({
  onSubmit: data => {
    PlotService.store(data)
      .then(res => {
        const plotStructure = {
          id: 0,
          name: data.name,
          surface: 0,
          ilot: '',
          texture: '',
          stone: '',
          groundDepth: '',
          organicMaterial: '',
          polygon: '',
          pilotage: [{
            overallDose: '0',
            performanceObjective: '0',
          }],
          ...data,
        };
        dispatch(addPlot({ ...plotStructure, id: res.data.plot.id }));
        PlotService.emit('plotAdded');
      })
      .catch(err => PlotService.emit('addPlotError', err.message));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddPlotForm);
