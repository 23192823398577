import PropTypes from 'prop-types';
import React from 'react';
import v3 from 'uuid';

import { useTranslation } from 'react-i18next';
import AdviceRow from './AdviceRow';

const AdvicesTable = ({ items }) => {
  const { t } = useTranslation('generics');

  return (
    <table className='table table-striped'>
      <thead className='thead-dark'>
        <tr>
          <th>{t('pages.parameters.culture.Variety class')}</th>
          <th>{t('pages.parameters.culture.Stage class')}</th>
          <th>&lt; 70</th>
          <th>&lt; 75</th>
          <th>&lt; 80</th>
          <th>&lt; 85</th>
          <th>&lt; 90</th>
        </tr>
      </thead>
      <tbody>
        {items.map(item => (
          <AdviceRow
            data={item}
            key={v3()}
          />
        ))}
      </tbody>
    </table>
  );
};

AdvicesTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    varietyClass: PropTypes.number.isRequired,
    stageClass: PropTypes.number.isRequired,
    adviceLess70: PropTypes.number.isRequired,
    adviceLess75: PropTypes.number.isRequired,
    adviceLess80: PropTypes.number.isRequired,
    adviceLess85: PropTypes.number.isRequired,
    adviceLess90: PropTypes.number.isRequired,
  })).isRequired,
};

export default AdvicesTable;
