/* eslint-disable no-return-assign */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import Alert from '../Alert';
import Button from '../Button';
import InputWithLabel from '../InputWithLabel';
import InputSelectWithLabel from '../InputSelectWithLabel';
import ProfileService from '../../services/ProfileService';
import UserService from '../../services/UserService';

const ProfileForm = ({ data, adminMode }) => {
  const { t } = useTranslation('generics');
  const [profile, setProfile] = useState(data);
  const [error, setError] = useState(null);
  const [success, setSucces] = useState(false);

  useEffect(() => ProfileService.on('editError', message => {
    setError(message);
  }), [setError]);

  useEffect(() => ProfileService.on('edited', () => setSucces(true)), [setSucces]);

  const handleFieldChange = (field, value) => {
    setProfile({
      ...profile,
      [field]: value,
    });
  };

  const countries = () => ([
    { label: 'France', value: 1 },
    { label: 'Bulgaria', value: 2 },
    { label: 'Hungary', value: 3 },
    { label: 'Austria', value: 4 },
    { label: 'Romania', value: 5 },
    { label: 'Germany', value: 6 },
    { label: 'Serbia', value: 7 },
    { label: 'Ukraine', value: 8 },
  ]);

  const isFormValid = formData => {
    if (formData.lastname.trim() === '') {
      setError(t('field is required', { field: t('pages.profile.profile.fields.Lastname') }));
      return false;
    }

    if (formData.firstname.trim() === '') {
      setError(t('field is required', { field: t('pages.profile.profile.fields.Firstname') }));
      return false;
    }

    if (formData.email.trim() === '') {
      setError(t('field is required', { field: t('pages.profile.profile.fields.Email') }));
      return false;
    }
    const re = /\S+@\S+\.\S+/;
    if (re.test(formData.email) === false) {
      setError(t('Email must have a correct format'));
      return false;
    }

    return true;
  };

  const handleSave = () => {
    setError(null);
    setSucces(false);
    if (isFormValid(profile)) {
      if (adminMode) {
        UserService.updateUserById(profile.id, profile)
          .then(resp => console.log('EDIT USER', resp))
          .catch(e => console.log('UPDATE USER', e));
      } else {
        ProfileService.edit(profile)
          .then(() => ProfileService.emit('pages.profile.edited'))
          .catch(err => ProfileService.emit('pages.profile.editError', err.message));
      }
    }
  };

  return (
    <div>
      {success && (<Alert theme='success' message={t('pages.profile.profile.alerts.Profile updated')} />)}
      {error && (<Alert message={error} />)}
      <div className='p-3'>
        <InputWithLabel
          label={t('pages.profile.profile.fields.Lastname')}
          name='lastname'
          onChange={val => handleFieldChange('lastname', val)}
          value={profile.lastname}
        />
      </div>
      <div className='p-3'>
        <InputWithLabel
          label={t('pages.profile.profile.fields.Firstname')}
          name='firstname'
          onChange={val => handleFieldChange('firstname', val)}
          value={profile.firstname}
        />
      </div>
      <div className='p-3'>
        <InputSelectWithLabel
          label={t('pages.profile.profile.fields.Country')}
          name='contry'
          options={countries()}
          onChange={val => handleFieldChange('country', val)}
          value={profile.country}
        />
      </div>
      <div className='p-3'>
        <InputWithLabel
          disabled
          label={t('pages.profile.profile.fields.Email')}
          name='email'
          type='email'
          onChange={val => handleFieldChange('email', val)}
          value={profile.email}
        />
      </div>
      <div className='text-center'>
        <Button onClick={() => handleSave()} title={t('pages.profile.profile.Save profile')} />
      </div>
    </div>
  );
};

ProfileForm.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    lastname: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProfileForm;
