import PropTypes from 'prop-types';
import React from 'react';

import AddExploitation from '../AddExploitation';
import EditExploitation from '../EditExploitation';

const FarmActions = ({ groupId }) => (
  <div>
    <AddExploitation theme='button' />
    <EditExploitation groupId={groupId} />
  </div>
);

FarmActions.propTypes = {
  groupId: PropTypes.number.isRequired,
};

export default FarmActions;
