import PropTypes from 'prop-types';
import React from 'react';

import flagEn from '../images/flag_en.png';
import flagFr from '../images/flag_fr.png';
import flagRo from '../images/flag_ro.png';
import flagDe from '../images/flag_de.png';
import flagBg from '../images/flag_bg.png';
import flagHu from '../images/flag_hu.png';

const getFlag = code => {
  switch (code) {
    case 'en':
      return flagEn;
    case 'fr':
      return flagFr;
    case 'de':
      return flagDe;
    case 'bg':
      return flagBg;
    case 'ro':
      return flagRo;
    case 'hu':
      return flagHu;
    default:
      return flagEn;
  }
};

const LangFlag = ({ lang = 'en' }) => (<img src={getFlag(lang)} alt='flag en' />);

LangFlag.propTypes = {
  lang: PropTypes.string,
};

export default LangFlag;
