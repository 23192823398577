import PropTypes from 'prop-types';
import React from 'react';
import v4 from 'uuid';

import { useTranslation } from 'react-i18next';
import crop from '../../images/ic_crop.png';

const NatValues = ({ average = null, univers = 1 }) => {
  const { t } = useTranslation('generics');

  const getUnit = () => (+univers === 2 ? 'kg/m²' : 'kgN/ha');

  return (
    <div className='w-100'>
      <h1>{t('pages.synthesis.mediumAverage')}</h1>
      <div className='averageContainer'>
        <h2>{t('pages.synthesis.NATIONAL')}</h2>
        <div
          key={v4()}
          className='line-value'
        >
          <img src={crop} className='averageImg' alt='crop icon' />
          {average ? (
            <>
              <p className='averageValue'>{average}</p>
              <p className='averageText'>{getUnit()}</p>
            </>
          ) : (
            <p className='averageText'>{t('pages.synthesis.noAverageAvailable')}</p>
          )}
        </div>
      </div>
    </div>
  );
};

NatValues.propTypes = {
  average: PropTypes.arrayOf(PropTypes.shape({
    averageAdvice: PropTypes.string,
    name: PropTypes.string,
  })),
};

export default NatValues;
