import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import v3 from 'uuid';

import ExploitationService from '../../services/ExploitationService';
import Pilot from './Pilot';
import ProfileForm from './ProfileForm';
import Subscriptions from './Subscriptions';

const ProfileDetails = ({ data, onExploitationDelete, adminMode = false }) => {
  useEffect(() => {
    ExploitationService.on('deleteExploitation', id => onExploitationDelete(id));
  }, [onExploitationDelete]);

  const pilots = pilotsData => (
    pilotsData && pilotsData.map(pilot => (
      <div key={v3()} className='py-2'>
        <Pilot serialNumber={pilot.serialNumber} />
      </div>
    ))
  );

  return (
    <div className='px-5'>
      { data && data.pilots && pilots(data.pilots) }
      <div className='py-3'>
        <ProfileForm
          data={{
            id: data.id,
            login: data.login,
            lastname: data.lastname,
            firstname: data.firstname,
            country: data.country.id,
            email: data.email,
          }}
          adminMode={adminMode}
        />
      </div>
      <Subscriptions list={data.subscriptions} />
    </div>
  );
};

ProfileDetails.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    login: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    subscriptions: PropTypes.arrayOf(PropTypes.shape({
      serialNumber: PropTypes.string.isRequired,
      univers: PropTypes.string.isRequired,
      year: PropTypes.number.isRequired,
    })).isRequired,
  }).isRequired,
  onExploitationDelete: PropTypes.func.isRequired,
};

export default ProfileDetails;
