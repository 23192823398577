import { connect } from 'react-redux';

import LoginForm from '../components/LoginForm';
import { setError } from '../redux/actions';
import AppService from '../services/AppService';
import { loginFormValidate } from '../domain/utils';

const mapStateToProps = state => ({
  user: state.user,
  alert: state.login.error ? state.login.error : '',
});

const mapDispatchToProps = dispatch => ({
  onLogin: async credentials => {
    dispatch(setError(null));
    const validatedCredentials = await loginFormValidate(credentials); 
    if (validatedCredentials.valid) {
      await AppService.loginPost(credentials)
        .then()
        .catch(error => {
          let errMessage = error.message;
          if (error.response) {
            errMessage = error.response.data.message ? error.response.data.message : errMessage;
          }
          dispatch(setError(errMessage));
          throw new Error;
        });
    } else {
      await dispatch(setError(validatedCredentials.message));
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginForm);
