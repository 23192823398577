import PropTypes from 'prop-types';
import React, { useState } from 'react';
import v3 from 'uuid';

const Tabs = ({ elements, activeId = null }) => {
  const [active, setActive] = useState(
    activeId !== null ? activeId : elements[0].id,
  );

  return (
    <div>
      <ul className='tabs-nav nav nav-pills nav-justified'>
        {elements.length > 1 && elements.map(elem => (
          <li className='nav-item' key={v3()}>
            <button
              className={`btn btn-tabs nav-link w-100 text-uppercase ${active === elem.id ? 'active' : ''}`}
              type='button'
              onClick={() => setActive(elem.id)}
            >
              {elem.title}
            </button>
          </li>
        ))}
      </ul>
      <div className='pb-3'>
        {elements.find(elem => elem.id === active).component}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    component: PropTypes.object,
  })).isRequired,
};

export default Tabs;
