import React, { useState } from 'react';
import PropTypes from 'prop-types';

import InputWithIcon from './InputWithIcon';

import eye from '../images/ic_eye.png';

const InputPassword = ({ onChange, value = '' }) => {
  const [type, setType] = useState('password');

  const handleIconClick = () => {
    setType(type === 'password' ? 'text' : 'password');
  };

  return (
    <InputWithIcon
      name='password'
      icon={eye}
      onChange={onChange}
      onIconClick={handleIconClick}
      type={type}
      value={value}
    />
  );
};

InputPassword.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default InputPassword;
