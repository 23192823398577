import PropTypes from 'prop-types';
import React from 'react';
import v3 from 'uuid';

import { useTranslation } from 'react-i18next';
import OrganizationRow from './OrganizationRow';

const OrganizationsTable = ({ handleDelete, items }) => {
  const { t } = useTranslation('generics');
  return (
    <table className='table table-striped'>
      <thead className='thead-dark'>
        <tr>
          <th>{t('pages.parameters.groups.Id')}</th>
          <th>{t('pages.parameters.groups.Name')}</th>
          <th>{t('pages.parameters.groups.Society')}</th>
          <th>{t('pages.parameters.groups.User')}</th>
          <th>{t('pages.parameters.groups.Farm')}</th>
          <th>{t('pages.parameters.groups.Actions')}</th>
        </tr>
      </thead>
      <tbody>
        {items.map(item => (
          <OrganizationRow
            key={v3()}
            data={item}
            onDelete={() => handleDelete(item.id)}
          />
        ))}
      </tbody>
    </table>
  );
};

OrganizationsTable.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    usersNumber: PropTypes.number.isRequired,
    exploitationsNumber: PropTypes.number.isRequired,
  })).isRequired,
};

export default OrganizationsTable;
