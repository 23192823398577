import PropTypes from 'prop-types';
import React from 'react';

const AdviceRow = ({ data }) => (
  <tr>
    <td>{data.varietyClass}</td>
    <td>{data.stageClass}</td>
    <td>{data.adviceLess70}</td>
    <td>{data.adviceLess75}</td>
    <td>{data.adviceLess80}</td>
    <td>{data.adviceLess85}</td>
    <td>{data.adviceLess90}</td>
  </tr>
);

AdviceRow.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    varietyClass: PropTypes.number.isRequired,
    stageClass: PropTypes.number.isRequired,
    adviceLess70: PropTypes.number.isRequired,
    adviceLess75: PropTypes.number.isRequired,
    adviceLess80: PropTypes.number.isRequired,
    adviceLess85: PropTypes.number.isRequired,
    adviceLess90: PropTypes.number.isRequired,
  }),
};

export default AdviceRow;
