import { connect } from 'react-redux';

import { addExploitation } from '../redux/actions';
import AddExploitationForm from '../components/Forms/AddExploitationForm';
import ExploitationService from '../services/ExploitationService';
import { groupsToSelect } from '../domain/utils';

const mapStateToProps = state => ({
  groups: groupsToSelect(state.user.groups),
});

const mapDispatchToProps = dispatch => ({
  onSubmit: data => {
    ExploitationService.store(data)
      .then(res => {
        dispatch(addExploitation(res.data.Exploitation));
        ExploitationService.emit('exploitationAdded');
      })
      .catch(err => ExploitationService.emit('addExploitationError', err.message));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddExploitationForm);
