import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import InputCheckBox from '../../../../InputCheckbox';
import Button from '../../../../Button';
import icEye from '../../../../../images/ic_eye.png';
import { useLocation } from 'react-router-dom';

function PlotMeasureItem({ measure, onSelect, handleSelectMeasure, measureSelected }) {
  const isChecked = useMemo(() => (
    measureSelected.filter(measureItem => measureItem.id === measure.id).length === 1
  ), [measure.id, measureSelected]);

  const location = useLocation()

  useEffect(() => {
    let urlSplited = location.pathname.split("/").splice(1)
    let measureIndex = urlSplited.indexOf("measure")
    if(measureIndex !== -1) {
      if(measure.id === parseInt(urlSplited[measureIndex + 1], 10)) {
        onSelect(measure)
      }
    }
  }, [])

  return (
    <tr>
      <td>
        <InputCheckBox
          id={measure.id}
          name={measure.stageName}
          onChange={handleSelectMeasure}
          checked={isChecked}
        />
      </td>
      <td>{measure.stageName}</td>
      <td>{moment(measure.date.date).format('DD/MM/YYYY')}</td>
      <td>{measure.advice}</td>
      <td className='text-right'>
        <Button
          title={<img src={icEye} alt='detail icon' />}
          theme='icon'
          onClick={() => onSelect(measure)}
        />
      </td>
    </tr>
  );
}

PlotMeasureItem.displayName = 'Borealis-Plot-Measure-Item';
PlotMeasureItem.propTypes = {
  measure: PropTypes.shape({
    advice: PropTypes.number.isRequired,
    stageName: PropTypes.string.isRequired,
    date: PropTypes.shape({
      date: PropTypes.string.isRequired,
    }).isRequired,
  }),
  onSelect: PropTypes.func.isRequired,
  handleSelectMeasure: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  measureSelected: PropTypes.array.isRequired,
};

export default PlotMeasureItem;
