import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import v4 from 'uuid';

const EntityRelationsDisplay = ({ buttonTitle, link, values }) => (
  <div>
    {values.map(entity => (
      <div className='entity border-bottom p-1 mb-1' key={v4()}>
        {entity.name}
      </div>
    ))}
    <div>
      <Link to={link} key={v4()} title={buttonTitle} className='btn btn-light btn-sm'>
        {buttonTitle}
      </Link>
    </div>
  </div>
);

EntityRelationsDisplay.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  link: PropTypes.string.isRequired,
};

export default EntityRelationsDisplay;
