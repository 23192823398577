/* eslint-disable no-console */
import moment from "moment";
import PropTypes from "prop-types";
import React, { useContext, useState, useEffect } from "react";
import v3 from "uuid";
import { useTranslation } from "react-i18next";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import { useSelector } from "react-redux";

import UserService from "../../../../../services/UserService";
import DataWithLabel from "../../../../DataWithLabel";
import Stars from "../../../../Stars";
import Button from "../../../../Button";
import { getMeasureScore } from "../../../../../domain/utils";
import MeasurePDF from "../../../../MeasurePDF";
import { useHistory, useLocation } from "react-router-dom";
import { PlotContext } from "../../Plot.Context";
import axios from "axios";

function PlotMeasureDetails({ data, idUnivers, onReturn }) {
  const { t } = useTranslation("generics");
  const { measures } =
    data.organization[0].exploitation[0].plot[0].pilotage[0].measureGroup[0];
  const exploitationID = useSelector((state) => state.filters.exploitation);
  const { selectedPlotDetail } = useContext(PlotContext);
  const location = useLocation();
  const history = useHistory();
  const [pdfUri, setPdfUri] = useState(null);

  const pdfComponent = <MeasurePDF data={data} />;

  const loadPDF = async () => {
    let pdf = await UserService.getMeasurePDF([
      location.pathname.split("/")[location.pathname.split("/").length - 1],
    ]);
    if (!pdf.data.data.hasError) {
      let uri = Object.entries(pdf.data.data.reports)[0][1];
      setPdfUri(uri);
    }
  };

  useEffect(() => {
    loadPDF();
  }, []);

  const sendMail = async () => {
    const blob = await pdf(pdfComponent).toBlob();
    await UserService.sendMeasure(blob, exploitationID, [
      selectedPlotDetail.organization[0].exploitation[0].plot[0].id,
    ])
      .then(() => {
        console.log("mail send");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteMeasureLocation = () => {
    let urlSplited = location.pathname.split("/").splice(1);
    urlSplited.splice(urlSplited.indexOf("measure"), 2);
    history.push(`/${urlSplited.join("/")}`);
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5">
            <div className="border border-grey border-large p-4 mb-4">
              <DataWithLabel
                label={t("pages.plot.measure.details.Growth stage")}
                data={
                  data.organization[0].exploitation[0].plot[0].pilotage[0]
                    .measureGroup[0].stageName
                }
              />
              <DataWithLabel
                label={t("pages.plot.measure.details.Date")}
                data={moment(
                  data.organization[0].exploitation[0].plot[0].pilotage[0]
                    .measureGroup[0].date.date
                ).format("DD-MM-YYYY HH:mm")}
              />
              <DataWithLabel
                label={t("pages.plot.measure.details.Operator")}
                data={
                  data.organization[0].exploitation[0].plot[0].pilotage[0]
                    .measureGroup[0].operator
                }
              />
              <DataWithLabel
                label={t("pages.plot.measure.details.N Pilot")}
                data={
                  data.organization[0].exploitation[0].plot[0].pilotage[0]
                    .measureGroup[0].gpnNumber
                }
              />
              {/* idUnivers 2 = colza */}
              {idUnivers === 2 && (
                <DataWithLabel
                  label={t("pages.plot.measure.details.Vegetation height")}
                  data={
                    data.organization[0].exploitation[0].plot[0].pilotage[0]
                      .measureGroup[0].height
                  }
                  unit=" cm"
                />
              )}
              {idUnivers === 2 ? (
                <div className="py-3 text-info">
                  {t("pages.plot.measure.details.Biomass")}{" "}
                  <b className="ml-3">
                    {data.organization[0].exploitation[0].plot[0].pilotage[0].measureGroup[0].advice.toFixed(
                      2
                    )}{" "}
                    kg/m<sup>2</sup>
                  </b>
                </div>
              ) : (
                <div className="py-3 text-info">
                  {t("pages.plot.measure.details.Advice")}{" "}
                  <b className="ml-3">
                    {
                      data.organization[0].exploitation[0].plot[0].pilotage[0]
                        .measureGroup[0].advice
                    }{" "}
                    kgN/ha
                  </b>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-7">
            <div className="text-primary font-weight-bold mb-2">
              {t("pages.plot.measure.details.Reference measurements")}
            </div>
            {/*  Is reference true */}
            <table className="table table-ref">
              <thead className="thead-dark">
                <tr>
                  <th>#</th>
                  <th>{t("pages.plot.measure.details.tables.Index")}</th>
                  <th>
                    {t("pages.plot.measure.details.tables.Quality index")}
                  </th>
                  <th>{t("pages.plot.measure.details.tables.Notes")}</th>
                </tr>
              </thead>
              <tbody>
                {measures
                  .filter((item) => item.areaReference)
                  .map((measure, index) => (
                    <tr key={v3()}>
                      <td>
                        <div className="pinsContainer">
                          <span>{index + 1}</span>
                          <div className="pins purplePins" />
                        </div>
                      </td>
                      <td>{Math.round(measure.gpnIndex)}</td>
                      <td>
                        <Stars
                          score={getMeasureScore(
                            measure.qualityIndex,
                            idUnivers
                          )}
                        />
                      </td>
                      <td>{measure.comment}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {/*  Is reference false */}
            <div className="text-primary font-weight-bold mt-3 mb-2">
              Plot measurements
            </div>
            <table className="table table-measures">
              <thead className="thead-dark">
                <tr>
                  <th>#</th>
                  {idUnivers !== 2 && <th>% Réf</th>}
                  <th>
                    {t("pages.plot.measure.details.tables.Quality index")}
                  </th>
                  <th>
                    {idUnivers === 2
                      ? t("pages.plot.measure.details.Biomass")
                      : t("pages.plot.measure.details.Advice")}
                  </th>
                  <th>{t("pages.plot.measure.details.tables.Notes")}</th>
                </tr>
              </thead>
              <tbody>
                {measures
                  .filter((item) => !item.areaReference)
                  .map((measure, index) => (
                    <tr key={v3()}>
                      <td>
                        <div className="pinsContainer">
                          <span>{index + 1}</span>
                          <div className="pins orangePins" />
                        </div>
                      </td>
                      {idUnivers !== 2 && <td>{measure.percentReference}%</td>}
                      <td>
                        <Stars
                          score={getMeasureScore(
                            measure.qualityIndex,
                            idUnivers
                          )}
                        />
                      </td>
                      <td>
                        <b className="text-primary">{measure.gpnAdvice}</b>
                      </td>
                      <td>{measure.comment}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="text-right my-3">
        <Button title="mail" onClick={() => sendMail()} />
        {/* <PDFDownloadLink
          className="btn text-uppercase btn-yellow m-2"
          document={pdfComponent}
          fileName="measureReport.pdf"
        >
          {({ loading }) =>
            loading
              ? t("pages.reports.Loading document")
              : t("pages.reports.Export PDF")
          }
        </PDFDownloadLink> */}
        {pdfUri && (
          <a
            className="btn text-uppercase btn-yellow m-2"
            download={"measureReport.pdf"}
            target="_blank"
            href={pdfUri}
          >
            {t("pages.reports.Export PDF")}
          </a>
        )}
        <Button
          title={t("form.Return")}
          theme="outline-secondary"
          onClick={() => onReturn() & deleteMeasureLocation()}
        />
      </div>
    </div>
  );
}

PlotMeasureDetails.displayName = "Borealis-Plot-Measure-Details";
PlotMeasureDetails.propTypes = {
  data: PropTypes.shape({
    advice: PropTypes.number.isRequired,
    stageName: PropTypes.string.isRequired,
    gpnNumber: PropTypes.string.isRequired,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    operator: PropTypes.string.isRequired,
    date: PropTypes.shape({
      date: PropTypes.string.isRequired,
    }).isRequired,
    measures: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        gpnIndex: PropTypes.number.isRequired,
        qualityIndex: PropTypes.number.isRequired,
        gpnAdvice: PropTypes.number,
        comment: PropTypes.string.isRequired,
        startCoordinates: PropTypes.arrayOf(PropTypes.number.isRequired)
          .isRequired,
        arrivalCoordinates: PropTypes.arrayOf(PropTypes.number.isRequired)
          .isRequired,
        areaReference: PropTypes.bool.isRequired,
        percentReference: PropTypes.number,
        ET: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
  idUnivers: PropTypes.number.isRequired,
  onReturn: PropTypes.func.isRequired,
};

export default PlotMeasureDetails;
