import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ReactLoading from 'react-loading';
import v3 from 'uuid';
import { useTranslation } from 'react-i18next';

import strawcereals from '../../../images/ic_strawcereals.png';
import rapeseed from '../../../images/ic_rapeseed.png';
import maize from '../../../images/ic_maize.png';
import meadow from '../../../images/ic_meadow.png';
import potato from '../../../images/ic_potato.png';
import sunflower from '../../../images/ic_sunflower.png';
import fiberflax from '../../../images/ic_fiberflax.png';
import { getUniversTranslate } from '../../../domain/utils';

const CultureMenu = ({ match, items = null, fetchData = () => {} }) => {
  const { t } = useTranslation('generics');
  const { params } = match;

  useEffect(() => fetchData(), [fetchData]);

  const getIcon = id => {
    switch (id) {
      case 1:
        return <img src={strawcereals} alt='crop icon' />;
      case 2:
        return <img src={rapeseed} alt='crop icon' />;
      case 3:
        return <img src={maize} alt='crop icon' />;
      case 4:
        return <img src={potato} alt='crop icon' />;
      case 5:
        return <img src={meadow} alt='crop icon' />;
      case 6:
        return <img src={fiberflax} alt='crop icon' />;
      case 7:
        return <img src={sunflower} alt='crop icon' />;
      default:
        return <img src={strawcereals} alt='crop icon' />;
    }
  };

  const isActive = id => {
    if (params.idUnivers) return id === parseInt(params.idUnivers, 10);

    return false;
  };

  return items ? (
    <div>
      <div className='text-primary font-weight-bold mb-2 text-uppercase'>{t('pages.parameters.culture.Univers')}</div>
      <nav id='culture-menu' className='row'>
        { items.map(item => (
          <Link
            key={v3()}
            className={`text-center col-6 ${isActive(item.id) && 'active'}`}
            title={t(getUniversTranslate(item.id))}
            to={`/parameters/culture-base/${item.id}`}
          >
            {getIcon(item.id)}
            <p>
              {t(getUniversTranslate(item.id))}
            </p>
          </Link>
        )) }
      </nav>
    </div>
  ) : (
    <ReactLoading type='bubbles' color='#8f8f8f' />
  );
};

CultureMenu.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequiredd,
    code: PropTypes.string.isRequired,
  })),
  fetchData: PropTypes.func,
};

export default withRouter(CultureMenu);
