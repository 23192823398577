import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { GoogleMap, LoadScript, Polygon } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import v3 from 'uuid';
import PlotMapLoading from './PlotMapLoading';
import { CENTER_BOUNDS_POSITION, GOOGLE_MAPS_OPTIONS, POLYGON_OPTIONS } from './constants';
import { selectPlot } from '../../../../../redux/plots/actions';
import { mapDefaultZoom } from '../../../../../domain/constants';
import { PlotContext } from '../../Plot.Context';

function PlotGlobalMap() {
  const { i18n } = useTranslation('generics');
  const { plotsTest } = useContext(PlotContext)
  const [formatedPlot, setFormatedPlot] = useState([]);
  const { setSelectedPlotDetail } = useContext(PlotContext)

  // Define ref for google maps
  const mapsRef = useRef(null);
  const polygonListenersRef = useRef([]);

  // Method for make bounds
  // ----------------------------------------------------------------------------
  const makeBounds = useCallback(() => {
    if (mapsRef.current && window.google) {
      const bounds = new window.google.maps.LatLngBounds();
      const path = formatedPlot.map(fPlot => fPlot.polygons).flat();

      if (path && Array.isArray(path) && path.length >= 1) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < path.length; i++) {
          bounds.extend(path[i]);
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < CENTER_BOUNDS_POSITION.length; i++) {
          bounds.extend(CENTER_BOUNDS_POSITION[i]);
        }
      }

      mapsRef.current.fitBounds(bounds);
      mapsRef.current.setCenter(bounds.getCenter());
    }
  }, [formatedPlot]);

  // On load for google map
  // ----------------------------------------------------------------------------
  const onLoadMap = useCallback(map => {
    if (map) {
      mapsRef.current = map;
      makeBounds();
    }
  }, [makeBounds]);

  // On unmount for google map
  // ----------------------------------------------------------------------------
  const onUnmountMap = useCallback(() => {
    if (mapsRef.current) {
      mapsRef.current = null;
    }
  }, []);

  // Double click for select plot
  // ----------------------------------------------------------------------------
  const onHandleClickPolygon = fPlot => {
    const plot = plotsTest.filter(p => p.id === fPlot.id)[0];

    if (plot) {
      setSelectedPlotDetail(plot);
    }
  };

  // Bind refs listeners
  // ----------------------------------------------------------------------------
  const onLoadPolygon = useCallback(polygon => {
    if (mapsRef.current && window.google && window.google.maps) {
      polygonListenersRef.current.push(
        window.google.maps.event.addListener(polygon, 'mouseover', function () {
          // eslint-disable-next-line react/no-this-in-sfc
          this.setOptions({
            fillColor: '#ffa305',
            strokeColor: '#ffa305',
          });
        }),
        window.google.maps.event.addListener(polygon, 'mouseout', function () {
          // eslint-disable-next-line react/no-this-in-sfc
          this.setOptions({
            fillColor: POLYGON_OPTIONS.fillColor,
            strokeColor: POLYGON_OPTIONS.strokeColor,
          });
        }),
      );
    }
  }, []);

  // Clean up refs
  // ----------------------------------------------------------------------------
  const onUnmountPolygon = useCallback(() => {
    polygonListenersRef.current.forEach(listener => listener.remove());
  }, []);

  // Get all polygons on mounted
  // ----------------------------------------------------------------------------
  useEffect(() => {
    try {
      if (plotsTest && Array.isArray(plotsTest) && plotsTest.length >= 1) {
        // Prepare polygons data
        const fPlots = plotsTest.map(plot => {
          if (plot.polygon && plot.polygon.length) {
            const polygon = JSON.parse(plot.polygon);

            if (polygon && Array.isArray(polygon) && polygon.length) {
              const p = polygon.map(latLng => ({
                lat: latLng[1],
                lng: latLng[0],
              }));

              return {
                id: plot.id,
                polygons: p,
              };
            }
          }

          return [];
        }).filter(item => item.id);

        // Set plots polygons
        setFormatedPlot(fPlots);
      }
    } catch (e) {
      console.error('Plot global map', e);
    }
  }, [plotsTest]);

  // Call make bounds if change plot
  // ----------------------------------------------------------------------------
  useEffect(() => {
    makeBounds();
  }, [makeBounds]);

  // Render
  // ----------------------------------------------------------------------------
  return (
    <div className='col-12 px-0 h-100 w-100'>
      <LoadScript
        id='script-loader'
        language={i18n.language}
        loadingElement={<PlotMapLoading />}
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
        preventGoogleFonts
      >
        <GoogleMap
          mapContainerClassName='plot-map'
          version='weekly'
          onLoad={onLoadMap}
          onUnmount={onUnmountMap}
          zoom={mapDefaultZoom}
          options={{
            ...GOOGLE_MAPS_OPTIONS,
            disableDoubleClickZoom: true,
          }}
          mapContainerStyle={{ height: '100vh' }}
          on
        >
          {
            (formatedPlot && formatedPlot.length >= 1) && formatedPlot.map(fPlot => {
              if (fPlot && fPlot.polygons && fPlot.polygons.length > 0) {
                return (
                  <Polygon
                    key={v3()}
                    paths={fPlot.polygons}
                    options={POLYGON_OPTIONS}
                    onClick={() => onHandleClickPolygon(fPlot)}
                    onLoad={onLoadPolygon}
                    onUnmount={onUnmountPolygon}
                  />
                );
              }

              return null;
            })
          }
        </GoogleMap>
      </LoadScript>
    </div>
  );
}

PlotGlobalMap.displayName = 'Borealis-Plot-Global-Map';

export default PlotGlobalMap;
