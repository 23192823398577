import PropTypes from 'prop-types';
import React from 'react';

const DropdownButton = ({ onClick, title = '' }) => (
  <button className='btn btn-link btn-sm dropdown-toggle text-info' type='button' onClick={() => onClick()}>{title}</button>
);

DropdownButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default DropdownButton;
