/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import UserService from '../../services/UserService';

import InputSelectWithLabel from '../InputSelectWithLabel';
import Alert from '../Alert';
import Input from '../Input';
import InputPassword from '../InputPassword';
import Button from '../Button';

const RegisterForm = ({ organization = null, closeModal }) => {
  const { t } = useTranslation('generics');
  const initialData = {
    lastname: '',
    firstname: '',
    country: 1,
    group_id: organization,
    roles: 1,
    pilotSerialNumber: '',
  };

  const countries = () => ([
    { label: 'France', value: 1 },
    { label: 'Bulgaria', value: 2 },
    { label: 'Hungary', value: 3 },
    { label: 'Austria', value: 4 },
    { label: 'Romania', value: 5 },
    { label: 'Germany', value: 6 },
    { label: 'Serbia', value: 7 },
    { label: 'Ukraine', value: 8 },
  ]);

  const roles = () => ([
    { label: t('roles.Farmer'), value: 1 },
    { label: t('roles.Distributor'), value: 2 },
  ]);

  const [data, setData] = useState(initialData);
  const [error, setError] = useState(null);

  const handleSubmit = () => {
    setError(null);
	console.log(data)
    const formOk = data.lastname.trim().length
    && data.firstname.trim().length
    && data.pilotSerialNumber.trim().length;

    if (!formOk) {
      setError(t('fieldsError.Fields are required'));
    } else {
      UserService.register(data)
        .then(() => {
          closeModal();
        })
        .catch(e => setError(e.message));
    }
  };

  const handleChange = (field, value) => setData({ ...data, [field]: value });

  return (
    <div className='row'>
      {error && (<div className='col-12'><Alert message={error} /></div>)}
      <div className='col-md-6 mb-3'>
        <Input
          name='lastname'
          placeholder={t('pages.profile.profile.fields.Lastname')}
          onChange={val => handleChange('lastname', val)}
          value={data.lastname}
        />
      </div>
      <div className='col-md-6 mb-3'>
        <Input
          name='firstname'
          placeholder={t('pages.profile.profile.fields.Firstname')}
          onChange={val => handleChange('firstname', val)}
          value={data.firstname}
        />
      </div>
      <div className='col-md-6 mb-3'>
        <InputSelectWithLabel
          label={t('pages.profile.profile.fields.Country')}
          name='country'
          options={countries()}
          onChange={val => handleChange('country', val)}
          value={data.country}
        />
      </div>
      <div className='col-md-6 mb-3'>
        <InputSelectWithLabel
          label={t('pages.profile.profile.fields.Role')}
          name='roles'
          options={roles()}
          onChange={val => handleChange('roles', val)}
          value={data.roles}
        />
      </div>
      <div className='col-md-6 mb-3'>
        <Input
          name='pilotSerialNumber'
          placeholder={t('pages.profile.profile.fields.NumPilot')}
          onChange={val => handleChange('pilotSerialNumber', val)}
          value={data.pilotSerialNumber}
        />
      </div>
      <div className='col-md-12 mb-3 text-center'>
        <Button title='Create' onClick={() => handleSubmit()} />
      </div>
    </div>
  );
};

export default RegisterForm;
