export const ActionTypes = {
  ADD_EXPLOITATION: 'ADD_EXPLOITATION',
  // ADD_PLOT: 'ADD_PLOT',
  DELETE_EXPLOITATION: 'DELETE_EXPLOITATION',
  // DELETE_PLOT: 'DELETE_PLOT',
  SET_CAMPAIGNS: 'SET_CAMPAIGNS',
  SET_ERROR: 'SET_ERROR',
  SET_EXPLOITATIONS: 'SET_EXPLOITATIONS',
  SET_FILTERS: 'SET_FILTERS',
  // SET_PLOTS: 'SET_PLOTS',
  SET_PROFILE: 'SET_PROFILE',
  SET_LANGUAGE: 'SET_LANGUAGE',
  SET_USER: 'SET_USER',
  SET_ADMIN_GROUP: 'SET_ADMIN_GROUP',
  REMOVE_ADMIN_GROUP: 'REMOVE_ADMIN_GROUP',
  SET_ADMIN_COUNTRIES: 'SET_ADMIN_COUNTRIES',
  EDIT_ADMIN_COUNTRY: 'EDIT_ADMIN_COUNTRY',
  SET_ADMIN_PILOTS: 'SET_ADMIN_PILOTS',
  DELETE_ADMIN_PILOT: 'DELETE_ADMIN_PILOT',
  UPDATE_ADMIN_PILOT_SUBSCRIPTIONS: 'UPDATE_ADMIN_PILOT_SUBSCRIPTIONS',
  UPDATE_ADMIN_PILOT_USERS: 'UPDATE_ADMIN_PILOT_USERS',
  DELETE_ADMIN_PILOT_USER: 'DELETE_ADMIN_PILOT_USER',
  SET_ADMIN_SUBSCRIPTIONS: 'SET_ADMIN_SUBSCRIPTIONS',
  SET_ADMIN_USERS: 'SET_ADMIN_USERS',
  SET_ADMIN_UNIVERS: 'SET_ADMIN_UNIVERS',
  UPDATE_ADMIN_UNIVERS_SUBSCRIPTION: 'UPDATE_ADMIN_UNIVERS_SUBSCRIPTION',
  ADD_ADMIN_UNIVERS_SUBSCRIPTION: 'ADD_ADMIN_UNIVERS_SUBSCRIPTION',
  DELETE_ADMIN_UNIVERS_SUBSCRIPTION: 'DELETE_ADMIN_UNIVERS_SUBSCRIPTION',
  SET_ADMIN_SIGNATURES: 'SET_ADMIN_SIGNATURES',
  UPDATE_ADMIN_SIGNATURE: 'UPDATE_ADMIN_SIGNATURE',
};

export const setUser = user => ({
  type: ActionTypes.SET_USER,
  user,
});

export const setError = error => ({
  type: ActionTypes.SET_ERROR,
  error,
});

export const setExploitations = exploitations => ({
  type: ActionTypes.SET_EXPLOITATIONS,
  exploitations,
});

export const setCampaigns = campaigns => ({
  type: ActionTypes.SET_CAMPAIGNS,
  campaigns,
});

export const setLang = lang => ({
  type: ActionTypes.SET_LANGUAGE,
  lang,
});

export const setFilters = filters => ({
  type: ActionTypes.SET_FILTERS,
  filters,
});

// export const setPlots = plots => ({
//   type: ActionTypes.SET_PLOTS,
//   plots,
// });

export const addExploitation = exploitation => ({
  type: ActionTypes.ADD_EXPLOITATION,
  exploitation,
});

// export const addPlot = plot => ({
//   type: ActionTypes.ADD_PLOT,
//   plot,
// });
//
// export const deletePlot = plotId => ({
//   type: ActionTypes.DELETE_PLOT,
//   plotId,
// });

export const deleteExploitation = exploitationId => ({
  type: ActionTypes.DELETE_EXPLOITATION,
  exploitationId,
});

export const setAdminGroups = groups => ({
  type: ActionTypes.SET_ADMIN_GROUP,
  groups,
});

export const removeAdminGroup = groupId => ({
  type: ActionTypes.REMOVE_ADMIN_GROUP,
  groupId,
});

export const setAdminCountries = countries => ({
  type: ActionTypes.SET_ADMIN_COUNTRIES,
  countries,
});

export const editAdminCountry = country => ({
  type: ActionTypes.EDIT_ADMIN_COUNTRY,
  country,
});

export const setAdminPilots = pilots => ({
  type: ActionTypes.SET_ADMIN_PILOTS,
  pilots,
});

export const deleteAdminPilot = id => ({
  type: ActionTypes.DELETE_ADMIN_PILOT,
  id,
});

export const updateAdminPilotSubscriptions = data => ({
  type: ActionTypes.UPDATE_ADMIN_PILOT_SUBSCRIPTIONS,
  data,
});

export const updateAdminPilotUsers = data => ({
  type: ActionTypes.UPDATE_ADMIN_PILOT_USERS,
  data,
});

export const deleteAdminPilotUser = data => ({
  type: ActionTypes.DELETE_ADMIN_PILOT_USER,
  data,
});

export const setAdminSubscriptions = subscriptions => ({
  type: ActionTypes.SET_ADMIN_SUBSCRIPTIONS,
  subscriptions,
});

export const setAdminUsers = users => ({
  type: ActionTypes.SET_ADMIN_USERS,
  users,
});

export const setAdminUnivers = univers => ({
  type: ActionTypes.SET_ADMIN_UNIVERS,
  univers,
});

export const updateAdminUniversSubscription = subscription => ({
  type: ActionTypes.UPDATE_ADMIN_UNIVERS_SUBSCRIPTION,
  subscription,
});

export const addAdminUniversSubscription = data => ({
  type: ActionTypes.ADD_ADMIN_UNIVERS_SUBSCRIPTION,
  data,
});

export const deleteAdminUniversSubscription = data => ({
  type: ActionTypes.DELETE_ADMIN_UNIVERS_SUBSCRIPTION,
  data,
});

export const setAdminSignatures = signatures => ({
  type: ActionTypes.SET_ADMIN_SIGNATURES,
  signatures,
});

export const editAdminSignature = signature => ({
  type: ActionTypes.UPDATE_ADMIN_SIGNATURE,
  signature,
});
