import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import v4 from 'uuid';

const QuickAccess = ({ children, title, image, links = [] }) => {
  const style = {
    backgroundImage: `url(${image})`,
  };

  return (
    <div className='quick-access bg-dark text-right p-5' style={style}>
      <h2 className='text-uppercase font-weight-bold font-size-30 text-white'>{title}</h2>
      {links.map(link => (
        <Link to={link.path} key={v4()} className='btn btn-yellow text-uppercase'>{link.text}</Link>
      ))}
      {children}
    </div>
  );
};

QuickAccess.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape(
    {
      text: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    },
  )),
};

export default QuickAccess;
