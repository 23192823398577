import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

const style = {
  control: (base, state) => ({
    ...base,
    borderRadius: 0,
    border: state.isFocused ? "1px solid #71BF44" : undefined,
    boxShadow: state.isFocused ? "0px 0px 0px 3px rgba(113,191,68,0.2)" : "none",
    minHeight: "50px",
    marginBottom: "10px",
    "&:hover": {
      border: undefined,
    }
  })
};

const InputSelectSearch = ({ options, name, value = '', onChange = () => {}, className = '', isDisabled = false, isClearable = true, isSearchable = true, inputValue = "", 
onInputChange = () => {}}) => (

  <Select id="select-search"
    styles={style}
    className={`${className}`}
    defaultValue={value}
    isDisabled={isDisabled}
    isClearable={isClearable}
    isSearchable={isSearchable}
    value={value}
    name={name}
    options={options}
    onChange={e => e != null & onChange(e)}
    getOptionLabel={e => e.label}
    getOptionValue={e => e.value}
    onInputChange={(value) => value != null ? onInputChange(value) : onInputChange("")}
    inputValue={inputValue}
  />
  )

InputSelectSearch.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  })).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  inputValue: PropTypes.string.isRequired,
  onInputChange: PropTypes.func
};

export default InputSelectSearch;
