import { connect } from 'react-redux';

import WelcomeBlock from '../components/WelcomeBlock';

const mapStateToProps = state => ({
  username: `${state.user.firstname} ${state.user.lastname}`,
});

export default connect(
  mapStateToProps,
  null,
)(WelcomeBlock);
