import EventEmitter from '../domain/EventEmitter';
import { API } from '../api/request';

class CountryService extends EventEmitter {
  add = data => API.post('front/countries/', data);

  edit = data => API.patch(`front/countries/${data.id}`, data, {
    headers: { 'Content-Type': 'application/merge-patch+json' },
  });

  fetchAll = () => API.get('front/countries/all');
}

const instance = new CountryService();

export default instance;
