import PropTypes from 'prop-types';
import React from 'react';
import v3 from 'uuid';

const InputSelect = ({ options, name, value = '', onChange = () => {}, className = '' }) => (
  <select className={`form-control ${className}`} name={name} value={value} onChange={e => onChange(e.target.value)}>
    {options.map(option => (
      <option key={v3()} value={option.value}>{option.label}</option>
    ))}
  </select>
);

InputSelect.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  })).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default InputSelect;
