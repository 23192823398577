import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ReactLoading from 'react-loading';

import CountriesTable from './CountriesTable';
import imgCountry from '../../images/imgCountry.png';

const ParamCountries = ({ changeActivation, items = null, fetchData = () => {} }) => {
  useEffect(() => fetchData(), [fetchData]);

  return items ? (
    <div className='row mx-0'>
      <div className='pt-lg-5 col-9'>
        <CountriesTable items={items} onChangeActivation={data => changeActivation(data)} />
      </div>
      <div className='pt-lg-5 col-3'>
        <img src={imgCountry} alt='Country right side' />
      </div>
    </div>
  ) : (
    <div className='d-flex justify-content-center vh-100'>
      <ReactLoading type='bubbles' color='#8f8f8f' />
    </div>
  );
};

ParamCountries.propTypes = {
  changeActivation: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    position: PropTypes.string,
    isActive: PropTypes.bool.isRequired,
  })),
  fetchData: PropTypes.func,
};

export default ParamCountries;
