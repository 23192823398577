/* eslint-disable no-param-reassign */
import EventEmitter from '../domain/EventEmitter';
import { API } from '../api/request';

class PlotService extends EventEmitter {
  select = plotDetails => {
    plotDetails.measureGroups = null;
    if (
      plotDetails.pilotage
      && plotDetails.pilotage[0]
      && plotDetails.pilotage[0].id
      && plotDetails.pilotage[0].cropId
    ) {
      this.getMeasures(plotDetails.id, plotDetails.pilotage[0].id, plotDetails.pilotage[0].cropId)
        .then(res => {
          if (res.data.data.measureGroup.length > 0) {
            plotDetails.measureGroups = res.data.data.measureGroup;
            this.emit('plotSelected', plotDetails);
          }
        })
        .catch(() => this.emit('plotSelected', plotDetails));
    } else {
      this.emit('plotSelected', plotDetails);
    }
  };

  getMeasures = (idPlot, gpnPilotId, cropId, campaign) => API.get(
    `front/plots/${idPlot}/gpnpilot/${gpnPilotId}/crop/${cropId}/campaign/${campaign}`,
  );

  getByExploitation = idExploitation => API.get(`front/plots/exploitation/${idExploitation}`);

  store = data => API.post('front/plots/add', data);

  edit = (id, data) => API.patch(`front/plots/${id}`, data, {
    headers: { 'Content-Type': 'application/merge-patch+json' },
  });

  importTelepac = data => {
    const formData = new FormData();

    formData.append('exploitation_id', data.exploitation_id);
    formData.append('xml', data.xml, data.xml.name);
    if (data.txt) {
      formData.append('txt', data.txt, data.txt.name);
    }

    return (API.post('front/telepac-import', formData));
  };

  delete = id => API.delete(`front/plots/${id}`);
}

const instance = new PlotService();

export default instance;
