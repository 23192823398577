import PropTypes from 'prop-types';
import React, { useState } from 'react';

import CultureService from '../../../services/CultureService';
import Switch from '../../Switch';

import icEyeBlue from '../../../images/ic_eye_blue.png';
import icDelete from '../../../images/ic_delete.png';

import ModalEntity from '../../ModalEntity';
import Button from '../../Button';

const SubscriptionRow = ({ data, onActivate, action }) => {
  const [visible, setVisible] = useState(false)

  return (
    <tr>
      <td>
        <Switch
          checked={data.isActive}
          name={`subscription_${data.id}_active`}
          onChange={() => onActivate({
            id: data.id,
            isActive: !data.isActive,
          })}
        />
      </td>
      <td>{data.year}</td>
      <td>
        <button
          className='btn btn-icon'
          onClick={() => CultureService.emit('selectSubscription', data.id)}
        >
          <img src={icEyeBlue} alt='Icon eye' width='25' height='auto' />
        </button>
        <button className='btn btn-icon' style={{width: 25}} onClick={() => setVisible(true)}>
          <img src={icDelete} alt='icon delete' width='15' height='auto' />
        </button>

        <ModalEntity title1={"Suppression"} visible={visible} onClose={() => setVisible(false)}>
          <span style={{fontSize: 15, textAlign: 'center'}}>Etes vous sur de vouloir supprimer l'année {data.year} ?</span>
          <div style={{display: 'flex', justifyContent: 'center', marginTop: 25}}>
            <Button 
            title="Confirmer"
            onClick={() => action.find((a) => a.name === "delete_culture").func(data.id) & setVisible(false)}></Button>
            <Button 
            title="Annuler"
            className='button-delete'
            onClick={() => setVisible(false)}></Button>
          </div>
        </ModalEntity>
      </td>
    </tr>
  );
}

SubscriptionRow.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
    isActive: PropTypes.bool.isRequired,
  }),
  onActivate: PropTypes.func.isRequired,
  action: PropTypes.arrayOf(PropTypes.object)
};

export default SubscriptionRow;
