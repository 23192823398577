/* eslint-disable no-console */
import React, { useState, useEffect, useMemo } from 'react';
import ReactLoading from 'react-loading';
// import { useTranslation } from 'react-i18next';

import UserService from '../../services/UserService';

import UserTable from '../Profile/UserTable';
import PageTitle from '../PageTitle';
import orgaImg from '../../images/orgaImg.png';
import Input from '../Input';

const ParamUsers = () => {
  // const { t } = useTranslation('generics');
  const [filter, setFilter] = useState('');
  const [users, setUsers] = useState([]);
  const filteredUsers = useMemo(() => (users.filter(
    data => (
      new RegExp(filter, 'i').test(data.firsname)
      || new RegExp(filter, 'i').test(data.lastname)
      || new RegExp(filter, 'i').test(data.login)
      || new RegExp(filter, 'i').test(data.email)
      || new RegExp(filter, 'i').test(data.pilots.join(''))
    ),
  )
    .sort((a, b) => {
      if (a.lastname < b.lastname) { return -1; }
      if (a.lastname > b.lastname) { return 1; }
      return 0;
    })), [users, filter]);

  useEffect(() => {
    UserService.fetchAll()
      .then(resp => {
        setUsers(resp.data.users);
      });
  }, []);

  const removeAtIndex = (arr, i) => [...arr.slice(0, i), ...arr.slice(i + 1, arr.length)];

  const removeUserId = id => {
    const idx = users.indexOf(users.filter(val => (
      val.id === id
    ))[0]);

    setUsers(removeAtIndex(users, idx));
  };

  const updateActiveUserState = (id, value) => {
    const tmp = [...users];
    const idx = users.indexOf(users.filter(val => (
      val.id === id
    ))[0]);
    tmp[idx].isActive = !value;

    setUsers(tmp);
    setFilter('');
  };

  const deleteUser = id => {
    UserService.deleteUserById(id)
      .then(() => {
        removeUserId(id);
      })
      .catch(e => console.log('ERROR DELETE USER', e));
  };

  const changeActiveUser = (id, value) => {
    UserService.updateUserById(id, { isActive: !value })
      .then(() => {
        updateActiveUserState(id, value);
      })
      .catch(e => console.log(e));
  };

  return users !== [] ? (
    <div>
      <div className='mb-3'>
        <PageTitle title='User management' />
      </div>
      <div className='row mx-0'>
        <div className='col-9'>
          <Input
            name='search'
            placeholder='Search'
            onChange={val => setFilter(val)}
            value={filter}
          />
          <UserTable
            users={filteredUsers}
            changeActiveUser={changeActiveUser}
            deleteUser={deleteUser}
          />
        </div>
        <div className='col-3'>
          <img src={orgaImg} alt='organization right side' />
        </div>
      </div>
    </div>
  ) : (
    <div className='d-flex justify-content-center vh-100'>
      <ReactLoading type='bubbles' color='#8f8f8f' />
    </div>
  );
};

export default ParamUsers;
