import PropTypes from 'prop-types';
import React from 'react';

const PageTitle = ({ title, icon = null }) => {
  const displayTitle = () => (<h1 className='text-uppercase font-weight-bold text-info font-size-30'>{title}</h1>);
  return icon ? (
    <div className='row align-items-center px-5 py-4 mx-0'>
      <div className='col-8'>
        {displayTitle()}
      </div>
      <div className='col-4 text-right'>
        <img src={icon} alt={`${title} icon`} />
      </div>
    </div>
  ) : displayTitle();
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

export default PageTitle;
