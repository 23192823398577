/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import React from 'react';

import InputSelect from './InputSelect';

const InputSelectWithLabel = ({ options, name, label, value = '', onChange = () => {} }) => (
  <label htmlFor={name}>
    {label}
    <InputSelect name={name} options={options} onChange={val => onChange(val)} value={value} />
  </label>
);

InputSelectWithLabel.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  })).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default InputSelectWithLabel;
