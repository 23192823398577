import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import v3 from 'uuid';

import { useTranslation } from 'react-i18next';
import Alert from '../../Alert';
import AddCropEntity from './AddCropEntity';
import CultureService from '../../../services/CultureService';
import CropsTable from './CropsTable';
import PreviousButton from './PreviousButton';

const CultureStep2 = ({ subscriptionId, onSelectCrop }) => {
  const { t } = useTranslation('generics');
  const [items, setItems] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    CultureService.on('selectCrop', idSubscription => onSelectCrop(idSubscription));
  }, [onSelectCrop]);

  const crop_action = [
    {
      name: "delete_crop",
      func: (cropId) => {
        CultureService.deleteCrop(cropId).then(() => {
          setItems(null);
          CultureService.fetchCrops(subscriptionId)
            .then(res => setItems(res.data.crop.countries))
            .catch(err => setError(err.message));
        })
        .catch((err) => {
          setError(err.message)
        })
      }
    },
    {
      name: "edit_crop",
      func: (data) => {
        CultureService.editCrop(data).then(() => {
          setItems(null);
          CultureService.fetchCrops(subscriptionId)
            .then(res => setItems(res.data.crop.countries))
            .catch(err => setError(err.message));
        })
        .catch((err) => {
          setError(err.message)
        })
      }
    }
  ]
  
  useEffect(() => {
    async function fetch() {
      CultureService.fetchCrops(subscriptionId)
        .then(res => setItems(res.data.crop.countries))
        .catch(err => setError(err.message));
    }
    fetch();
  }, [setError, setItems, subscriptionId]);

  const handleAddCrop = data => CultureService.addCrop({
    ...data,
    subscription_id: subscriptionId,
  })
    .then(() => {
      setItems(null);
      CultureService.fetchCrops(subscriptionId)
        .then(res => setItems(res.data.crop.countries))
        .catch(err => setError(err.message));
    })
    .catch(err => setError(err.message));

  return (
    <div>
      {error && (<Alert message={error} />)}
      {items ? (
        <div>
          <div className='text-info text-uppercase mb-3'>
            {t('pages.parameters.culture.Culture')} / {t('pages.parameters.culture.Year')} / <b>{t('pages.parameters.culture.Crop')}</b>
          </div>
          <div className='text-primary font-weight-bold mb-2 text-uppercase'>{t('pages.parameters.culture.Crop')}</div>
          {items.map(item => (
            <div className='py-4 border-bottom mb-4' key={v3()}>
              <CropsTable data={item} action={crop_action}/>
            </div>
          ))}
          <AddCropEntity
            title={t('pages.parameters.culture.Add a crop')}
            placeholder={t('pages.parameters.culture.Name')}
            name='crop_name'
            onAdd={data => handleAddCrop(data)}
            options={items.map(country => ({
              value: country.id,
              label: country.name,
            }))}
          />
          <div className='py-2'>
            <PreviousButton />
          </div>
        </div>
      ) : (<ReactLoading type='bubbles' color='#8f8f8f' />)}
    </div>
  );
};

CultureStep2.propTypes = {
  subscriptionId: PropTypes.number.isRequired,
  onSelectCrop: PropTypes.func.isRequired,
};

export default CultureStep2;
