/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import France from '@svg-maps/france.departments';
import { RadioSVGMap } from 'react-svg-map';
import { useTranslation } from 'react-i18next';

import NatValues from './NatValues';

import SynthesisService from '../../services/SynthesisService';

import crop from '../../images/ic_crop.png';
import bgMap from '../../images/bgMap.png';
import huMap from '../../images/huMap.png';
import atMap from '../../images/atMap.png';
import roMap from '../../images/roMap.png';
import deMap from '../../images/deMap.png';
import rsMap from '../../images/rsMap.png';
import uaMap from '../../images/uaMap.png';
import noMap from '../../images/noMap.png';

const MapCountry = ({ averageNat, filtersSelected, id }) => {
  const [average, setAverage] = useState([]);
  const [selectedDepartement, setSelectedDepartement] = useState(null);
  const [averageDepLoading, setAverageDepLoading] = useState(false);
  const { t } = useTranslation('generics');

  useEffect(() => {
    if (selectedDepartement) {
      setAverageDepLoading(true);
      SynthesisService.generateSynthesis({
        ...filtersSelected,
        department: selectedDepartement.code,
      })
        .then(response => {
          if (response.data.synthesis) {
            setAverage(response.data.synthesis);
          } else {
            setAverage(null);
          }
          setAverageDepLoading(false);
        });
    }
  }, [selectedDepartement]);


  const getMap = () => {
    switch (id) {
      case 1:
        return (
          <RadioSVGMap
            map={France}
            className='map'
            locationClassName='mapLocation'
            onChange={event => (
              setSelectedDepartement({
                code: event.id,
                name: event.attributes.name.value,
              })
            )}
          />
        );
      case 2:
        return <img src={bgMap} alt='Country map' />;
      case 3:
        return <img src={huMap} alt='Country map' />;
      case 4:
        return <img src={atMap} alt='Country map' />;
      case 5:
        return <img src={roMap} alt='Country map' />;
      case 6:
        return <img src={deMap} alt='Country map' />;
      case 7:
        return <img src={rsMap} alt='Country map' />;
      case 8:
        return <img src={uaMap} alt='Country map' />;
      default:
        return <img src={noMap} alt='Country map' />;
    }
  };

  const getUnit = () => (+filtersSelected.univers_id === 2 ? 'kg/m²' : 'kgN/ha');

  return (
    <div className='row p-5 m-0'>
      <div className='col-5 d-flex justify-content-center flex-column'>
        <NatValues average={averageNat} univers={filtersSelected.univers_id} />
        {selectedDepartement && !averageDepLoading && (
          <div className='averageContainer w-100'>
            <h2>{selectedDepartement.name}</h2>
            <div className='line-value'>
              <img src={crop} className='averageImg' alt='crop icon' />
              {average ? (
                <>
                  <p className='averageValue'>{average}</p>
                  <p className='averageText'>{getUnit()}</p>
                </>
              ) : (
                <p className='averageText'>{t('pages.synthesis.noAverageAvailable')}</p>
              )}
            </div>
          </div>
        )}
      </div>
      <div className='col-7'>
        {getMap(id)}
      </div>
    </div>
  );
};

MapCountry.propTypes = {
  id: PropTypes.number.isRequired,
};

export default MapCountry;
