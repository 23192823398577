import PropTypes from 'prop-types';
import React from 'react';

import Switch from '../Switch';
import fr from '../../images/ic_fr.png';
import at from '../../images/ic_at.png';
import rs from '../../images/ic_rs.png';
import ua from '../../images/ic_ua.png';
import hu from '../../images/ic_hu.png';
import ro from '../../images/ic_ro.png';
import de from '../../images/ic_de.png';
import bg from '../../images/ic_bg.png';

const CountryRow = ({ data, onActivate }) => {
  const getFlag = id => {
    switch (id) {
      case 1:
        return <img src={fr} alt='flag country icon' />;
      case 2:
        return <img src={bg} alt='flag country icon' />;
      case 3:
        return <img src={hu} alt='flag country icon' />;
      case 4:
        return <img src={at} alt='flag country icon' />;
      case 5:
        return <img src={ro} alt='flag country icon' />;
      case 6:
        return <img src={de} alt='flag country icon' />;
      case 7:
        return <img src={rs} alt='flag country icon' />;
      case 8:
        return <img src={ua} alt='flag country icon' />;
      default:
        return '';
    }
  };

  return (
    <tr>
      <td>{getFlag(data.id)}<span> {data.name}</span></td>
      <td>{data.code}</td>
      <td>
        <Switch
          checked={data.isActive}
          name={`country_${data.id}_active`}
          onChange={() => onActivate({
            id: data.id,
            isActive: !data.isActive,
          })}
        />
      </td>
    </tr>
  );
};

CountryRow.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
  }),
  onActivate: PropTypes.func.isRequired,
};

export default CountryRow;
