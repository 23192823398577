import React from 'react';
import { useTranslation } from 'react-i18next';
import Sidebar from '../components/Sidebar';

import AddCountry from '../containers/AddCountry';
import AdminMenu from '../components/AdminMenu';
import icParameters from '../images/ic_page_parameters.png';
import PageTitle from '../components/PageTitle';
import ParamCountries from '../containers/ParamCountries';

const ParametersCountries = () => {
  const { t } = useTranslation('generics');

  return (
    <div className='page'>
      <Sidebar title={t('pages.parameters.Parameters')} icon={icParameters}>
        <AdminMenu />
      </Sidebar>
      <div className='page-content short bg-light'>
        <div className='flex-column'>
          <div className='my-4'>
            <PageTitle title={t('pages.parameters.countries.Country Management')} />
          </div>
          <AddCountry />
        </div>
      </div>
      <div className='page-content p-0 mt-5'>
        <ParamCountries />
      </div>
    </div>
  );
};

export default ParametersCountries;
