/* eslint-disable react-hooks/exhaustive-deps */
// THIS FILE NEED REFACTO
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { useTranslation } from 'react-i18next';

import { getUniversTranslate } from '../domain/utils';

import Sidebar from '../components/Sidebar';
import HeaderAction from '../components/HeaderAction';
import InputSelectWithLabel from '../components/InputSelectWithLabel';
import Button from '../components/Button';
import MapCountry from '../components/Synthesis/MapCountry';

import SynthesisService from '../services/SynthesisService';
import UserService from '../services/UserService';

import icSynthesis from '../images/ic_page_synthesis.png';
import synthesisHeader from '../images/header_synthesis.png';

const Synthesis = () => {
  const { t } = useTranslation('generics');
  const [userCountry, setUserCountry] = useState(null);
  const [filterLoaded, setFilterLoaded] = useState(false);
  const [average, setAverages] = useState(null);
  const [noData, setNoData] = useState(true);
  const [loadingCrop, setLoadingCrop] = useState(true);

  const [filtersValues, setFilters] = useState({
    campaigns: [],
    crops: [],
    univers: [],
  });
  const [filtersSelected, setFiltersSelected] = useState({
    campaign: null,
    crop: null,
    univers_id: null,
  });

  const formatDataFilterDefault = data => {
    const formatedData = [];
    data.forEach(val => {
      formatedData.push({ label: val, value: val });
    });

    return (formatedData);
  };

  const formatDataCropFilter = data => {
    const formatedData = [];
    data.forEach(val => {
      formatedData.push({ label: val.name, value: val.name });
    });

    return (formatedData);
  };

  // eslint-disable-next-line no-unused-vars
  const formatDataFilterUnivers = data => {
    const formatedData = [];

    data.forEach(val => {
      formatedData.push({ label: t(getUniversTranslate(val.id)), value: val.id });
    });

    return (formatedData);
  };

  useEffect(() => {
    UserService.getData()
      .then(user => {
        setUserCountry(user.data.User.country);
        SynthesisService.getFilters()
          .then(response => {
            SynthesisService.getCropByUnivers(
              response.data.filters?.univers[0].id,
              response.data.filters?.campaigns[0],
            )
              .then(crops => {
                setFilters({
                  ...filtersValues,
                  campaigns: formatDataFilterDefault(response.data.filters.campaigns),
                  crops: formatDataCropFilter(crops.data.crops),
                  univers: formatDataFilterUnivers(response.data.filters.univers),
                });
                setFiltersSelected({
                  ...filtersSelected,
                  crop: crops?.data?.crops[0]?.name,
                  univers_id: response.data.filters?.univers[0]?.id,
                  campaign: response.data.filters?.campaigns[0],
                });
                setLoadingCrop(false);
              })
              .catch(e => console.error('getCropByUnivers', e));
            setFilterLoaded(true);
          })
          .catch(e => console.error('getFilters', e));
      });
  }, []);

  const generateSynthesis = () => {
    SynthesisService.generateSynthesis({
      ...filtersSelected,
      department: '',
    })
      .then(response => {
        if (response.data.synthesis) {
          setAverages(response.data.synthesis);
          setNoData(false);
        } else {
          setAverages(0);
          setNoData(false);
        }
      });
  };

  const updateUnivers = choice => {
    setLoadingCrop(true);
    SynthesisService.getCropByUnivers(choice, filtersSelected.campaign)
      .then(resp => {
        setFilters({ ...filtersValues, crops: formatDataCropFilter(resp.data.crops) });
        setFiltersSelected({
          ...filtersSelected,
          univers_id: choice,
          crop: resp.data?.crops[0]?.name,
        });
        setLoadingCrop(false);
      });
  };

  const updateCampaign = choice => {
    setLoadingCrop(true);
    SynthesisService.getCropByUnivers(filtersSelected.univers_id, choice)
      .then(resp => {
        setFilters({ ...filtersValues, crops: formatDataCropFilter(resp.data.crops) });
        setFiltersSelected({
          ...filtersSelected,
          campaign: choice,
          crop: resp.data?.crops[0]?.name,
        });
        setLoadingCrop(false);
      });
  };

  const filters = () => (
    filterLoaded ? (
      <div className='px-5 mt-2'>
        <div className='py-2'>
          <InputSelectWithLabel
            label={t('Campaigns')}
            name='campaign'
            options={filtersValues.campaigns}
            value={filtersSelected.campaign}
            onChange={data => updateCampaign(data)}
          />
        </div>
        <div className='py-2'>
          <InputSelectWithLabel
            label={t('Univers')}
            name='univers'
            options={filtersValues.univers}
            value={filtersSelected.univers_id}
            onChange={choice => updateUnivers(choice)}
          />
        </div>
        <div className='py-2'>
          { loadingCrop ? (
            <ReactLoading type='bubbles' color='#8f8f8f' />
          ) : (
            <InputSelectWithLabel
              label={t('pages.synthesis.Crop')}
              name='crop'
              options={filtersValues.crops}
              value={filtersSelected.crop}
              onChange={crop => setFiltersSelected({
                ...filtersSelected,
                crop,
              })}
            />
          )}
        </div>
        <div className='py-2 text-center'>
          <Button
            title={t('form.Generate')}
            onClick={() => generateSynthesis()}
          />
        </div>
      </div>
    ) : (
      <ReactLoading type='bubbles' color='#8f8f8f' />
    )
  );

  return (
    <div className='page'>
      <Sidebar title={t('pages.synthesis.Synthesis')} icon={icSynthesis}>
        {filters()}
      </Sidebar>
      <div id='page-synthesis' className='page-content p-0'>
        <HeaderAction
          action={t('pages.synthesis.Choose your data')}
          description={t('pages.synthesis.Select the fields in the left sidebar')}
          image={synthesisHeader}
        />
        { !noData && (
          userCountry && (
            <MapCountry
              averageNat={average}
              filtersSelected={filtersSelected}
              id={userCountry.id}
            />
          )
        )}
      </div>
    </div>
  );
};

export default Synthesis;
