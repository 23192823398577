/* eslint-disable no-console */
import { API } from '../../api/request';

/*
|--------------------------------------------------------------------------
| DEFINE TYPES
|--------------------------------------------------------------------------
*/

export const ADD_PLOT = 'ADD_PLOT';
export const SET_PLOTS = 'SET_PLOTS';
export const EDIT_PLOT = 'EDIT_PLOT';
export const EDIT_SELECTED_PLOT = 'EDIT_SELECTED_PLOT';
export const DELETE_PLOT = 'DELETE_PLOT';
export const SELECT_PLOT = 'SELECT_PLOT';

/*
|--------------------------------------------------------------------------
| ACTIONS
|--------------------------------------------------------------------------
*/

/**
 * Add new plot
 * @param plot
 * @returns {{plot: *, type: string}}
 */
export const addPlot = plot => ({
  type: ADD_PLOT,
  plot,
});

/**
 * Set plots
 * @param plots
 * @returns {{type: string, plots: *}}
 */
export const setPlots = plots => ({
  type: SET_PLOTS,
  plots,
});

/**
 * Edit specific plot
 * @param plotId
 * @param field
 * @param value
 * @returns {{payload: *, type: string}}
 */
export const editPlot = (plotId, field, value) => ({
  type: EDIT_PLOT,
  payload: {
    plotId,
    field,
    value,
  },
});

/**
 * Edit selected plot
 * @param plotId
 * @param field
 * @param value
 * @returns {{payload: {field: *, plotId: *, value: *}, type: string}}
 */
export const editSelectedPlot = (plotId, field, value) => ({
  type: EDIT_SELECTED_PLOT,
  payload: {
    plotId,
    field,
    value,
  },
});

/**
 * Delete specific plot
 * @param plotId
 * @returns {{plotId: *, type: string}}
 */
export const deletePlot = plotId => dispatch => {
  dispatch({
    type: DELETE_PLOT,
    plotId,
  });
};

/**
 * Select specific plot
 * @param plot
 * @returns {function(...[*]=)}
 */
export const selectPlot = plot => (dispatch, getState) => {
  const state = getState();

  API.get(
    `front/plots/${plot.id}/campaign/${state.filters.campaign || new Date().getFullYear()}/v2`,
  )
    .then(res => {
      dispatch({
        type: SELECT_PLOT,
        plot: res.data.data,
      });
    })
    .catch(e => {
      console.error(e);
    });
};
