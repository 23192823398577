import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React from 'react';
import v3 from 'uuid';

const Stars = ({ score }) => {
  const starsClass = [];
  for (let index = 1; index < 6; index += 1) {
    starsClass.push(index <= score ? 'text-primary' : 'text-grey');
  }

  return (
    <div>
      {starsClass.map(className => (
        <FontAwesomeIcon icon={faStar} className={className} key={v3()} />
      ))}
    </div>
  );
};

Stars.propTypes = {
  score: PropTypes.number,
};

export default Stars;
