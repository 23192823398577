import React from 'react';
import PropTypes from 'prop-types';

import Input from './Input';

const InputWithIcon = ({ name, onChange, onIconClick, icon, type = 'text', placeholder = '', value = '' }) => {
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      onIconClick();
    }
  };

  return (
    <div className='input-group hc-input-w-icon'>
      <Input
        className='form-control'
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      <div className='input-group-append' onClick={onIconClick} tabIndex='0' onKeyPress={handleKeyPress} role='button'>
        <img src={icon} alt='icon' />
      </div>
    </div>
  );
};

InputWithIcon.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onIconClick: PropTypes.func.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default InputWithIcon;
