import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Button = ({ title, theme, onClick = () => {}, isSmall = false, withoutMargin = false, type = 'button', className = '' }) => {
  let cssClass = 'btn-primary';

  if (theme) cssClass = `btn-${theme}`;

  if (isSmall) cssClass = `${cssClass} btn-sm`;

  return (
    <button
      className={clsx(
        `btn text-uppercase m-${theme === 'icon' || withoutMargin ? '0' : '2'}`,
        cssClass,
        className,
      )}
      type={type}
      onClick={() => onClick()}
    >
      { title }
    </button>
  );
};

Button.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  theme: PropTypes.string,
  isSmall: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit']),
  className: PropTypes.string,
  withoutMargin: PropTypes.bool,
};

export default Button;
