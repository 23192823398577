import i18n from '../i18n';

export const loginFormValidate = async credentials => {
  let valid = false;
  let message = null;

  const username = credentials.username ? credentials.username.trim() : '';
  const password = credentials.password ? credentials.password.trim() : '';

  if (username === '' && password === '') {
    message = i18n.t('fieldsError.Fields are required');
    // message = 'fields are required';
  } else if (password === '') {
    message = i18n.t('fieldsError.field is required', { field: i18n.t('pages/login:Password') });
  } else if (username === '') {
    message = i18n.t('fieldsError.field is required', { field: i18n.t('pages/login:Login') });
  } else {
    valid = true;
  }

  return { valid, message };
};

export const formatCoords = rawCoords => {
  if (rawCoords) {
    let parsedCoords = '';
    rawCoords.forEach(coords => {
      parsedCoords += `${coords[1]},${coords[0]}|`;
    });
    return (parsedCoords.substring(0, parsedCoords.length - 1));
  }
  return '';
};

export const formatCampaignsFromAPI = campaigns => campaigns
  .map(item => ({ ...item, year: parseInt(item.year, 10) }))
  .sort((a, b) => b.year - a.year);

export const formatCampaignToFlatArray = campaigns => campaigns.map(item => item.year);

export const getFirstExploitationIdFromCampaign = (campaigns, year) => {
  const filteredCampaign = campaigns.find(item => item.year === year);
  if (filteredCampaign && filteredCampaign.exploitations.length) {
    return filteredCampaign.exploitations[0].id;
  }
  return null;
};

export const cleanExploitations = exploitations => exploitations
  .map(item => ({
    value: parseInt(item.id, 10),
    label: item.company.length === 0 ? `${i18n.t('fieldsError.No name')} - ${item.id}` : item.company,
    ...item,
  }));

export const formatExploitations = (campaigns, year) => {
  const campaign = campaigns.find(item => item.year === year);
  if (campaign) {
    return cleanExploitations(campaign.exploitations);
  }

  return [];
};

const calculateStdScore = value => {
  let score = 0;

  if (value > 0.8) {
    score = 1;
  } else if (value > 0.6) {
    score = 2;
  } else if (value > 0.4) {
    score = 3;
  } else if (value > 0.2) {
    score = 4;
  } else if (value >= 0) {
    score = 5;
  }

  return score;
};

const calculateColzaScore = (value, isRef) => {
  let score = 0;

  if(!isRef) {
    if (value > 40) {
      score = 1;
    } else if (value > 35) {
      score = 2;
    } else if (value > 30) {
      score = 3;
    } else if (value > 25) {
      score = 4;
    } else if (value >= 0) {
      score = 5;
    }
  } else {
    if (value > 25) {
      score = 1;
    } else if (value > 20) {
      score = 2;
    } else if (value > 15) {
      score = 3;
    } else if (value > 10) {
      score = 4;
    } else if (value >= 0) {
      score = 5;
    }
  }

  return score;
};

// eslint-disable-next-line arrow-body-style
export const getMeasureScore = (value, idUnivers, isRef = false) => {
  return idUnivers === 2 ? calculateColzaScore(value, isRef) : calculateStdScore(value);
};

export const updateReportsFilter = (arrayToUpadte, data) => {
  const arrayIndex = arrayToUpadte.indexOf(data.value);
  if (data.checked) {
    return arrayIndex === -1 ? [...arrayToUpadte, data.value] : arrayToUpadte;
  }

  arrayToUpadte.splice(arrayIndex, 1);
  return arrayToUpadte;
};

export const groupsToSelect = groups => groups.map(item => ({ value: item.id, label: item.name }));

export const addExploitationIntoAGroup = (groups, exploitation) => groups.map(grp => (
  {
    ...grp,
    exploitations: grp.id === exploitation.groupId
      ? [...grp.exploitations, exploitation]
      : grp.exploitations,
  }
));

export const addExploitationToFirstCampaign = (state, exploitation) => {
  state[0].exploitations.push(exploitation);

  return state;
};

export const isFloat = value => parseFloat(value.match(/^-?\d*(\.\d+)?$/)) > 0;

export const getAllExploitations = exploitationState => {
  const exploitationsFormatted = [];
  exploitationState.forEach(campaign => {
    const { year, exploitations } = campaign;

    exploitations.forEach(expl => {
      const existingExploitation = exploitationsFormatted.findIndex(item => item.id === expl.id);
      if (existingExploitation === -1) {
        exploitationsFormatted.push({ ...expl, campaigns: [year] });
      } else {
        exploitationsFormatted[existingExploitation].campaigns.push(year);
      }
    });
  });

  return exploitationsFormatted;
};

export const getFirstExploitationForEachCampaign = campaigns => {
  const result = {};
  campaigns.forEach(campaign => {
    const { year, exploitations } = campaign;
    if (exploitations.length > 0) {
      result[year] = exploitations[0].id;
    }
  });

  return result;
};

export const getUniversTranslate = id => {
  switch (id) {
    case 1:
      return ('univers.Cereals');
    case 2:
      return ('univers.Rapeseed');
    case 3:
      return ('univers.Corn');
    case 4:
      return ('univers.Potatoe');
    case 5:
      return ('univers.Grassland');
    case 6:
      return ('univers.Linen');
    case 7:
      return ('univers.Sunflower');
    default:
      return ('univers.UniverseError');
  }
};

export const getFirstPositionFromPolygon = polygon => {
  if (polygon && polygon.length) {
    const data = JSON.parse(polygon);
    if (data.length && data[0].length === 2) {
      return data[0].reverse();
    }
  }
  return null;
};
