import EventEmitter from '../domain/EventEmitter';
import { API } from '../api/request';

class CultureService extends EventEmitter {
  fetchCrops = id => API.get(`front/univers/subscription/${id}`);

  fetchCropDetail = id => API.get(`front/univers/crops/${id}`);

  fetchUnivers = () => API.get('front/univers/get-subscriptions');

  addSubscription = data => API.post('front/subscriptions/add', data);

  deleteSubscription = (subId) => API.delete(`front/subscriptions/remove/${subId}`)

  addCrop = data => API.post('front/crops/add', data);

  deleteCrop = (cropId) => API.delete(`front/crops/${cropId}`)

  editCrop = (crop) => API.patch(`front/crops/${crop.id}`, crop)

  importCrop = (file, cultureId) => {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return API.post(`front/univers/crops/${cultureId}/import`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  updateSubscription = data => API.patch(
    `front/subscriptions/edit/${data.id}`,
    { isActive: data.isActive },
    { headers: { 'Content-Type': 'application/merge-patch+json' } },
  );
}

const instance = new CultureService();

export default instance;
