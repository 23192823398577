/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Alert from './Alert';
import Button from './Button';
import Input from './Input';
import InputPassword from './InputPassword';

const LoginForm = ({ onLogin, alert = '' }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { t } = useTranslation('generics');
  const history = useHistory();

  const handleOnLogin = async event => {
    if (event) event.preventDefault();
    await onLogin({ username, password })
      .then(() => {
        history.push('/');
      })
      .catch(e => {})
  };

  return (
    <>
      { alert.length ? (
        <Alert message={alert} />
      ) : '' }
      <form onSubmit={e => handleOnLogin(e)}>
        <label className='mb-4' htmlFor='login'>{t('pages.login.Login')}
          <Input name='login' onChange={value => setUsername(value)} value={username} />
        </label>

        <label className='mb-4' htmlFor='password'>{t('pages.login.Password')}
          <InputPassword onChange={value => setPassword(value)} value={password} />
        </label>
        <Button title={t('Connection')} type='submit' theme='outline-primary' />
      </form>
    </>
  );
};

LoginForm.propTypes = {
  alert: PropTypes.string,
  onLogin: PropTypes.func.isRequired,
};

export default LoginForm;
