import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ReactLoading from 'react-loading';

import { useTranslation } from 'react-i18next';
import PilotsTable from './PilotsTable';
import PageTitle from '../PageTitle';
import pilotImg from '../../images/pilotImg.png';

const ParamPilots = ({
  items = null,
  fetchData = () => {},
  onDelete,
  userList = [],
  subscriptionList = [],
}) => {
  const { t } = useTranslation('generics');
  useEffect(() => fetchData(), [fetchData]);

  return items ? (
    <div>
      <div className='mb-3 clearfix r'>
        <div className='d-flex justify-content-between'>
          <PageTitle title={t('pages.parameters.menu.Pilot management')} />
          <div className='mr-5'>
            <Link to='/parameters/pilots/update-subscription' className='btn btn-primary float-right m-2'>{t('form.Update')}</Link>
          </div>
        </div>
      </div>
      <div className='row mx-0'>
        <div className='col-9'>
          <PilotsTable
            items={items}
            handleDelete={id => onDelete(id)}
            userList={userList}
            subscriptionList={subscriptionList}
          />
        </div>
        <div className='col-3 p-0'>
          <img src={pilotImg} alt='pilot right side' />
        </div>
      </div>
    </div>
  ) : (
    <div className='d-flex justify-content-center vh-100'>
      <ReactLoading type='bubbles' color='#8f8f8f' />
    </div>
  );
};

ParamPilots.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    serialNumber: PropTypes.string.isRequired,
    subscriptions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      year: PropTypes.number.isRequired,
      isActive: PropTypes.bool.isRequired,
      universId: PropTypes.number.isRequired,
      universName: PropTypes.string.isRequired,
      universCode: PropTypes.string.isRequired,
    })).isRequired,
    users: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      lastname: PropTypes.string.isRequired,
      firstname: PropTypes.string.isRequired,
    })).isRequired,
  })),
  fetchData: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  userList: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  })),
  subscriptionList: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  })),
};

export default ParamPilots;
