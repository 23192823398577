import React from 'react';
import { useTranslation } from 'react-i18next';

const Route404 = () => {
  const { t } = useTranslation('generics');

  return (
    <div><h1>{t('pages.reports.errors.404')}</h1></div>
  );
};

export default Route404;
