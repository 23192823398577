import { API } from '../api/request';

class UserService {
  getFilters = () => API.get('front/synthesis/get-filters');

  getVarieties = crop => API.get(`front/synthesis/get-varieties?cropName=${crop}`);

  generateSynthesis = data => API.post('/front/synthesis/generateV2', data);

  getCropByUnivers = (univers, campaign) => API.get(`/front/crops/univers/${univers}/campaign/${campaign}`);
}

const instance = new UserService();

export default instance;
