/*
| Filename : constants.js
| Description : Plot map constants
*/

/**
 * Quadra France position
 * @type {({lng: number, lat: number})[]}
 */
export const CENTER_BOUNDS_POSITION = [
  { lat: 42.7735751870702, lng: 2.02675242729883 },
  { lat: 46.128788298397986, lng: -4.43320851020117 },
  { lat: 50.87041058314889, lng: 1.6751899272988302 },
  { lat: 47.03488960086175, lng: 7.69569773979883 },
];

/**
 * Google maps position
 * @type {{mapTypeControl: boolean, mapTypeId: string, clickableIcons: boolean, scaleControl: boolean, streetViewControl: boolean, fullscreenControl: boolean, zoomControl: boolean, rotateControl: boolean}}
 */
export const GOOGLE_MAPS_OPTIONS = {
  zoomControl: true,
  scaleControl: true,
  rotateControl: true,
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  clickableIcons: false,
  mapTypeId: 'satellite',
};

/**
 * Options for Google Maps polygon
 * @type {{fillColor: string, fillOpacity: number, draggable: boolean, strokeWeight: number, strokeColor: string, strokeOpacity: number}}
 */
export const POLYGON_OPTIONS = {
  fillColor: '#FFCB05',
  fillOpacity: 0.5,
  strokeColor: '#FFCB05',
  strokeOpacity: 0.8,
  strokeWeight: 3,
  draggable: false,
};
