/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import React from 'react';

import Input from './Input';

const InputWithLabel = ({
  label,
  name,
  onChange,
  placeholder = '',
  type = 'text',
  value = '',
  disabled = false
}) => (
  <label htmlFor={name}>
    {label}
    <Input
      disabled={disabled}
      name={name}
      onChange={val => onChange(val)}
      placeholder={placeholder}
      type={type}
      value={value}
    />
  </label>
);

InputWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default InputWithLabel;
