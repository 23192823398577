import PropTypes from 'prop-types';
import React from 'react';
import v3 from 'uuid';

import SubscriptionRow from './SubscriptionRow';

const SubscriptionsTable = ({ onChangeActivation, items, action }) => (
  <table className='table table-borderless table-hover'>
    <tbody>
      {items.map(item => (
        <SubscriptionRow
          data={item}
          key={v3()}
          onActivate={data => onChangeActivation(data)}
          action={action}
        />
      ))}
    </tbody>
  </table>
);

SubscriptionsTable.propTypes = {
  onChangeActivation: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
    isActive: PropTypes.bool.isRequired,
  })).isRequired,
  action: PropTypes.arrayOf(PropTypes.object)
};

export default SubscriptionsTable;
