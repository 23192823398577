import PropTypes from 'prop-types';
import React from 'react';
import ReactLoading from 'react-loading';

import { useTranslation } from 'react-i18next';
import UpdateSubscription from './UpdateSubscription';
import PageTitle from '../PageTitle';

const ParamPilotsEditSubs = ({ items = null }) => {
  const { t } = useTranslation('generics');
  const sortedItems = items ? items.sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  }) : null;

  if (items) {
    return (
      <div>
        <div className='mb-3'>
          <PageTitle title={t('pages.parameters.pilots.edit-sub.Subscription update')} />
        </div>
        <UpdateSubscription subscriptions={sortedItems} />
      </div>
    );
  }
  return (
    <div className='d-flex justify-content-center vh-100'>
      <ReactLoading type='bubbles' color='#8f8f8f' />
    </div>
  );
};

ParamPilotsEditSubs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  })),
};

export default ParamPilotsEditSubs;
