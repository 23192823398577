import { Link } from 'react-router-dom';
import React from 'react';

import { useTranslation } from 'react-i18next';
import ProfileDetails from '../containers/ProfileDetails';
import OrganizationTabs from '../containers/OrganizationTabs';
import Sidebar from '../components/Sidebar';

import profileHeader from '../images/profileHeader.png';
import icProfile from '../images/ic_page_profile.png';

const Profile = () => {
  const { t } = useTranslation('generics');

  return (
    <div className='page'>
      <Sidebar title={t('Profile')} icon={icProfile}>
        <ProfileDetails />
        <div className='text-center'>
          <Link to='/contact' className='btn btn-yellow'>{t('pages.profile.Contact us')}</Link>
        </div>
      </Sidebar>
      <div className='page-content p-0'>
        <img src={profileHeader} alt='header profile' />
        <OrganizationTabs />
      </div>
    </div>
  );
};

export default Profile;
