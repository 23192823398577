import PropTypes from 'prop-types';
import React from 'react';
import v3 from 'uuid';

import { useTranslation } from 'react-i18next';
import StageRow from './StageRow';

const StagesTable = ({ items }) => {
  const { t } = useTranslation('generics');

  return (
    <table className='table table-striped'>
      <thead className='thead-dark'>
        <tr>
          <th>{t('pages.parameters.culture.Name')}</th>
          <th />
          <th>{t('pages.parameters.culture.Class')}</th>
        </tr>
      </thead>
      <tbody>
        {items.map(item => (
          <StageRow
            data={item}
            key={v3()}
          />
        ))}
      </tbody>
    </table>
  );
};

StagesTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    class: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    abbr: PropTypes.string.isRequired,
  })).isRequired,
};

export default StagesTable;
