import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import ExploitationService from '../../services/ExploitationService';
import Organization from './Organization';
import Tabs from '../Tabs';

const OrganizationTabs = ({ organizations = [], getProfile = () => {}, user = 'USER', adminMode = false }) => {
  useEffect(() => ExploitationService.on('exploitationEdited', () => getProfile()), [getProfile]);
  useEffect(() => ExploitationService.on('exploitationAdded', () => getProfile()), [getProfile]);
  // FIX DELETE EXPLOITATION

  const makeTabs = () => {
    const tabs = [];
    organizations.forEach(org => {
      tabs.push({
        id: org.id,
        title: org.name,
        component: (<Organization data={org} roles={user.roles} adminMode={adminMode} />),
      });
    });

    return tabs;
  };

  return (
    <Tabs elements={makeTabs()} />
  );
};

OrganizationTabs.propTypes = {
  organizations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    postcode: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    exploitations: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      company: PropTypes.string.isRequired,
      address: PropTypes.string,
      address2: PropTypes.string,
      postcode: PropTypes.string.isRequired,
      city: PropTypes.string,
      email: PropTypes.string,
      campain: PropTypes.number.isRequired,
    })).isRequired,
  })).isRequired,
  getProfile: PropTypes.func,
};

export default OrganizationTabs;
