import { NavLink } from 'react-router-dom';
import React from 'react';
import v4 from 'uuid';

import { useTranslation } from 'react-i18next';
import { adminMenuItems } from '../router';

const AdminMenu = () => {
  const { t } = useTranslation('generics');

  return (
    <nav id='admin-menu' className='px-5'>
      { adminMenuItems.map(item => (
        <NavLink
          to={item.path}
          key={v4()}
          title={item.label}
          activeClassName='active'
        >
          {t(`pages.parameters.menu.${item.label}`)}
        </NavLink>
      )) }
    </nav>
  );
};

export default AdminMenu;
