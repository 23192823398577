/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';

import Sidebar from '../components/Sidebar';
import ProfileDetails from '../components/Profile/ProfileDetails';
import OrganizationTabs from '../components/Profile/OrganizationTabs';

import icProfile from '../images/ic_menu_profile.png';
import profileHeader from '../images/profileHeader.png';
import UserService from '../services/UserService';

const ParametersDetailsGroup = ({ match }) => {
  const { t } = useTranslation('generics');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const { params } = match;

  useEffect(() => {
    UserService.getById(params.id)
      .then(resp => {
        setData(resp.data.user);
        setLoading(false);
      });
  }, []);

  return (
    <div className='page'>
      { !loading && data ? (
        <>
          <Sidebar title={t('Profile')} icon={icProfile}>
            <ProfileDetails data={data} adminMode />
          </Sidebar>
          <div className='page-content p-0'>
            <img src={profileHeader} alt='header profile' />
            <OrganizationTabs
              organizations={data.groups}
              user={{ roles: 'ROLE_SUPER_ADMIN' }}
              adminMode
            />
          </div>
        </>
      ) : (
        <ReactLoading type='bubbles' color='#8f8f8f' />
      )}
    </div>
  );
};

export default ParametersDetailsGroup;
