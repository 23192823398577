// TODO: Rework this class component to functionnal component for add I18N hook

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import Alert from './Alert';
import EditExploitationForm from './EditExploitationForm';
import ExploitationService from '../services/ExploitationService';
import ModalEntity from './ModalEntity';

function EditExploitation({ groupId }) {
  const { t } = useTranslation('generics');
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    ExploitationService.on('editExploitation', () => setVisible(true));
    ExploitationService.on('exploitationEdited', () => setVisible(false));
    ExploitationService.on('editError', err => setError(err));
  }, []);

  const handleOnSave = data => {
    ExploitationService.edit({ ...data, groupId })
      .then(() => ExploitationService.emit('exploitationEdited'))
      .catch(err => ExploitationService.emit('editError', err.message));
  };

  return (
    <ModalEntity title1={t('form.Edit')} title2={t('pages.profile.organization.modal.Exploitation')} visible={visible} onClose={() => setVisible(false)}>
      {error && <Alert message={error} />}
      <EditExploitationForm onSave={data => handleOnSave(data)} />
    </ModalEntity>
  );
}

EditExploitation.propTypes = {
  groupId: PropTypes.number.isRequired,
};

export default EditExploitation;
