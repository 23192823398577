import axios from 'axios';

export const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
});

export const setSession = token => {
  if (token) {
    localStorage.setItem('jwt_token', token);
    API.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    localStorage.removeItem('jwt_token');
    delete API.defaults.headers.common.Authorization;
  }
};
