/* eslint-disable jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { useTranslation } from 'react-i18next';
import InputImageWithLabel from '../../InputImageWithLabel';
import InputWithLabel from '../../InputWithLabel';
import Button from '../../Button';

const EditSignature = ({ data, onSave }) => {
  const { t } = useTranslation('generics');
  const [content, setContent] = useState(data.content);
  const [image, setImage] = useState(data.image);
  const [imgLink, setImgLink] = useState(data.link);

  useEffect(() => {
    setContent(data.content);
    setImgLink(data.link);
    setImage(data.image);
  }, [data]);

  return (
    <div className='container-fluid pt-3'>
      <div className='text-info font-weight-bold text-uppercase mb-3'>{t('form.Edit')}</div>
      <label>{t('pages.parameters.signatures.Text')}</label>
      <CKEditor
        editor={ClassicEditor}
        data={content}
        onChange={(event, editor) => {
          const value = editor.getData();
          setContent(value);
        }}
      />
      <div className='my-5'>
        <InputImageWithLabel
          label={t('pages.parameters.signatures.Image')}
          name='image'
          onChange={img => setImage(img)}
          value={data.image}
        />
      </div>

      <div className='my-5'>
        <InputWithLabel
          label={t('pages.parameters.signatures.Link image')}
          name='link'
          onChange={val => setImgLink(val)}
          value={imgLink}
        />
      </div>

      <Button title={t('form.Save')} onClick={() => onSave({ content, image, imgLink })} />
    </div>
  );
};

EditSignature.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    country: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    }).isRequired,
    isActive: PropTypes.bool.isRequired,
    link: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }),
  onSave: PropTypes.func.isRequired,
};

export default EditSignature;
