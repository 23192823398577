import set from 'lodash/fp/set';
import { ADD_PLOT, DELETE_PLOT, EDIT_PLOT, EDIT_SELECTED_PLOT, SELECT_PLOT, SET_PLOTS } from './actions';

/*
|--------------------------------------------------------------------------
| INITIAL STATE
|--------------------------------------------------------------------------
*/
const initialSelectedState = {
  organization: [
    {
      name: '',
      address: '',
      address2: '',
      postcode: '',
      city: '',
      email: '',
      phone: '',
      fax: '',
      logo: '',
      exploitation: [
        {
          company: '',
          address: '',
          address2: '',
          postcode: '',
          city: '',
          lastname: '',
          firstname: '',
          email: '',
          plot: [
            {
              id: 0,
              name: '',
              surface: 0,
              ilot: '',
              texture: '',
              stone: '',
              groundDepth: '',
              organicMaterial: '',
              polygon: '',
              pilotage: [
                {
                  lng: null,
                  lat: null,
                  crop: '',
                  variety: '',
                  plot: '',
                  date: '',
                  measureGroup: [
                    {
                      id: 0,
                      date: {
                        date: '',
                        timezone_type: 0,
                        timezone: '',
                      },
                      advice: 0,
                      stageClass: '',
                      operator: '',
                      height: 0,
                      gpnNumber: '',
                      stageName: '',
                      measures: [
                        {
                          id: 0,
                          gpnIndex: 0,
                          qualityIndex: 0,
                          gpnAdvice: null,
                          comment: '',
                          startCoordinates: [
                            0,
                            0,
                          ],
                          arrivalCoordinates: [
                            0,
                            0,
                          ],
                          areaReference: true,
                          percentReference: null,
                          ET: 0,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
const initialState = {
  data: [],
  selected: initialSelectedState,
};

/*
|--------------------------------------------------------------------------
| REDUCER
|--------------------------------------------------------------------------
*/
export const plotsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLOTS:
      return {
        data: action.plots,
        selected: initialSelectedState,
      };
    case ADD_PLOT:
      return {
        ...state,
        data: [...state.data, action.plot],
      };
    case EDIT_PLOT:
      return {
        ...state,
        data: state.data.map(plot => {
          if (plot.id === action.payload.id) {
            return action.payload.id;
          }

          return plot;
        }),
      };
    case EDIT_SELECTED_PLOT: {
      const selectedData = set(action.payload.field, action.payload.value, state.selected);

      return {
        data: state.data.map(plot => {
          if (plot.id === action.payload.plotId) {
            return selectedData;
          }

          return plot;
        }),
        selected: selectedData,
      };
    }
    case DELETE_PLOT: {
      const filteredPlot = state.data.filter(plot => plot.id !== action.plotId);

      if (state.selected && state.selected.id && state.selected.id === action.plotId) {
        return {
          selected: initialSelectedState,
          data: filteredPlot,
        };
      }

      return {
        ...state,
        data: filteredPlot,
      };
    }
    case SELECT_PLOT: {
      return {
        ...state,
        selected: {
          // fix this shiiiiit
          ...initialSelectedState,
          ...action.plot,
        },
      };
    }
    default:
      return state;
  }
};
