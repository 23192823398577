import PropTypes from 'prop-types';
import React, { useState } from 'react';

const InputCheckBox = ({ checked = false, id, name, onChange = () => {} }) => {
  const [isChecked, setChecked] = useState(checked);

  return (
    <input
      checked={isChecked}
      id={id}
      name={name}
      type='checkbox'
      onChange={() => {
        setChecked(!isChecked);
        onChange(!isChecked);
      }}
    />
  );
};

InputCheckBox.propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default InputCheckBox;
