import { connect } from 'react-redux';

import { getAllExploitations, cleanExploitations } from '../domain/utils';
import ReportsFilters from '../components/Reports/ReportsFilters';

const formatExploitation = exploitations => {
  const formatedExploitations = [];
  let formatedPlots = [];
  exploitations.forEach(exploitation => {
    formatedPlots = [];
    exploitation.plots.forEach(plot => {
      formatedPlots.push({ label: plot.name, value: plot.id });
    });
    if (formatedPlots.length > 0) {
      formatedExploitations.push({
        label: exploitation.label,
        value: `exp-${exploitation.id}`,
        children: formatedPlots,
      });
    }
  });
  return (formatedExploitations);
};

const mapStateToProps = state => ({
  campaigns: state.campaigns.map(item => ({ label: String(item), value: item })),
  exploitations: formatExploitation(cleanExploitations(getAllExploitations(state.exploitations))),
});

export default connect(
  mapStateToProps,
  null,
)(ReportsFilters);
