import PropTypes from 'prop-types';
import React from 'react';

const StageRow = ({ data }) => (
  <tr>
    <td>{data.name}</td>
    <td>{data.abbr}</td>
    <td>{data.class}</td>
  </tr>
);

StageRow.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    class: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    abbr: PropTypes.string.isRequired,
  }),
};

export default StageRow;
