/* eslint-disable no-console */
import EventEmitter from '../domain/EventEmitter';
import { API } from '../api/request';

class ReportsService extends EventEmitter {
  getData = data => {
    this.getReports(data)
      .then(res => this.emit('resultReceived', res.data))
      .catch(err => console.log('getReports error', err));
    this.getCsv(data)
      .then(res => this.emit('CsvReceived', new Blob([res.data])))
      .catch(() => this.emit('CsvReceived', null));
  }

  getReports = data => API.post('front/reports/v4', data);

  getCsv = data => API.post('front/reports/v2/csv', data, {
    responseType: 'blob',
  });
}

const instance = new ReportsService();

export default instance;
