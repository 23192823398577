import PropTypes from 'prop-types';
import React from 'react';
import v3 from 'uuid';

import { useTranslation } from 'react-i18next';
import CropRow from './CropRow';

const CropsTable = ({ data, action = [] }) => {
  const { t } = useTranslation('generics');

  return (
    <div>
      <div className='country-name text-info h5'>
        {data.name}
      </div>
      {data.crops.length ? (
        <table className='table table-borderless table-hover'>
          <tbody>
            {data.crops.map(item => (
              <CropRow
                data={item}
                key={v3()}
                action={action}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <p className='message text-muted font-italic'>{t('pages.parameters.culture.No crop for this country')}</p>
      )}
    </div>
  );
};

CropsTable.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    crops: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  action: PropTypes.arrayOf(PropTypes.object)
};

export default CropsTable;
