import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import Alert from '../Alert';
import Button from '../Button';
import InputSelectWithLabel from '../InputSelectWithLabel';
import SubscriptionService from '../../services/SubscriptionService';

const UpdateSubscription = ({ subscriptions }) => {
  const { t } = useTranslation('generics');
  const defaultValue = subscriptions.length ? subscriptions[0].value : '';
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState({
    subscriptionFromId: defaultValue,
    subscriptionNewId: defaultValue,
  });

  const handleOnChange = (field, val) => setData({ ...data, [field]: parseInt(val, 10) });

  const handleSubmit = () => {
    setSuccess(false);
    setError(null);
    if (data.subscriptionFromId === data.subscriptionNewId) {
      setError(t('fieldsError.Fields cannot be identical'));
    } else {
      SubscriptionService.replaceBy(data)
        .then(() => setSuccess(true))
        .catch(err => setError(err.message));
    }
  };

  return (
    <div>
      {error && (<Alert message={error} />)}
      {success && (<Alert message={t('pages.parameters.pilots.edit-subs.Subscription edited')} theme='success' />)}
      <InputSelectWithLabel
        label={t('pages.parameters.pilots.edit-subs.Subscription to be updated')}
        name='sub_from'
        options={subscriptions}
        value={data.subscriptionFromId}
        onChange={val => handleOnChange('subscriptionFromId', parseInt(val, 10))}
      />
      <InputSelectWithLabel
        label={t('pages.parameters.pilots.edit-subs.Replace with')}
        name='sub_new'
        options={subscriptions}
        value={data.subscriptionNewId}
        onChange={val => handleOnChange('subscriptionNewId', parseInt(val, 10))}
      />
      <Button onClick={() => handleSubmit()} title={t('form.Validate')} />
    </div>
  );
};

UpdateSubscription.propTypes = {
  subscriptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  })).isRequired,
};

export default UpdateSubscription;
