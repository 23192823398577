import { API } from '../api/request';
import EventEmitter from '../domain/EventEmitter';

class ExploitationService extends EventEmitter {
  store = data => API.post('front/exploitations/add', data);

  delete = id => API.delete(`front/exploitations/${id}`);

  getAll = () => API.get('front/users/get-exploitations');

  edit = data => API.patch(`front/exploitations/${data.id}`, data, {
    headers: { 'Content-Type': 'application/merge-patch+json' },
  });
}

const instance = new ExploitationService();

export default instance;
