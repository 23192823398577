import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import LangSelector from '../components/LangSelector';
import LoginForm from '../containers/LoginForm';
import Button from '../components/Button';
import ModalEntity from '../components/ModalEntity';
import RegisterForm from '../components/Forms/RegisterForm';

import logo from '../images/logo_app.png';
import bgLogin from '../images/bg_login_page.png';
import logoNpilot from '../images/logo_n-pilot.png';
import AppService from '../services/AppService'

const Login = () => {
  const { t } = useTranslation('generics');
  const [token, setToken] = useState(localStorage.getItem('jwt_token'));
  const [visible, setVisible] = useState(false);
  const { i18n } = useTranslation();
  // useEffect(() => AppService.on('logged', userToken => setToken(userToken)), [setToken]);
  // useEffect(() => AppService.on('logout', () => setToken(null)), [setToken]);
  useEffect(() => i18n.changeLanguage('en'), [])

  return (
    <>
      <div id='page-login' className='page'>
        <div className='column vh-auto vh-lg-100'>
          <header className='bg-light p-5'>
            <img src={logo} alt='Borealis logo' />
          </header>
          <div className='content p-3 p-lg-5'>
            <div className='pb-3 mb-4 border-bottom clearfix'>
              <h2 className='h5 text-info text-uppercase float-left'>{t('pages.login.Select your language')}</h2>
              <div className='float-right'>
                <LangSelector />
              </div>
            </div>
            <p className='text-info'>{t('pages.login.Link account informations')}</p>
            <div className='row align-items-center py-4'>
              <div className='col-md-5 mb-4'>
                <h2 className='h5 text-info text-uppercase mb-3 ml-2'>{t('pages.login.New member')}</h2>
                <p className='text-info mb-3 ml-2'>{t('pages.login.Create your account')}</p>
                <Button title={t('pages.login.Register')} onClick={() => setVisible(true)} />
              </div>
              <div className='col-md-7 px-5 py-4 bg-light'>
                <h2 className='h5 text-info text-uppercase mb-4'>{t('pages.login.oldMember')}</h2>
                <LoginForm />
              </div>
            </div>
          </div>
          <footer className='bg-light p-4 d-inline-block'>
            <a href='https://www.lat-nitrogen.com/' className='text-info btn btn-link text-uppercase'>{t('pages.login.Return to Borealis LAT website')}</a>
          </footer>
        </div>
        <div className='has-background column vh-100 text-white' style={{ backgroundImage: `url(${bgLogin})` }}>
          <div id='block-npilot' className='h4 text-white text-center px-4 py-5 mx-auto mb-0'>
            <img src={logoNpilot} alt='logo n-pilot' />
            <div className='pt-3'>{t('pages.login.Smart fertilisation')}</div>
          </div>
        </div>

        <ModalEntity title1={t('form.register')} visible={visible} onClose={() => setVisible(false)}>
          <RegisterForm closeModal={() => setVisible(false)} />
        </ModalEntity>
      </div>
    </>
  );
};

export default Login;
