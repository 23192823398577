
import { connect } from 'react-redux';
import OrganizationTabs from '../components/Profile/OrganizationTabs';

import ProfileService from '../services/ProfileService';
import { setUser } from '../redux/actions';

const mapStateToProps = state => ({
  organizations: state.user.groups,
  user: state.user,
});

const mapDispatchtoProps = dispatch => ({
  getProfile: () => ProfileService.getProfile()
    .then(profileData => dispatch(setUser(profileData.data.User)))
    .catch(profileErr => console.log(profileErr)),
});

export default connect(mapStateToProps, mapDispatchtoProps)(OrganizationTabs);
