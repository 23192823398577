import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import Alert from '../Alert';
import FarmActions from './FarmActions';
import FarmTable from './FarmTable';
import UserTable from './UserTable';
import OrganizationDetails from './OrganizationDetails';
import TitleOffset from '../TitleOffset';

const Organization = ({ data, roles, adminMode }) => {
  const { t } = useTranslation('generics');
  const [error, setError] = useState(null);
  const [succes, setSucces] = useState(false);
  return (
    <div>
      {((roles.indexOf('ROLE_SUPER_ADMIN') !== -1) || (roles.indexOf('DISTRIBUTEUR') !== -1)) && (
        <div className='p-5 bg-light'>
          {succes && (<Alert theme='success' message={t('pages.profile.organization.alerts.Organization saved')} />)}
          {error && (<Alert message={error} />)}
          <TitleOffset title={t('pages.profile.Organisation')} />
          <OrganizationDetails
            data={{
              id: data.id,
              name: data.name,
              company: data.company,
              postcode: data.postcode,
              phone: data.phone,
              address1: data.address1,
              city: data.city,
              email: data.email,
              logo: data.logo,
            }}
            onError={message => {
              setSucces(false);
              setError(message);
            }}
            onSuccess={() => {
              setError(null);
              setSucces(true);
            }}
            adminMode={adminMode}
          />
        </div>
      )}
      <div className='p-5 p-lg-5'>
        <TitleOffset title={t('pages.profile.Farm')} />
        <FarmTable farms={data.exploitations} adminMode={adminMode} />
        {!adminMode && <FarmActions groupId={data.id} />}
      </div>
      { data.users && (
        <div className='p-5 p-lg-5'>
          <TitleOffset title='Utilisateurs' />
          <UserTable users={data.users} />
          {/* <FarmActions groupId={data.id} /> */}
        </div>
      )}
    </div>
  );
};

export default Organization;
