import PropTypes from 'prop-types';
import React from 'react';

const VarietyRow = ({ data }) => (
  <tr>
    <td>{data.name}</td>
    <td>{data.c1}</td>
    <td>{data.c2}</td>
    <td>{data.class}</td>
  </tr>
);

VarietyRow.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    class: PropTypes.number.isRequired,
    c1: PropTypes.number.isRequired,
    c2: PropTypes.number.isRequired,
  }),
};

export default VarietyRow;
