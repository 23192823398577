import icParameters from './images/ic_menu_parameters.png';
import icPlot from './images/ic_menu_plot.png';
import icProfile from './images/ic_menu_profile.png';
import icReport from './images/ic_menu_reports.png';
import icSynthesis from './images/ic_menu_synthesis.png';

export const menuItems = [
  { icon: icProfile, label: 'Profile', path: '/profile' },
  { icon: icPlot, label: 'Plot', path: '/plot' },
  { icon: icReport, label: 'Reports', path: '/reports' },
  { icon: icSynthesis, label: 'Synthesis', path: '/synthesis' },
];
export const adminItem = { icon: icParameters, label: 'parameters.Parameters', path: '/parameters' };

export const adminMenuItems = [
  { label: 'Users management', path: '/parameters/users' },
  { label: 'Campaign', path: '/parameters/campaign' },
  { label: 'Pilot management', path: '/parameters/pilots' },
  { label: 'Organizations', path: '/parameters/groups' },
  { label: 'Culture Base', path: '/parameters/culture-base' },
  { label: 'Countries', path: '/parameters/countries' },
  { label: 'Administration of email signatures', path: '/parameters/signatures' },
];
