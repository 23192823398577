import PropTypes from 'prop-types';
import React, { useState } from 'react';
import v4 from 'uuid';

import { useTranslation } from 'react-i18next';
import icDelete from '../images/ic_delete.png';
import InputSelect from './InputSelect';
import Button from './Button';

const EntityRelations = ({ options, name, values, onAdd, onDelete }) => {
  const { t } = useTranslation('generics');
  const [selectedItem, selectItem] = useState(null);

  const handleOnSelect = id => {
    selectItem(options.find(item => item.id === parseInt(id, 10)) || null);
  };

  return (
    <div>
      {values.map(entity => (
        <div className='entity border-bottom p-1 mb-1' key={v4()}>
          {entity.name}
          <button className='delete btn btn-icon px-2 ml-2' onClick={() => onDelete(entity.id)}>
            <img src={icDelete} alt='delete icon' width='11' height='auto' />
          </button>
        </div>
      ))}
      <div className='form-inline'>
        <div className='form-group'>
          <InputSelect
            options={options}
            name={name}
            className='form-control-sm'
            onChange={id => handleOnSelect(id)}
            value={selectedItem ? selectedItem.value : ''}
          />
        </div>
        <div className='form-group'>
          <Button title={t('form.Add')} onClick={() => onAdd(selectedItem)} theme='light' isSmall />
        </div>
      </div>
    </div>
  );
};

EntityRelations.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  })).isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  name: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default EntityRelations;
