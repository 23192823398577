import React from 'react';
import PropTypes from 'prop-types';
import PlotFilters from './PlotFilters';
import PlotList from './PlotList';

function PlotSelector({ setEditedMode, setPolygonEditable }) {
  return (
    <div className='px-5'>
      <PlotFilters />
      <PlotList setEditedMode={setEditedMode} setPolygonEditable={setPolygonEditable} />
    </div>
  );
}

PlotSelector.displayName = 'Borealis-Plot-Selector';
PlotSelector.propTypes = {
  setEditedMode: PropTypes.func.isRequired,
  setPolygonEditable: PropTypes.func.isRequired,
};

export default PlotSelector;
