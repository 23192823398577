import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Sidebar from '../components/Sidebar';

import AdminMenu from '../components/AdminMenu';
import icParameters from '../images/ic_page_parameters.png';
import ParamPilotUsers from '../components/Admin/ParamPilotUsers';
import UserService from '../services/UserService';
import PilotService from '../services/PilotService';

const ParametersPilotUsers = ({ match }) => {
  const { t } = useTranslation('generics');
  const [pilot, setPilot] = useState(null);
  const [users, setUsers] = useState([]);
  const { params } = match;

  useEffect(() => {
    async function fetch() {
      await axios.all([
        UserService.fetchAll(),
        PilotService.fetchAll(),
      ]).then(axios.spread((...responses) => {
        const data = responses[1].data.pilots
          .find(item => item.id === parseInt(params.id, 10));
        setUsers(responses[0].data.users.map(user => ({
          ...user,
          label: `${user.firstname} ${user.lastname}`,
          value: user.id,
        })));
        if (data) setPilot(data);
      })).catch(errors => console.log('ParamPilots@fetchData', errors));
    }
    fetch();
  }, [params, setPilot, setUsers]);

  const handleOnAdd = data => PilotService.edit({
    ...data, user: data.user.id,
  })
    .then(() => setPilot({
      ...pilot,
      users: [...pilot.users, data.user],
    }))
    .catch(err => console.log('ParamPilots@edit', err.message));

  const handleOnDelete = data => PilotService.edit(data)
    .then(() => setPilot({
      ...pilot,
      users: pilot.users.filter(item => item.id !== data.user_to_remove),
    }))
    .catch(err => console.log('ParamPilots@edit', err.message));

  return (
    <div className='page'>
      <Sidebar title={t('pages.parameters.Parameters')} icon={icParameters}>
        <AdminMenu />
      </Sidebar>
      <div className='page-content'>
        <ParamPilotUsers
          userList={users}
          item={pilot}
          handleOnAdd={data => handleOnAdd(data)}
          handleOnDelete={data => handleOnDelete(data)}
        />
      </div>
    </div>
  );
};

export default ParametersPilotUsers;
