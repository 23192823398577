import { API } from '../api/request';

class CountryService {
  edit = data => {
    const formData = new FormData();
    Object.keys(data).forEach(item => {
      if (item === 'image') {
        formData.append(item, data[item], data[item].name);
      } else {
        formData.append(item, data[item]);
      }
    });

    return API.post(`front/signatures/edit/${data.id}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  fetchAll = () => API.get('front/signatures/');
}

const instance = new CountryService();

export default instance;
