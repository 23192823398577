import { set } from 'lodash/fp';
import React, { createContext, useState } from 'react';
import { API } from '../../../api/request';

const PlotContext = createContext()

function PlotProvider({children}) {

  const [plotsTest, setPlotsTest] = useState([]);
  const [selectedPlotTest, setSelectedPlotTest] = useState(null);
  const [campaigns, setCampaigns] = useState([])
  const [exploitations, setExploitations] = useState([])
  const [exploitationsInSelect, setExploitationsInSelect] = useState([])
  const [filter, setFilter] = useState({})
  const [selectedPlotDetail, setSelectedPlotDetail] = useState(null);
 

  const selectPlotTest = (plot, campaign) => {
    API.get(`front/plots/${plot.id}/campaign/${campaign || new Date().getFullYear()}/v2`,)
        .then(res => {
          setSelectedPlotTest(plot)
          setSelectedPlotDetail(res.data.data)
        })
        .catch(e => {
          console.error(e);
        });
  }

  const editFieldSelectedPlot = (field, value, plotId) => {
    const selectedData = set(field, value, selectedPlotDetail);
    plotsTest.map((plot, idx) => {
        if (plot.id === plotId.id) {
          setSelectedPlotDetail(selectedData)
        }
    })
  }

  const resetContextPlot = () => {
    setPlotsTest([])
  }

  return (
    <PlotContext.Provider value={{
        plotsTest, 
        setPlotsTest,
        selectedPlotTest,
        setSelectedPlotTest,
        exploitationsInSelect,
        setExploitationsInSelect,
        selectPlotTest,
        campaigns,
        setCampaigns,
        exploitations,
        setExploitations,
        filter,
        setFilter,
        selectedPlotDetail,
        setSelectedPlotDetail,
        editFieldSelectedPlot
    }}>
      {children}
    </PlotContext.Provider>
  )
  
}
export { PlotContext, PlotProvider }

