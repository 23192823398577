
import { connect } from 'react-redux';

import { deleteExploitation, setUser } from '../redux/actions';
import ExploitationService from '../services/ExploitationService';
import ProfileDetails from '../components/Profile/ProfileDetails';
import ProfileService from '../services/ProfileService';

const mapStateToProps = state => ({
  data: state.user,
});

const mapDispatchToProps = dispatch => ({
  onExploitationDelete: exploitationId => {
    ExploitationService.delete(exploitationId)
      .then(() => {
        ProfileService.getProfile()
          .then(profileData => {
            dispatch(setUser(profileData.data.User));
            dispatch(deleteExploitation(exploitationId));
          })
          .catch(profileErr => console.log(profileErr));
      })
      .catch(err => console.log({ err }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetails);
