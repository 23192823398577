import React from 'react';
import { useTranslation } from 'react-i18next';

import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import ReportActions from './ReportActions';
import MeasurePDF from '../MeasurePDF';

const Results = ({ data }) => {
  const ReportPDF = () => (<MeasurePDF data={data.data} />);
  const { t } = useTranslation('generics');

  return data && (
    <div className='px-5 h-100'>
      <PDFDownloadLink className='btn text-uppercase btn-outline-secondary m-2' document={<ReportPDF />} fileName='test.pdf'>
        {({ loading }) => (loading ? t('pages.reports.Loading document') : t('pages.reports.Export PDF'))}
      </PDFDownloadLink>
      <ReportActions />
      <PDFViewer className='col-12 h-100'>
        {ReportPDF()}
      </PDFViewer>
    </div>
  );
};

export default Results;
