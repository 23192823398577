import PropTypes from 'prop-types';
import React from 'react';

const ButtonDownload = ({ children, file, fileName }) => {
  const url = window.URL.createObjectURL(file);
  return (
    <a className='btn btn-primary' href={url} download={fileName}>
      {children}
    </a>
  );
};

ButtonDownload.propTypes = {
  file: PropTypes.instanceOf(Blob).isRequired,
  fileName: PropTypes.string.isRequired,
};

export default ButtonDownload;
