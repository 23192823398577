import { API } from '../api/request';

class SubscriptionService {
  fetchAll = () => API.get('front/subscriptions/all');

  replaceBy = data => API.patch('front/pilots/subscriptions/multiple-update', data, {
    headers: { 'Content-Type': 'application/merge-patch+json' },
  });
}

const instance = new SubscriptionService();

export default instance;
