import React from 'react';
import { useTranslation } from 'react-i18next';
import Sidebar from '../components/Sidebar';

import icParameters from '../images/ic_page_parameters.png';
import AdminMenu from '../components/AdminMenu';
import ParamOrganizations from '../containers/ParamOrganizations';

const ParametersGroups = () => {
  const { t } = useTranslation('generics');

  return (
    <div className='page'>
      <Sidebar title={t('pages.parameters.Parameters')} icon={icParameters}>
        <AdminMenu />
      </Sidebar>
      <div className='page-content pr-0'>
        <ParamOrganizations />
      </div>
    </div>
  );
};

export default ParametersGroups;
