import React from 'react';

import { Link } from 'react-router-dom';

import Switch from '../Switch';
import Button from '../Button';
import icDelete from '../../images/ic_delete.png';
import icEye from '../../images/ic_eye.png';

const UserItem = ({ data, deleteUser, changeActiveUser }) => (
  <tr>
    <td>{data.email}</td>
    <td>{data.login}</td>
    <td>{data.pilots?.join(', ')}</td>
    <td>
      <Switch
        checked={data.isActive}
        name={`user_${data.id}_active`}
        onChange={() => changeActiveUser(data.id, data.isActive)}
      />
    </td>
    <td>
      <Link
        title={data.lastname}
        to={`/parameters/users/${data.id}`}
      >
        <Button
          title={<img src={icEye} alt='detail icon' />}
          theme='icon'
        />
      </Link>
      <button
        className='delete btn btn-icon px-2'
        // eslint-disable-next-line no-alert
        onClick={() => window.confirm(`Supprimer ${data.lastname} ${data.firstname} ?`) && deleteUser(data.id)}
      >
        <img src={icDelete} alt='icon delete' width='11' height='auto' />
      </button>
    </td>
  </tr>
);

export default UserItem;
