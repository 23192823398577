import { API } from '../api/request';

class CampaignService {
  export = (campaign, type) => API.get(`front/campaigns/${campaign}/${type}`, {
    responseType: 'blob',
  });

  fetchAll = () => API.get('front/campaigns/');
}

const instance = new CampaignService();

export default instance;
