import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import Alert from '../Alert';
import AppService from '../../services/AppService';
import Button from '../Button';
import InputWithLabel from '../InputWithLabel';
import TextareaWithLabel from '../TextareaWithLabel';

const ContactForm = () => {
  const { t } = useTranslation('generics');
  const [data, setData] = useState({
    subject: '',
    company: '',
    lastname: '',
    firstname: '',
    email: '',
    details: '',
  });
  const [success, setSucces] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (field, value) => setData({ ...data, [field]: value });

  const isFormValid = () => {
    if (data.subject.trim() === '') {
      setError(t('fieldsError.field is required', { field: t('pages.contact.fields.Subject') }));
      return false;
    }

    if (data.company.trim() === '') {
      setError(t('fieldsError.field is required', { field: t('pages.contact.fields.Company') }));
      return false;
    }

    if (data.lastname.trim() === '') {
      setError(t('fieldsError.field is required', { field: t('pages.contact.fields.Lastname') }));
      return false;
    }

    if (data.firstname.trim() === '') {
      setError(t('fieldsError.field is required', { field: t('pages.contact.fields.Firstname') }));
      return false;
    }

    if (data.email.trim() === '') {
      setError(t('fieldsError.field is required', { field: t('pages.contact.fields.Email') }));
      return false;
    }

    const re = /\S+@\S+\.\S+/;
    if (re.test(data.email) === false) {
      setError(t('fieldsError.Email must have a correct format'));
      return false;
    }

    if (data.details.trim() === '') {
      setError(t('fieldsError.field is required', { field: t('pages.contact.fields.Details') }));
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    setSucces(false);
    setError(null);
    if (isFormValid()) {
      AppService.contact(data)
        .then(() => setSucces(true))
        .catch(err => setError(err.message));
    } else {
      setError(t('fieldsError.Fields are required'));
    }
  };

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12 mb-2'>
          {error && (<Alert message={error} />)}
          {success && (<Alert message={t('pages.contact.alerts.Message sent')} theme='success' />)}
          <InputWithLabel
            label={t('pages.contact.fields.Subject')}
            name='subject'
            onChange={val => handleChange('subject', val)}
            value={data.subject}
          />
        </div>
        <div className='col-md-6 mb-2'>
          <InputWithLabel
            label={t('pages.contact.fields.Firstname')}
            name='firstname'
            onChange={val => handleChange('firstname', val)}
            value={data.firstname}
          />
        </div>
        <div className='col-md-6 mb-2'>
          <InputWithLabel
            label={t('pages.contact.fields.Lastname')}
            name='lastname'
            onChange={val => handleChange('lastname', val)}
            value={data.lastname}
          />
        </div>
        <div className='col-md-6 mb-2'>
          <InputWithLabel
            label={t('pages.contact.fields.Email')}
            name='email'
            type='email'
            onChange={val => handleChange('email', val)}
            value={data.email}
          />
        </div>
        <div className='col-md-6 mb-2'>
          <InputWithLabel
            label={t('pages.contact.fields.Company')}
            name='company'
            onChange={val => handleChange('company', val)}
            value={data.company}
          />
        </div>
        <div className='col-12 mb-2'>
          <TextareaWithLabel
            label={t('pages.contact.fields.Details')}
            name='details'
            onChange={val => handleChange('details', val)}
            value={data.details}
          />
        </div>
        <div className='col-12 text-center'>
          <Button title={t('form.Send')} onClick={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
