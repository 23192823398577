import React from 'react';
import ReactLoading from 'react-loading';

const PlotMapLoading = () => (
  <ReactLoading type='bubbles' color='#8f8f8f' />
);

PlotMapLoading.displayName = 'Borealis-Plot-Map-Loading';

export default PlotMapLoading;
