import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Input = ({ name, onChange, type = 'text', placeholder = '', value = '', className = '', ...restProps }) => (
  <input
    className={clsx(className, 'form-control')}
    name={name}
    type={type}
    placeholder={placeholder}
    value={value}
    onChange={e => onChange(e.target.value)}
    {...restProps}
  />
);

Input.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.string,
};

export default Input;
