import React from 'react';
import { useTranslation } from 'react-i18next';
import LangFlag from './LangFlag';
import LangDropdown from './LangDropdown';

function LangSelector() {
  const { i18n } = useTranslation();

  return (
    <div>
      <LangFlag lang={i18n.language} />
      <LangDropdown lang={i18n.language} onLangChange={newLang => i18n.changeLanguage(newLang)} />
    </div>
  );
}

export default LangSelector;
