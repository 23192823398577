import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import ButtonDownload from '../ButtonDownload';
import ReportsService from '../../services/ReportsService';

function ReportActions() {
  const { t } = useTranslation('generics');
  const [csv, setCsv] = useState(null);

  useEffect(() => {
    ReportsService.on('CsvReceived', file => setCsv(file));
  }, []);

  return csv ? (
    <ButtonDownload file={csv} fileName='reports.csv'>{t('form.Export CSV')}</ButtonDownload>
  ) : null;
}

export default ReportActions;
