import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../Button';

function EditPlot({ setEditedMode, editedMode, handleSubmit }) {
  const { t } = useTranslation('generics');

  return (
    <span>
      <Button
        title={editedMode ? t('form.Save change') : t('form.Edit')}
        onClick={() => {
          if (editedMode) {
            handleSubmit();
            setEditedMode(false);
          } else {
            setEditedMode(true);
          }
        }}
        theme='primary'
      />
    </span>
  );
}

EditPlot.displayName = 'Borealis-Plot-Edit';
EditPlot.propTypes = {
  editedMode: PropTypes.bool.isRequired,
  setEditedMode: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default EditPlot;
