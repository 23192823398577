import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { useTranslation } from 'react-i18next';
import AddExploitationForm from '../containers/AddExploitationForm';
import Button from './Button';
import ExploitationService from '../services/ExploitationService';
import ModalEntity from './ModalEntity';

const AddExploitation = ({ theme = 'link' }) => {
  const { t } = useTranslation('generics');
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    ExploitationService.on('exploitationAdded', () => setVisible(false));
  }, []);

  return (
    <div className='mt-2'>
      {theme === 'button' ? (
        <Button title={t('form.Add')} onClick={() => setVisible(true)} theme='yellow' />
      ) : (
        <button className='btn btn-link text-secondary p-0' onClick={() => setVisible(true)}>
          <FontAwesomeIcon icon={faPlus} /> {t('form.Add a farm')}
        </button>
      )}
      <ModalEntity title1={t('form.Add')} title2={t('form.a farm')} visible={visible} onClose={() => setVisible(false)}>
        <AddExploitationForm />
      </ModalEntity>
    </div>
  );
};

AddExploitation.propTypes = {
  theme: PropTypes.string,
};

export default AddExploitation;
