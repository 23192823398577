import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ReactLoading from 'react-loading';
import { Route, useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import AppService from '../services/AppService';

const PrivateRoute = ({ hasData, appInit, component, ...options }) => {
  const cookies = useCookies();
  const history = useHistory();

  useEffect(() => appInit(), [appInit]);
  useEffect(() => AppService.on('autoLogout', () => localStorage.removeItem('jwt_token')), []);

  const route = () => {
    // eslint-disable-next-line no-unused-expressions
    cookies[0].cgu === undefined && history.push('/cgu');

    return (
      hasData ? <Route {...options} component={component} /> : <ReactLoading type='bubbles' color='#8f8f8f' />
    );
  };

  const finalComponent = localStorage.getItem('jwt_token') !== null ? route() : window.location = `${process.env.REACT_APP_AZURE_URL}authorize?p=b2c_1_n-pilot_siupin&client_id=${process.env.REACT_APP_CLIENT_ID}&scope=openid%20offline_access&response_type=code&redirect_uri=${process.env.REACT_APP_REDIRECT_URL}azure_connect`;

  return finalComponent;
};

PrivateRoute.propTypes = {
  hasData: PropTypes.number,
  appInit: PropTypes.func.isRequired,
};

export default PrivateRoute;
