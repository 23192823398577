/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

import { useTranslation } from 'react-i18next';
import Button from '../Button';
import InputCheckboxesWithLabel from '../InputCheckboxesWithLabel';
import { updateReportsFilter } from '../../domain/utils';
import InputSelectSearch from '../InputSelectSearch';
import InputSelectSearchMulti from '../InputSelectSearchMulti';
import InputCheckBoxSimple from '../InputCheckboxSimple';
import { API } from '../../api/request';

const ReportsFilters = ({ campaigns, exploitations, onSearch }) => {
  const { t } = useTranslation('generics');
  const [chosenCampaigns, setChosenCampaigns] = useState([]);
  const [selectedExploitations, setselectedExploitations] = useState([])
  const [exploitationSearch, setExploitationSearch] = useState("")
  const [parcelSearch, setParcelSearch] = useState("")
  const [selectedParcel, setSelectedParcel] = useState(new Map())
  const [allChecked, setAllChecked] = useState(new Map())
  const [exploitationToDisplay, setExploitationToDisplay] = useState([])
  const [parcelToDisplay, setParcelToDisplay] = useState(new Map())
  const [campaignToSearch, setcampaignToSearch] = useState([])
  const [saveApiRequestResut, setSaveApiRequestResut] = useState(new Map())
  const [isLoading, setIsLoading] = useState(false)


  const isAllChecked = (checked, exploitation) => {
    if(checked.length === parcelToDisplay.get(exploitation.value).length) {
      setAllChecked(map => new Map(map.set(exploitation.value, true)))
    } else {
      setAllChecked(map => new Map(map.set(exploitation.value, false)))
    }
  }

  const changeSelectedParcel = (exploitation) => {
    if(allChecked.get(exploitation.value)) {
      setAllChecked(map => new Map(map.set(exploitation.value, false)))
      setSelectedParcel(map => new Map(map.set(exploitation.value, [])))
    } else {
      setAllChecked(map => new Map(map.set(exploitation.value, true)))
      setSelectedParcel(map => new Map(map.set(exploitation.value, [...parcelToDisplay.get(exploitation.value)])))
    }
  }

  const filterExploitWithMeasure = (campaign, exploitation) => {
    setParcelToDisplay(new Map())
    setSelectedParcel(new Map())
    let camp = campaign != null ? [...chosenCampaigns, campaign.value] : chosenCampaigns
    let exploit = exploitation != null ? exploitation : selectedExploitations
    
    if(camp.length > 0 && exploit.length > 0) {
      setIsLoading(true)
    }
    exploit.forEach((e, idxExploit) => {
      let plots = []
      e.children.forEach((p, idxPlot) => {
        camp.forEach((c, idxCampaign) => {     
          if(!saveApiRequestResut.has(`${c}-${p.value}`)) {
            API.get(`front/reports/check-measure/${c}/${p.value}`)
            .then((response) => {
              saveApiRequestResut.set(`${c}-${p.value}`, +response.data.message > 0)
              plots = updateParcelToDisplay(p, e, plots, c)
              if(idxExploit == exploit.length - 1 && idxPlot == e.children.length - 1 && idxCampaign == camp.length - 1) {
                setIsLoading(false)
              }
            })
            .catch(e => {
                console.error(e);
            })
          } else {
            plots = updateParcelToDisplay(p, e, plots, c)
          }
        })
      }) 
    })
  }

  const RenderLoading = () => (
    <div style={{fill: "#8f8f8f",height:"64px",width: "64px", margin: "auto"}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <circle transform="translate(8 0)" cx="0" cy="16" r="0"> 
              <animate attributeName="r" values="0; 4; 0; 0" dur="1.2s" repeatCount="indefinite" begin="0"
              keytimes="0;0.2;0.7;1" keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8" calcMode="spline" />
            </circle>
            <circle transform="translate(16 0)" cx="0" cy="16" r="0"> 
              <animate attributeName="r" values="0; 4; 0; 0" dur="1.2s" repeatCount="indefinite" begin="0.3"
                keytimes="0;0.2;0.7;1" keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8" calcMode="spline" />
            </circle>
            <circle transform="translate(24 0)" cx="0" cy="16" r="0"> 
              <animate attributeName="r" values="0; 4; 0; 0" dur="1.2s" repeatCount="indefinite" begin="0.6"
                keytimes="0;0.2;0.7;1" keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8" calcMode="spline" />
            </circle>
          </svg>
        </div>
  )

  const updateParcelToDisplay = (plot, exploit, plots, campaign) => {
    if(saveApiRequestResut.get(`${campaign}-${plot.value}`)) {
      if(plots.indexOf(plot) < 0) {
        plots.push(plot) 
      }
      if(plots.length > 0) {
        setParcelToDisplay(map => new Map(map.set(exploit.value, plots)))
      }
    }
    return plots
  }

  const launchSearch = () => {
    let checkedParcel = []
    selectedParcel.forEach((k) => {
      checkedParcel = checkedParcel.concat(k.map((p) => p.value))
    })
    if(chosenCampaigns.length > 0 && checkedParcel.length  > 0) {
      onSearch({
        campaigns: chosenCampaigns,
        plots: checkedParcel,
      })
    } else {
      console.warn("You have to select at least one campaign and one parcel.")
    }
  }

  return (
    <div className='px-5'>
      <div className='py-2'>
        <InputCheckboxesWithLabel
          label={t('pages.reports.filters.labels.Campaigns')}
          name='campaigns'
          onChange={data => setChosenCampaigns(updateReportsFilter(chosenCampaigns, data)) & filterExploitWithMeasure(data,null)}
          options={campaigns}
          values={chosenCampaigns}
        />
      </div>
      <div className='py-2'>
        <label htmlFor="exploitations">{t('Exploitations')}</label>
        <InputSelectSearchMulti
          name={"exploitations"}
          options={exploitations}
          value={selectedExploitations}
          onChange={(val) => setselectedExploitations(val) & filterExploitWithMeasure(null,val)}
          isMulti={true}
          isSearchable={true}
          onInputChange={(value) => setExploitationSearch(value)}
          inputValue={exploitationSearch}></InputSelectSearchMulti>
          { isLoading ?
              <RenderLoading></RenderLoading>
            :
            <>
              {
                selectedExploitations?.map((e, idx) => (
                  <div key={idx}>
                    <label htmlFor="exploitations">{`${e.label} - ${t('pages.parameters.campaign.Plots')}`}</label>
                    <InputSelectSearchMulti
                      name={`${e.label}-parcels`}
                      options={parcelToDisplay.get(e.value)}
                      value={selectedParcel.get(e.value)}
                      onChange={(val) => setSelectedParcel(map => new Map(map.set(e.value, val))) & isAllChecked(val, e)}
                      isMulti={true}
                      isSearchable={true}
                      onInputChange={(value) => setParcelSearch(value)}
                      inputValue={parcelSearch}></InputSelectSearchMulti>
                      <label key={`${idx}-selectall`} htmlFor={`${e.value}-selectall`} className='checkbox-label'>
                        <InputCheckBoxSimple
                          id={`${e.value}-selectall`}
                          name={`${e.value}-selectall`}
                          checked={allChecked?.get(e.value)}
                          onChange={() => changeSelectedParcel(e)}
                        />
                        {t('pages.reports.Select All')}
                      </label>
                  </div>
                ))
              }
            </>
          }
      </div>
      <div className='py-2 text-center'>
        <Button
          title={t('form.Search')}
          onClick={() => launchSearch()}
        />
      </div>
    </div>
  );
};

ReportsFilters.propTypes = {
  campaigns: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  })).isRequired,
  // exploitations: PropTypes.arrayOf(PropTypes.shape({
  //   label: PropTypes.string.isRequired,
  //   value: PropTypes.number.isRequired,
  // })).isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default ReportsFilters;
