import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';

import OrganizationService from '../services/OrganizationService';

import Sidebar from '../components/Sidebar';
import AdminMenu from '../components/AdminMenu';
import icParameters from '../images/ic_page_parameters.png';
import Organization from '../components/Profile/Organization';

const ParametersDetailsGroup = ({ match }) => {
  const { t } = useTranslation('generics');
  const [loading, setLoading] = useState('true');
  const [data, setData] = useState(null);
  const { params } = match;

  useEffect(() => {
    OrganizationService.getById(params.idGroup)
      .then(resp => {
        setData(resp.data.group);
        setLoading(false);
      });
  }, []);

  return (
    <div className='page'>
      <Sidebar title={t('pages.parameters.Parameters')} icon={icParameters}>
        <AdminMenu />
      </Sidebar>
      <div className='page-content'>
        { loading ? (
          <ReactLoading type='bubbles' color='#8f8f8f' />
        ) : (
          <Organization data={data} roles={['ROLE_SUPER_ADMIN']} />
        )}
      </div>
    </div>
  );
};

export default ParametersDetailsGroup;
