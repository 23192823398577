import PropTypes from 'prop-types';
import React from 'react';
import v3 from 'uuid';
import { useTranslation } from 'react-i18next';

const Subscriptions = ({ list }) => {
  const { t } = useTranslation('generics');

  return (
    <div>
      <h2 className='text-uppercase has-offset text-info pt-5 mb-3'>{t('pages.profile.Subscriptions')}</h2>
      <ul className='list-unstyled text-info'>
        {list.map(subscription => (
          <li key={v3()}>
            {`${subscription.serialNumber}: ${subscription.univers} - ${subscription.year}`}
          </li>
        ))}
      </ul>
    </div>
  );
};

Subscriptions.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    serialNumber: PropTypes.string.isRequired,
    univers: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
  })).isRequired,
};

export default Subscriptions;
