
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ReactLoading from 'react-loading';

import { useTranslation } from 'react-i18next';
import OrganizationsTable from './OrganizationsTable';
import PageTitle from '../PageTitle';
import orgaImg from '../../images/orgaImg.png';

const ParamOrganizations = ({ onDelete, items = null, fetchData = () => {} }) => {
  const { t } = useTranslation('generics');
  useEffect(() => fetchData(), [fetchData]);

  return items ? (
    <div>
      <div className='mb-3'>
        <PageTitle title={t('pages.parameters.menu.Organizations')} />
      </div>
      <div className='row mx-0'>
        <div className='col-9'>
          <OrganizationsTable items={items} handleDelete={onDelete} />
        </div>
        <div className='col-3'>
          <img src={orgaImg} alt='organization right side' />
        </div>
      </div>
    </div>
  ) : (
    <div className='d-flex justify-content-center vh-100'>
      <ReactLoading type='bubbles' color='#8f8f8f' />
    </div>
  );
};

ParamOrganizations.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    usersNumber: PropTypes.number.isRequired,
    exploitationsNumber: PropTypes.number.isRequired,
  })),
  onDelete: PropTypes.func.isRequired,
  fetchData: PropTypes.func,
};

export default ParamOrganizations;
