/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import AppService from '../services/AppService';
import { setSession } from '../api/request';


const Azure = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const history = useHistory();

  useEffect(() => {
    const code = query.get('code');
    const error = query.get('error_description');
    if (code) {
      AppService.getAzureToken(code)
        .then(resp => {
          setSession(resp.data.id_token);
          AppService.login()
            .then(() => {
              history.push('/');
            })
            .catch(e => {
              if (e?.response?.status === 401) {
                history.push('login');
              }
            });
        })
        // eslint-disable-next-line no-return-assign
        .catch(() => window.location = `${process.env.REACT_APP_AZURE_URL}authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&scope=openid%20offline_access&response_type=code&redirect_uri=${process.env.REACT_APP_REDIRECT_URL}azure_connect&p=B2C_1_n-pilot_siupin`);
    } else if (error) {
      if (error.match(/The user has forgotten their password./)) {
        window.location = `${process.env.REACT_APP_AZURE_URL}authorize?response_type=id_token&scope=openid%20profile&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URL}azure_connect&p=b2c_1_n-pilot_sspr`;
      } else {
        window.location = `${process.env.REACT_APP_AZURE_URL}authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&scope=openid%20offline_access&response_type=code&redirect_uri=${process.env.REACT_APP_REDIRECT_URL}azure_connect&p=b2c_1_n-pilot_siupin`;
      }
    } else {
      window.location = `${process.env.REACT_APP_AZURE_URL}authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&scope=openid%20offline_access&response_type=code&redirect_uri=${process.env.REACT_APP_REDIRECT_URL}azure_connect&p=b2c_1_n-pilot_siupin`;
    }
  }, []);

  return (<p>wait please</p>);
};

export default Azure;
