/* eslint-disable no-console */
import { connect } from 'react-redux';
import PrivateRoute from '../components/PrivateRoute';

import {
  formatCampaignsFromAPI,
  formatCampaignToFlatArray,
  getFirstExploitationIdFromCampaign,
} from '../domain/utils';
import {
  setCampaigns,
  setError,
  setExploitations,
  setFilters,
  setUser,
} from '../redux/actions';
import { setPlots } from '../redux/plots/actions';
import ProfileService from '../services/ProfileService';
import AppService from '../services/AppService';
import PlotService from '../services/PlotService';
import { setSession } from '../api/request';

const mapStateToProps = state => ({
  hasData: state.user.id,
});

const mapDispatchToProps = dispatch => ({
  appInit: () => {
    setSession(localStorage.getItem('jwt_token'));
    AppService.init()
      .then(res => {
        const { campaign } = res.data;
        const campaigns = formatCampaignsFromAPI(campaign);
        const idExploitation = getFirstExploitationIdFromCampaign(campaigns, campaigns[0]?.year);
        const campaignsFilters = formatCampaignToFlatArray(campaigns);
        dispatch(setExploitations(campaigns));
        dispatch(setCampaigns(campaignsFilters));
        dispatch(setFilters({
          campaign: campaignsFilters[0],
          exploitation: parseInt(idExploitation, 10),
        }));
        if (idExploitation) {
          PlotService.getByExploitation(idExploitation)
            .then(plotsRes => dispatch(setPlots(plotsRes.data.data.plots)))
            .catch(plotErr => console.log(plotErr));
        }
        ProfileService.getProfile()
          .then(profileData => {
            dispatch(setUser(profileData.data.User));
          })
          .catch(profileErr => console.log(profileErr));
      })
      .catch(error => {
        if (error?.response?.data?.status !== 404) {
          dispatch(setError(error.message ? error.message : 'An error when getting app base datas'));
        }
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
