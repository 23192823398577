import thunkMiddleware from 'redux-thunk';
import { applyMiddleware, compose, createStore } from 'redux';
import logger from 'redux-logger';

import { rootReducer } from './reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancers = [thunkMiddleware];

if (process.env.NODE_ENV === 'development') enhancers.push(logger);

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(...enhancers),
  ),
);

export default store;
