import { connect } from 'react-redux';

import PilotService from '../services/PilotService';
import ParamPilots from '../components/Admin/ParamPilots';
import {
  deleteAdminPilot,
  setAdminPilots,

} from '../redux/actions';

const mapStateToProps = state => ({
  items: state.aPilots,
  subscriptionList: state.aSubscriptions.map(sub => ({
    ...sub,
    label: `${sub.universName} - ${sub.year}`,
    value: sub.id,
  })),
  userList: state.aUsers.map(user => ({
    ...user,
    label: `${user.firstname} ${user.lastname}`,
    value: user.id,
  })),
});

const mapDispatchtoProps = dispatch => ({
  fetchData: () => {
    setAdminPilots(null);
    PilotService.fetchAll()
      .then(res => dispatch(setAdminPilots(res.data.pilots)))
      .catch(err => console.log('ParamPilots@fetchData', err.message));
  },
  onDelete: id => PilotService.delete(id)
    .then(() => dispatch(deleteAdminPilot(id)))
    .catch(err => console.log('PilotService@delete', err.message)),
});

export default connect(mapStateToProps, mapDispatchtoProps)(ParamPilots);
