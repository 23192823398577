import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setPlots } from '../../../../redux/plots/actions';
import { setError } from '../../../../redux/actions';
import { formatCampaignsFromAPI, formatCampaignToFlatArray, formatExploitations, getFirstExploitationForEachCampaign, getFirstExploitationIdFromCampaign } from '../../../../domain/utils';
import PlotService from '../../../../services/PlotService';
import AppService from '../../../../services/AppService';
import InputSelectWithLabel from '../../../InputSelectWithLabel';
import InputSelectSearch from '../../../InputSelectSearch';
import { useHistory, useLocation } from 'react-router-dom';
import { PlotContext } from '../Plot.Context';

function PlotFilters() {
  const dispatch = useDispatch();
  const { t } = useTranslation('generics');
  const { campaigns, setCampaigns } = useContext(PlotContext)
  const { exploitations, setExploitations } = useContext(PlotContext)
  const { filter, setFilter} = useContext(PlotContext)
  const [campaignApi, setCampaignApi] = useState([])
  const { exploitationsInSelect, setExploitationsInSelect } = useContext(PlotContext)
  const [firstExploitations, setFirstExploitations] = useState([])
  const [valueSearch, setValueSearch] = useState("")
  const history = useHistory();
  const location = useLocation();

  const { setPlotsTest } = useContext(PlotContext)

  useEffect(() => {
    AppService.init().then((res) => {
      setCampaignApi(res.data.campaign);
      //Récupération des campagnes et exploitations
      const campaigns = formatCampaignsFromAPI(res.data.campaign);
      const campaignsFilters = formatCampaignToFlatArray(campaigns);
      let campaignsYears = campaignsFilters.map(item => ({ label: String(item), value: item }))

      setCampaigns(campaignsYears)
      setExploitations(campaigns)
      setExploitationsInSelect(formatExploitations(campaigns, campaignsFilters[0]))

      const idExploitation = getFirstExploitationIdFromCampaign(campaigns, campaigns[0]?.year)

      //Vérification de l'url pour selectionner par default les ressources
      let urlSplited = location.pathname.split("/").splice(1)
      let exploitIndex = urlSplited?.indexOf("exploitation")
      let campaignIndex = urlSplited?.indexOf("campaign")
      let isPlot = urlSplited?.indexOf("plot")
      if(exploitIndex !== -1 && campaignIndex !== -1 && isPlot !== -1) {
        onFiltersChange({
          campaign: parseInt(urlSplited[campaignIndex + 1], 10),
          exploitation: parseInt(urlSplited[exploitIndex + 1], 10),
        })
        // if no id, build the location path with the default value
      } else {
        if(campaignsYears[0]?.value && idExploitation && isPlot !== -1) {
	  console.log(filter)
          onFiltersChange({
            campaign: filter.campaign ? filter.campaign : campaignsYears[0]?.value,
            exploitation: filter.exploitation ? filter.exploitation : parseInt(idExploitation, 10),
          })
          history.push(`/plot/campaign/${filter.campaign ? filter.campaign : campaignsYears[0]?.value}/exploitation/${filter.exploitation ? filter.exploitation : parseInt(idExploitation, 10)}`)
        }
      }
      setFirstExploitations(getFirstExploitationForEachCampaign(campaigns))

    }).catch(error => {
      if (error?.response?.data?.status !== 404) {
        dispatch(setError(error.message ? error.message : 'An error when getting app base datas'));
      }
    });
  }, [])

  useEffect(() => {
    if(filter?.exploitation) {
      PlotService.getByExploitation(filter?.exploitation)
              .then(plotsRes => setPlotsTest(plotsRes.data.data.plots))
              .catch(plotErr => console.log(plotErr));
    }
  }, [campaignApi, filter, campaigns, exploitations])

  // METHODS
  // ----------------------------------------------------------------------------
  const onFiltersChange = filters => {
    setFilter(filters)
	console.log(filters)
    // Get filters per exploitation
    PlotService.getByExploitation(filters.exploitation)
      .then((response) => {
        setPlotsTest(response.data.data.plots)
      })
      .catch(err => console.log(err));
  };

  const updateLocation = (campaign, exploitation, from) => {
    if(from === "c") {
      exploitation = firstExploitations[campaign]
    }
    let urlSplited = location.pathname.split("/").splice(1)
    if(urlSplited?.indexOf("plot") !== -1) {
      history.push(`/plot/campaign/${campaign}/exploitation/${exploitation}`)
    }
  }

  const handleCampaignChange = (campaignString) => {
    // Get campaign ID
    const campaign = parseInt(campaignString, 10);
    setExploitationsInSelect(formatExploitations(exploitations, campaign))
    // Build filters data
	console.log(campaign)
	console.log(exploitationsInSelect)
    const filters = {
      campaign,
      exploitation: firstExploitations[campaign],
    };
	console.log(filters)
    // Dispatch filters
    onFiltersChange(filters);
  };

  // RENDER
  // ----------------------------------------------------------------------------
  return (
    <div>
      <InputSelectWithLabel
        label={t('Campaigns')}
        name='campaigns'
        options={campaigns}
        value={filter.campaign}
        onChange={val => handleCampaignChange(val) & updateLocation(val, filter.exploitation, "c")}
      />
      <label htmlFor="exploitations">{t('Exploitations')}</label>
      <InputSelectSearch
        name={"exploitations"}
        options={exploitationsInSelect}
        value={exploitationsInSelect.find((e) => e.value == filter.exploitation)}
        onChange={val => val != null && onFiltersChange({
          campaign: filter.campaign,
          exploitation: parseInt(val.value, 10),
        }) & updateLocation(filter.campaign, val.value, "e")}
        isSearchable={true}
        onInputChange={(value) => setValueSearch(value)}
        inputValue={valueSearch}
      />
    </div>
  );
}

PlotFilters.displayName = 'Borealis-Plot-Filters';

export default PlotFilters;
