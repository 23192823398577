import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReactLoading from 'react-loading';
import store from '../redux/store';
import Main from './Main';
import Menu from '../containers/MenuContainer';

const App = () => (
  <Suspense fallback={<ReactLoading type='bubbles' color='#8f8f8f' />}>
    <CookiesProvider>
      <Provider store={store}>
        <Router>
          <div className='d-block d-lg-flex'>
            <Menu />
            <Main />
            <ToastContainer 
              position="top-right"
              autoClose={5000}
              closeOnClick/>
          </div>
        </Router>
      </Provider>
    </CookiesProvider>
  </Suspense>
);

export default App;
