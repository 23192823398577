import { connect } from 'react-redux';

import { updateAdminUniversSubscription, addAdminUniversSubscription, deleteAdminUniversSubscription } from '../redux/actions';
import CultureService from '../services/CultureService';
import CultureStep1 from '../components/Admin/Culturebase/CultureStep1';
import { showErrorToast } from '../services/Toastr.service';

const mapStateToProps = state => ({
  items: state.aUnivers,
});

const mapDispatchToProps = dispatch => ({
  changeActivation: data => {
    CultureService.updateSubscription(data)
      .then(() => dispatch(updateAdminUniversSubscription(data)))
      .catch((err) => {
        console.error('CultureService@editSubscription', err.message);
        showErrorToast(err.message)
      })
  },
  addSubscription: data => {
    CultureService.addSubscription(data)
      .then(res => dispatch(addAdminUniversSubscription({
        univers: data.univers_id,
        subscription: res.data.subscription,
      })))
      .catch((err) => {
        console.error('CultureService@addSubscription', err.message);
        showErrorToast(err.message)
      })
  },
  deleteSubscription: data => {
    CultureService.deleteSubscription(data.yearId)
      .then(_ => {
        dispatch(deleteAdminUniversSubscription({
          univers: data.univers_id,
          subscription: data.yearId,
        }))
      })
      .catch(err => console.log('CultureService@deleteSubscription', err.message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CultureStep1);
