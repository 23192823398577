import PropTypes from 'prop-types';
import React, { useState } from 'react';

const InputCheckBoxSimple = ({ checked = false, id, name, onChange = () => {} }) => {

  return (
    <input
      checked={checked}
      id={id}
      name={name}
      type='checkbox'
      onChange={() => onChange()}
    />
  );
};

InputCheckBoxSimple.propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default InputCheckBoxSimple;
