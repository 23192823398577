import PropTypes from 'prop-types';
import React from 'react';

import icPlotColor from '../images/ic_plot_color.png';

const ModalHeader = ({ line1, line2 = '' }) => (
  <div className='modal-header-comp clearfix mb-3'>
    <div className='d-inline-block float-left text-info font-weight-bold text-uppercase'>
      <span className='line1 d-block'>{line1}</span>
      <span className='line2'>{line2}</span>
    </div>
    <img src={icPlotColor} alt='Plot icon' className='float-right' width='auto' height='41' />
  </div>
);

ModalHeader.propTypes = {
  line1: PropTypes.string.isRequired,
  line2: PropTypes.string,
};

export default ModalHeader;
