import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Sidebar from '../components/Sidebar';

import AdminMenu from '../components/AdminMenu';
import CampaignService from '../services/CampaignService';
import icParameters from '../images/ic_page_parameters.png';
import ParamCampaign from '../components/Admin/ParamCampaign';

import image from '../images/bg-admin-campaign.jpg';

const ParametersCampaign = () => {
  const { t } = useTranslation('generics');
  const [items, setItem] = useState(null);

  useEffect(() => {
    setItem(null);
    async function fetch() {
      CampaignService.fetchAll()
        .then(res => setItem(res.data.campaigns))
        .catch(err => console.log('CampaignService@fetchData', err.message));
    }
    fetch();
  }, [setItem]);

  return (
    <div className='page'>
      <Sidebar title={t('pages.parameters.Parameters')} icon={icParameters}>
        <AdminMenu />
      </Sidebar>
      <div className='page-content has-background' style={{ backgroundImage: `url(${image})` }}>
        <ParamCampaign items={items} />
      </div>
    </div>
  );
};

export default ParametersCampaign;
