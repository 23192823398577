/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import React from 'react';

import Switch from '../../Switch';

const Signature = ({ clicked, data, onActivate, active = false }) => (
  <div className={`container-fluid pt-3 signature ${active && ('signActive')}`}>
    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
    <div className='row' onClick={() => clicked(data)}>
      <div className='col-8 text-info text-uppercase'>
        {data.country.name}
      </div>
      <div className='col-4'>
        <Switch
          checked={data.isActive}
          name={`signature_${data.id}_active`}
          onChange={() => onActivate({
            id: data.id,
            country: data.country.id,
            isActive: !data.isActive,
          })}
        />
      </div>
    </div>
  </div>
);

Signature.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    country: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    }).isRequired,
    isActive: PropTypes.bool.isRequired,
    link: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }),
  onActivate: PropTypes.func.isRequired,
};

export default Signature;
