import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import Alert from '../Alert';
import Button from '../Button';
import CountryService from '../../services/CountryService';
import Input from '../Input';

const AddCountry = ({ onSuccess = () => {} }) => {
  const { t } = useTranslation('generics');
  const initialData = { name: '', code: '', position: '[0,0]' };
  const [data, setData] = useState(initialData);
  const [error, setError] = useState(null);

  const handleOnChange = (field, value) => setData({ ...data, [field]: value });

  const handleSubmit = () => {
    if (data.name.trim() === '' || data.code.trim() === '') {
      setError('Fields are required');
    } else {
      CountryService.add(data)
        .then(() => {
          setData(initialData);
          onSuccess(); // API dont send created id, so we reload all data
        })
        .catch(err => setError(err.message));
    }
  };

  return (
    <div>
      <div className='mb-3 text-uppercase text-info'>{t('pages.parameters.countries.New country')}</div>
      {error && (<Alert message={error} />)}
      <div className='mb-3'>
        <Input
          value={data.name}
          name='name'
          placeholder={t('pages.parameters.countries.Name')}
          onChange={val => handleOnChange('name', val)}
        />
      </div>
      <div className='mb-3'>
        <Input
          value={data.code}
          name='code'
          placeholder={t('pages.parameters.countries.Code')}
          onChange={val => handleOnChange('code', val)}
        />
      </div>
      <Button title={t('form.Validate')} onClick={() => handleSubmit()} />
    </div>
  );
};

export default AddCountry;
