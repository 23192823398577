import React from 'react';

import { Text, Image, View, Font, StyleSheet } from '@react-pdf/renderer';
import nPilotLogo from '../images/n-pilotPdfLogo.png';

import Roboto from '../sass/Roboto-Regular.ttf';
import RobotoBold from '../sass/Roboto-Bold.ttf';

const HeaderPDF = ({ headline }) => {
  Font.register({
    family: 'Roboto',
    fonts: [
      { src: Roboto },
      { src: RobotoBold, fontStyle: 'bold', fontWeight: '700' },
    ],
  });

  const styles = StyleSheet.create({
    header: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      color: 'grey',
    },
    headline: {
      fontFamily: 'Roboto',
      fontStyle: 'bold',
      color: '#71BF44',
      fontSize: 25,
    },
    logo: {
      width: 200,
    },
  });

  return (
    <View style={styles.header} fixed>
      <Text style={styles.headline}>{headline}</Text>
      <Image
        style={styles.logo}
        src={nPilotLogo}
      />
    </View>
  );
};

export default HeaderPDF;
