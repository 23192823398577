import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import Alert from '../../Alert';
import Button from '../../Button';
import Input from '../../Input';
import InputSelect from '../../InputSelect';

const AddCropEntity = ({
  name,
  onAdd,
  options,
  placeholder,
  title,
}) => {
  const { t } = useTranslation('generics');
  const [error, setError] = useState(null);
  const [value, setValue] = useState('');
  const [country, setCountry] = useState(options.length ? options[0].value : '');

  const handleSubmit = () => {
    if (value.trim().length > 0) {
      onAdd({
        country,
        name: value.trim(),
      });
    } else {
      setError(t('fieldsError.Field is required'));
    }
  };

  return (
    <div className='border p-3'>
      <div className='text-secondary font-weight-bold mb-2 text-uppercase'>{title}</div>
      {error && (<Alert message={error} />)}
      <div className='form-inline'>
        <div className='form-group'>
          <Input
            name={name}
            placeholder={placeholder}
            onChange={val => setValue(val)}
            value={value}
          />
        </div>
        <div className='form-group'>
          <InputSelect
            name='country'
            onChange={id => setCountry(parseInt(id, 10))}
            options={options}
            value={country}
          />
        </div>
        <div className='form-group'>
          <Button title={t('form.Add')} onClick={() => handleSubmit()} />
        </div>
      </div>
    </div>
  );
};

AddCropEntity.propTypes = {
  name: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  })).isRequired,
  placeholder: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default AddCropEntity;
