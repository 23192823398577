/* eslint-disable no-console */
import React, { useContext, useEffect } from 'react';
import v4 from 'uuid';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Alert from '../../../Alert';
import PlotService from '../../../../services/PlotService';
import PlotItem from './PlotItem';
import { useHistory, useLocation  } from "react-router-dom";
import { PlotContext } from '../Plot.Context';

function PlotList({ setEditedMode, setPolygonEditable }) {
  const { t } = useTranslation('generics');
  const { plotsTest, setPlotsTest } = useContext(PlotContext)
  const { selectPlotTest } = useContext(PlotContext)
  const { setSelectedPlotDetail } = useContext(PlotContext)
  const { filter } = useContext(PlotContext)
  const history = useHistory();
  const location = useLocation();

  // METHODS
  // ----------------------------------------------------------------------------
  const onPlotDelete = plotId => {
    setEditedMode(false);
    setPolygonEditable(false);
    PlotService.delete(plotId)
      .then(() => {
        setPlotsTest(plotsTest.filter((p) => p.id != plotId))
      })
      .catch(err => console.log('onPlotDelete', err));
  };

  useEffect(() => {
    //If no parcelId is define in url, we put the /parcel/:id. If there is an id, we change it
    if(plotsTest.length) {
      let urlSplited = location.pathname.split("/").splice(1)
      let indexParcel = urlSplited.indexOf("parcel")
      let plot = plotsTest.find((p) => p.id === +urlSplited[indexParcel + 1])
      if(indexParcel !== -1 && plot) {
        setEditedMode(false);
        setPolygonEditable(false);
        selectPlotTest(plot, filter.campaign)
      } else {
        setSelectedPlotDetail(null)
      }
    }
  }, [plotsTest])

  const onPlotSelect = plot => {
    setEditedMode(false);
    setPolygonEditable(false);
    selectPlotTest(plot, filter.campaign)

    //If no parcelId is define in url, we put the /parcel/:id. If there is an id, we change it
    let urlSplited = location.pathname.split("/").splice(1)
    let indexParcel = urlSplited.indexOf("parcel")
    urlSplited.splice(urlSplited.indexOf("measure"), 2)
    if(indexParcel !== -1) {
      urlSplited[indexParcel + 1] = plot.id
      history.push(`/${urlSplited.join("/")}`)
    } else {
      history.push(`${location.pathname}/parcel/${plot.id}`)
    }
  };

  // RENDER
  // ----------------------------------------------------------------------------
  return (
    <div className='plot-list'>
      {plotsTest.length === 0 ? (
        <Alert message={t('pages.plot.alerts.No result for theses filters')} theme='info' />
      ) : ''}
      {plotsTest.map(plot => (
        <PlotItem
          data={plot}
          key={v4()}
          onClick={() => onPlotSelect(plot)}
          onDelete={() => onPlotDelete(plot.id)}
        />
      ))}
    </div>
  );
}

PlotList.displayName = 'Borealis-Plot-List';
PlotList.propTypes = {
  setEditedMode: PropTypes.func.isRequired,
  setPolygonEditable: PropTypes.func.isRequired,
};

export default PlotList;
