/**
 * Varrieties options for plot
 */
export const getVarietiesOptions = (t, cropName) => {
  const strongWheat = t('pages.plot.modal.options.crops.Strong wheat').toUpperCase();
  const durumWheat = t('pages.plot.modal.options.crops.Durum wheat').toUpperCase();
  const softWheat = t('pages.plot.modal.options.crops.Soft wheat').toUpperCase();
  const qualitySoftWheat = t('pages.plot.modal.options.crops.Quality soft wheat').toUpperCase();
  const rape = t('pages.plot.modal.options.crops.Rape').toUpperCase();
  const barleyWinter = t('pages.plot.modal.options.crops.Barley winter').toUpperCase();
  const springBarley = t('pages.plot.modal.options.crops.Spring barley').toUpperCase();
  const triticale = t('pages.plot.modal.options.crops.Triticale').toUpperCase();

  switch (cropName) {
    case strongWheat:
      return [
        'ADESSO',
        'ANTONIUS',
        'BOLOGNA',
        'BUSSARD',
        'CH CLARO',
        'CH COMBIN',
        'CH NARA',
        'COURTOT',
        'DOLLY',
        'ELEMENT',
        'ENERGO',
        'ESPERIA',
        'FIGARO',
        'FORCALI',
        'FOREL',
        'GALIBIER',
        'GEO',
        'GHAYTA',
        'IZALCO CS',
        'LOGIA',
        'LUKULLUS',
        'METROPOLIS',
        'MONOPOL',
        'MV BERES',
        'MV KOLO',
        'MV MAZURKA',
        'MV TOLDI',
        'PIRENEO',
        'QUALITY',
        'REBELDE',
        'RENAN',
        'RUNAL',
        'SEGOR',
        'SIALA',
        'TIEPOLO',
        'TITLIS',
        'TOGANO',
        'UBICUS',
        'VERZASCA',
        'WAXIMUM',
      ];
    case durumWheat:
      return [
        'ALEXIS',
        'ANVERGUR',
        'ATOUDUR',
        'BABYLONE',
        'BIENSUR',
        'BYZANCE',
        'CASTELDOUX',
        'CLAUDIO',
        'CLOVIS',
        'COUSSUR',
        'CULTUR',
        'DAKTER',
        'DAURUR',
        'DUROFINUS',
        'FABULIS',
        'FLORIDOU',
        'GIBUS',
        'HERAKLION',
        'ISILDUR',
        'JOYAU',
        'KARUR',
        'LG BORIS',
        'LIBERDUR',
        'LUMINUR',
        'MIRADOUX',
        'NOBILIS',
        'ORLU',
        'PASTADOU',
        'PESCADOU',
        'PICTUR',
        'PLUSUR',
        'QUALIDOU',
        'RELIEF',
        'RGT FABIONUR',
        'RGT FIERTIMUR',
        'RGT MONBECUR',
        'RGT MUSCLUR',
        'RGT VOILUR',
        'SCULPTUR',
        'SURMESUR',
        'SY BANCO',
        'SY CYSCO',
        'TABLUR',
        'TOSCADOU',
      ];
    case softWheat:
      return [
        'ABSALON',
        'ACCROC',
        'ADAGIO',
        'ADDICT',
        'ADHOC',
        'ADVISOR',
        'AIGLE',
        'ALBIANO',
        'ALHAMBRA',
        'ALIXAN',
        'ALLEZY',
        'ALTAMIRA',
        'ALTIGO',
        'AMADOR',
        'AMBELLO',
        'AMBITION',
        'AMBOISE',
        'AMIFOR',
        'ANDROMEDE CS',
        'ANGELUS',
        'APACHE',
        'APANAGE',
        'APLOMB',
        'APRILIO',
        'ARAMIS',
        'AREZZO',
        'ARKEOS',
        'ARLEQUIN',
        'ARMADA',
        'ARTDECO',
        'AS DE COEUR',
        'ASCOTT',
        'ATOUPIC',
        'AUBUSSON',
        'AUCKLAND',
        'AYMERIC',
        'AZZERTI',
        'BAGOU',
        'BAROK',
        'BELEPI',
        'BERGAMO',
        'BERMUDE',
        'BIANCOR',
        'BIENFAIT',
        'BOISSEAU',
        'BONIFACIO',
        'BOREGAR',
        'BRENTANO',
        'CALABRO',
        'CALCIO',
        'CALISOL',
        'CALUMET',
        'CAMP REMY',
        'CAMPERO',
        'CAMPESINO',
        'CAPHORN',
        'CCB INGENIO',
        'CECYBON',
        'CELLULE',
        'CHEVALIER',
        'CHEVIGNON',
        'CHEVRON',
        'COLLECTOR',
        'COMPIL',
        'COMPLICE',
        'CONCRET',
        'CONEXION',
        'COURTOT',
        'CREEK',
        'CROISADE',
        'CROUSTY',
        'CUBITUS',
        'DESCARTES',
        'DIAMENTO',
        'DIDEROT',
        'DINOSOR',
        'EPHOROS',
        'ETANA',
        'ETHIC',
        'EUCLIDE',
        'EXELCIOR',
        'EXPERT',
        'FAIRPLAY',
        'FALADO',
        'FANION',
        'FANTOMAS',
        'FILON',
        'FLUOR',
        'FRUCTIDOR',
        'GALACTIC',
        'GALOPAIN',
        'GENY',
        'GIMMICK',
        'GLASGOW',
        'GONCOURT',
        'GOTIK',
        'GRAINDOR',
        'GRANAMAX',
        'GRAPELI',
        'GWASTELL',
        'HYBELLO',
        'HYBERY',
        'HYBIZA',
        'HYCLICK',
        'HYCROP',
        'HYDROCK',
        'HYFI',
        'HYGUARDO',
        'HYKING',
        'HYNERGY',
        'HYNVICTUS',
        'HYSTAR',
        'HYSUN',
        'HYTEK',
        'HYWIN',
        'HYXO',
        'HYXPERIA',
        'HYXPRESS',
        'HYXTRA',
        'ILLICO',
        'INTERET',
        'INTRO',
        'IONESCO',
        'ISENGRAIN',
        'ISTABRAQ',
        'JB ASANO',
        'JB DIEGO',
        'JOKER',
        'KARILLON',
        'KORELI',
        'KUNDERA',
        'KWS DAKOTANA',
        'KWS EXTASE',
        'KWS TONNERRE',
        'LAURIER',
        'LAVOISIER',
        'LEAR',
        'LEEDS',
        'LG AMSTRONG',
        'LG ASCONA',
        'LG AURIGA',
        'LIPARI',
        'LITHIUM',
        'LUDWIG',
        'LUMINON',
        'LYRIK',
        'MACARON',
        'MANAGER',
        'MANDRAGOR',
        'MAORI',
        'MATHEO',
        'MERCATO',
        'MODERN',
        'MONITOR',
        'MONTEREY',
        'MUSIK',
        'MUTIC',
        'NEMO',
        'NOBLESKO',
        'NOGAL',
        'NORWAY',
        'NUMERIC',
        'OBIWAN',
        'ODYSSEE',
        'OLBIA',
        'OREGRAIN',
        'ORLOGE',
        'ORTOLAN',
        'OVALO',
        'OXEBO',
        'PAKITO',
        'PALEDOR',
        'PANNONIKUS',
        'PAPAGENO',
        'PASTORAL',
        'PIBRAC',
        'PILIER',
        'POPEYE',
        'PORTHUS',
        'PREMIO',
        'PREVERT',
        'PROVIDENCE',
        'PUEBLO',
        'RAINER',
        'RECIPROC',
        'RGT AMPIEZZO',
        'RGT CESARIO',
        'RGT CONEKTO',
        'RGT CYCLO',
        'RGT DISTINGO',
        'RGT FRENEZIO',
        'RGT KILIMANJARO',
        'RGT KRYPTO',
        'RGT LEXIO',
        'RGT LIBRAVO',
        'RGT MONDIO',
        'RGT PULKO',
        'RGT SACRAMENTO',
        'RGT TEKNO',
        'RGT TEXACO',
        'RGT VELASKO',
        'RGT VENEZIO',
        'RGT VOLUPTO',
        'ROCHFORT',
        'RONSARD',
        'RUBISCO',
        'RUSTIC',
        'SAINT EX',
        'SALVADOR',
        'SANKARA',
        'SANREMO',
        'SCENARIO',
        'SCOR',
        'SELEKT',
        'SEPIA',
        'SHERLOCK',
        'SIRTAKI',
        'SOBRED',
        'SOISSONS',
        'SOKAL',
        'SOLEHIO',
        'SOLIFLOR',
        'SOLINDO CS',
        'SOLIVE',
        'SOLLARIO',
        'SOLOGNAC',
        'SOLVEIG',
        'SOMCA',
        'SONYX',
        'SOPHIE CS',
        'SORBET CS',
        'SOROKK',
        'SOTHYS CS',
        'SPONSOR',
        'STADIUM',
        'STARWAY',
        'STEREO',
        'SU ASTRAGON',
        'SULTAN',
        'SWEET',
        'SY ADORATION',
        'SY MATTIS',
        'SY MOISSON',
        'SY PASSION',
        'SY TOLBIAC',
        'SYLLON',
        'SYSTEM',
        'TENOR',
        'TENTATION',
        'TERROIR',
        'THALYS',
        'TOBAK',
        'TORP',
        'TRAPEZ',
        'TRIOMPH',
        'TULIP',
        'UBICUS',
        'UNIK',
        'VALDO',
        'VYCKOR',
        'ZEPHYR',
      ];
    case qualitySoftWheat:
      return [
        'ADVISOR',
        'AIGLE',
        'ALIXAN',
        'ALLEZY',
        'APACHE',
        'APRILIO',
        'AREZZO',
        'ATTRAKTION',
        'AUCKLAND',
        'BERMUDE',
        'BIENFAIT',
        'BOREGAR',
        'BRENTANO',
        'CALABRO',
        'CALUMET',
        'CELLULE',
        'CENTURION',
        'CHEVIGNON',
        'COMPIL',
        'COMPLICE',
        'DESCARTES',
        'DIAMENTO',
        'ETANA',
        'EUCLIDE',
        'FILON',
        'FOXYL',
        'FRUCTIDOR',
        'GHAYTA',
        'GONCOURT',
        'GRAINDOR',
        'GRANAMAX',
        'HYBELLO',
        'HYBERY',
        'HYBIZA',
        'HYDROCK',
        'HYFI',
        'HYKING',
        'HYPODROM',
        'HYSPEED',
        'HYSUN',
        'HYWIN',
        'HYXO',
        'HYXTRA',
        'ILLICO',
        'INTERET',
        'IONESCO',
        'LAURIER',
        'LG ABSALON',
        'LG AMSTRONG',
        'LG ASCONA',
        'LIPARI',
        'LYRIK',
        'MAORI',
        'MATHEO',
        'MUTIC',
        'NEMO',
        'OREGRAIN',
        'ORLOGE',
        'PASTORAL',
        'PIBRAC',
        'RGT CESARIO',
        'RGT FORZANO',
        'RGT KILIMANJARO',
        'RGT LIBRAVO',
        'RGT MONTECARLO',
        'RGT SACRAMENTO',
        'RGT TEKNO',
        'RGT VELASKO',
        'RGT VENEZIO',
        'RUBISKO',
        'SANREMO',
        'SCENARIO',
        'SEPIA',
        'SOISSONS',
        'SOLEHIO',
        'SY MATTIS',
        'SY MOISSON',
        'SYLLON',
        'TERROIR',
        'TRIOMPH',
        'UBICUS',
      ];
    case rape:
      return [
        'ACAPULCO',
        'ADEMO KWS',
        'ALESSANDRO KWS',
        'ALICANTE',
        'AMAZZONITE',
        'ANNAPOLIS',
        'ARCHITECT',
        'ASTRONOM',
        'AUTRE HYBRIDE',
        'AUTRE LIGNEE',
        'BARAQUE',
        'BLUESTAR',
        'BONANZA',
        'CADRAN',
        'COVERMIX',
        'CRISTIANO KWS',
        'DALTON',
        'DK EXCEPTION',
        'DK EXCLAMATION',
        'DK EXENTE',
        'DK EXENTIEL',
        'DK EXLIBRIS',
        'DK EXPACITO',
        'DK EXPANSION',
        'DK EXPEDIENT',
        'DK EXPERTISE',
        'DK EXPLORATION',
        'DK EXSTORM',
        'DK EXTENSO',
        'DK EXTRACT',
        'DK EXTREMUS',
        'DK IMPERIAL CL',
        'DOZZEN',
        'DUALIS',
        'ES CAPELLO',
        'ES IMPERIO',
        'ES MAMBO',
        'ES NAVIGO',
        'FELICIANO KWS',
        'FENCER',
        'FERNANDO KWS',
        'GAELIS',
        'HALYN',
        'HOURRA',
        'INV 1010',
        'KUNZITE',
        'LBS 3023 C',
        'LG ABSOLUT',
        'LG ACROPOLE',
        'LG ALASCO',
        'LG AMPLITUDE',
        'LG ASPECT',
        'MANZZANA',
        'MEDLEY',
        'MEMORI CS',
        'PAMELA',
        'POPULAR',
        'PT 246',
        'PT274',
        'PT275',
        'PT284',
        'RAFFINESS',
        'RAPITA03',
        'RGT QUIZZ',
        'SEVERINO KWS',
        'STEFANO KWS',
        'SY MATTEO',
        'TANZANITE',
        'TREZZOR',
        'TURQUOISE',
      ];
    case barleyWinter:
      return [
        'ABONDANCE',
        'AMANDINE',
        'AMISTAR',
        'ARTURIO',
        'AUGUSTA',
        'AZUREL',
        'BAGOO',
        'BONNIE',
        'CALYPSO',
        'CAMPAGNE',
        'CAMPANILE',
        'CANTARE',
        'CARAVAN',
        'CASANOVA',
        'CASINO',
        'CATHERINE',
        'CERVOISE',
        'CHAMPIE',
        'CHOUETTA',
        'COCCINEL',
        'CONCORDIA',
        'DANIELA',
        'DAXOR',
        'DOLMEN',
        'EMOTION',
        'ESCADRE',
        'ESTEREL',
        'FARO',
        'GIGGA',
        'GOODY',
        'HENRIETTE',
        'HICKORY',
        'HIMALAYA',
        'HIRONDELLA',
        'HOBBIT',
        'HOOK',
        'INFINITY',
        'ISOCEL',
        'JENNY',
        'JETTOO',
        'JOKER',
        'KARIOKA',
        'KETOS',
        'KWS AKKORD',
        'KWS BORRELY',
        'KWS CASSIA',
        'KWS DOMINO',
        'KWS ESTAMINET',
        'KWS FARO',
        'KWS FLEMMING',
        'KWS GLACIER',
        'KWS JAGUAR',
        'KWS MERIDIAN',
        'KWS ORBIT',
        'KWS OXYGENE',
        'KWS SALSA',
        'KWS TONIC',
        'LG CASTING',
        'LG ZAPPA',
        'LIMPID',
        'MAGISTRAL',
        'MALICORNE',
        'MALTESSE',
        'MANGOO',
        'MARGAUX',
        'MAZURKA',
        'MEMENTO',
        'METAXA',
        'MOON',
        'NEWTON',
        'ORBISE',
        'ORDINALE',
        'ORIGAMI',
        'ORJOIE',
        'ORPAILLE',
        'ORWELL',
        'PASSEREL',
        'PIXEL',
        'PLATINE',
        'PLURIEL',
        'POMPADOUR',
        'QUAD',
        'QUADRIGA',
        'QUEEN',
        'RAFAELA',
        'RAMATA',
        'RGT PLANET',
        'ROSSIGNOLA',
        'SALAMANDRE',
        'SANDRA',
        'SCANDAL',
        'SEDUCTION',
        'SILEX',
        'SMOOTH',
        'SOBELL',
        'SY BAMBOO',
        'SY BOOGY',
        'SY MOOCE',
        'SY POOL',
        'SY TEPEE',
        'TATOO',
        'TEKTOO',
        'TIGOO',
        'TOOTY',
        'TOUAREG',
        'VANESSA',
        'VARIETE BRASSICOLE',
        'VARIETE FOURRAGERE',
        'VOLUME',
        'VOYEL',
        'ZOO',
      ];
    case springBarley:
      return [
        'BEATRIX',
        'BELLINI',
        'CONCERTO',
        'EXPLORER',
        'FOCUS',
        'GESINE',
        'GRACE',
        'KWS AURELIA',
        'KWS IRINA',
        'LEANDRA',
        'LIBERTA',
        'MARYLIN',
        'NFC TIPPLE',
        'ODYSSEY',
        'OVERTURE',
        'PIONIER',
        'PRESTIGE',
        'PRUNELLA',
        'REVANCHE',
        'RGT ORBITER',
        'RGT PLANET',
        'SANETTE',
        'SANGRIA',
        'SEBASTIAN',
        'SOULMATE',
        'STYLE',
        'SUNSHINE',
        'SY CRISTALLIN',
        'TRAVALER',
        'ZEPPELIN',
      ];
    case triticale:
      return [
        'AGOSTINO',
        'AGRILAC',
        'ANAGRAM',
        'BELLAC',
        'BIENVENU',
        'BIKINI',
        'BIVOUAC',
        'BREHAT',
        'COLLEGIAL',
        'CONSTANT',
        'DUBLET',
        'ELEAC',
        'ELICSIR',
        'EPIAC',
        'GRANDVAL',
        'KASYNO',
        'KAULOS',
        'KEREON',
        'KORTEGO',
        'KWS FIDO',
        'MATINAL',
        'MAXIMAL',
        'OMEAC',
        'ORVAL',
        'QUATREVENTS',
        'RAGTAC',
        'RAMDAM',
        'RENOVAC',
        'RIVOLT',
        'ROTEGO',
        'RUMINAC',
        'SECONZAC',
        'SULIAC',
        'SW TALENTRO',
        'TARZAN',
        'TREMPLIN',
        'TRIBECA',
        'TRIMMER',
        'TRISKELL',
        'TRISMART',
        'TULUS',
        'VIVIER',
        'VOLKO',
        'VUKA',
      ];
    default:
      return [];
  }
};

/**
 * Stone options
 * @type {({label: string, value: string})[]}
 */
export const STONE_OPTIONS = [
  { value: '--', label: '--' },
  { value: '0 %', label: '0 %' },
  { value: '0 - 5 %', label: '0 - 5 %' },
  { value: '5 - 10 %', label: '5 - 10 %' },
  { value: '10 - 20 %', label: '10 - 20 %' },
  { value: '+20 %', label: '+20 %' },
];
