
import { connect } from 'react-redux';

import CultureService from '../services/CultureService';
import { setAdminUnivers } from '../redux/actions';
import CultureMenu from '../components/Admin/Culturebase/CultureMenu';

const mapStateToProps = state => ({
  items: state.aUnivers,
});

const mapDispatchtoProps = dispatch => ({
  fetchData: () => {
    CultureService.fetchUnivers()
      .then(res => dispatch(setAdminUnivers(res.data.univers.univers)))
      .catch(err => console.log('CultureService@fetchUnivers', err.message));
  },
});

export default connect(mapStateToProps, mapDispatchtoProps)(CultureMenu);
