import PropTypes from 'prop-types';
import React from 'react';
import v3 from 'uuid';

import Signature from './Signature';

const Signatures = ({ onSelect, onChangeActivation, items, active }) => (
  <div>
    {items.map(item => (
      <Signature
        data={item}
        key={v3()}
        active={item.id === active}
        onActivate={data => onChangeActivation(data)}
        clicked={sign => onSelect(sign)}
      />
    ))}
  </div>
);

Signatures.propTypes = {
  onChangeActivation: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    country: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    }).isRequired,
    isActive: PropTypes.bool.isRequired,
    link: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  })).isRequired,
};

export default Signatures;
