import PropTypes from 'prop-types';
import React from 'react';

const DataWithLabel = ({ label, data = '', unit = '' }) => (
  <div className='py-3 text-info'>
    {label} <b className='ml-3'>{data}{unit}</b>
  </div>
);

DataWithLabel.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.string.isRequired,
  unit: PropTypes.string,
};

export default DataWithLabel;
