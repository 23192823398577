import React from 'react';

import { useTranslation } from 'react-i18next';
import ContactForm from '../components/Forms/ContactForm';
import PageTitle from '../components/PageTitle';

const Contact = () => {
  const { t } = useTranslation('generics');

  return (
    <div className='page'>
      <div className='page-content'>
        <PageTitle title={t('pages.contact.Contact us')} />
        <ContactForm />
      </div>
    </div>
  );
};

export default Contact;
