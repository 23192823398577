import PropTypes, { func } from 'prop-types';
import React, { useState } from 'react';

import CultureService from '../../../services/CultureService';

import icEyeBlue from '../../../images/ic_eye_blue.png';
import icDelete from '../../../images/ic_delete.png';
import icEdit from '../../../images/ic_edit.png';
import ic_check from '../../../images/ic_check.png'
import Input from '../../Input';
import ModalEntity from '../../ModalEntity';
import Button from '../../Button';

const CropRow = ({ data, action = [] }) => {
  const [inEdit, setInEdit] = useState(false)
  const [value, setValue] = useState(data.name)
  const [visible, setVisible] = useState(false)

  const updateCrop = () => {
    data.name = value
    action.find((a) => a.name === "edit_crop").func(data)
  }

    return (
      <tr>
        <td>
          {
            inEdit ?
              <Input name={`crop name`}
              type="text"
              value={value}
              onChange={(val) => setValue(val)}></Input>
            :
              data.name
          }
        </td>
        <td>
          {
            !inEdit ?
              <>
                <button
                  className='btn btn-icon'
                  onClick={() => CultureService.emit('selectCrop', data.id)}
                >
                  <img src={icEyeBlue} alt='Icon eye' width='25' height='auto' />
                </button>
                <button className='btn btn-icon' style={{width: 25}} onClick={() => setInEdit(true)}>
                  <img src={icEdit} alt='icon edit' width='15' height='auto' />
                </button>
                <button className='btn btn-icon' style={{width: 25}} onClick={() => setVisible(true)}>
                  <img src={icDelete} alt='icon delete' width='15' height='auto' />
                </button>
                
                <ModalEntity title1={"Suppression"} visible={visible} onClose={() => setVisible(false)}>
                  <span style={{fontSize: 15, textAlign: 'center'}}>Etes vous sur de vouloir supprimer la récolte {data.name} ?</span>
                  <div style={{display: 'flex', justifyContent: 'center', marginTop: 25}}>
                    <Button 
                    title="Confirmer"
                    onClick={() => action.find((a) => a.name === "delete_crop").func(data.id) & setVisible(false)}></Button>
                    <Button 
                    title="Annuler"
                    className='button-delete'
                    onClick={() => setVisible(false)}></Button>
                  </div>
                </ModalEntity>
              </>
            :
              <>
                <button className='btn btn-icon' style={{width: 25}}>
                  <img src={ic_check} alt='icon validate' width='15' height='auto' onClick={() => updateCrop()}/>
                </button>
                <button className='btn btn-icon' style={{width: 25}} onClick={() => setInEdit(false)}>
                  <img src={icDelete} alt='icon discard' width='15' height='auto' />
                </button>
              </>
          }
        </td>
      </tr>
    );
  }

CropRow.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  action: PropTypes.arrayOf(PropTypes.object)
};

export default CropRow;
