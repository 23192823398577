import { toast } from 'react-toastify';

export const showInfoToast = (message, progressBar = false) => {
    toast.info(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: !progressBar,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: true
        })
}

export const showErrorToast = (message) => {
    toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: true
        })
}

export const showWarnToast = (message) => {
    toast.warn(message, {
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: true
        })
}

export const showSuccessToast = (message) => {
    toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: true
        })
}
