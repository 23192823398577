/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import React from 'react';

import InputCheckox from './InputCheckbox';

const InputCheckboxesWithLabel = ({ onChange, options, label, name, values }) => (
  <div>
    <div className='label mb-2 mt-3'>{label}</div>
    {options.map(opt => (
      <label key={opt.value} htmlFor={`${name}-${opt.value}`} className='checkbox-label'>
        <InputCheckox
          id={`${name}-${opt.value}`}
          name={name}
          checked={values.indexOf(opt.value) !== -1}
          onChange={newBool => onChange({
            value: opt.value,
            checked: newBool,
          })}
        />
        {opt.label}
      </label>
    ))}
  </div>
);

InputCheckboxesWithLabel.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  })).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  values: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
};

export default InputCheckboxesWithLabel;
