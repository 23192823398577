import PropTypes from 'prop-types';
import React from 'react';

import ExploitationService from '../../services/ExploitationService';

import icDelete from '../../images/ic_delete.png';
import icEdit from '../../images/ic_edit.png';

const FarmItem = ({ data, adminMode }) => (
  <tr>
    <td>{data.company}</td>
    <td>{data.firstname}</td>
    <td>{data.lastname}</td>
    <td>{data.postcode}</td>
    <td>{data.city}</td>
    <td>{data.email}</td>
    <td>{data.campain}</td>
    <td>
      {!adminMode && (
        <>
          <button
            className='edit btn btn-icon px-2'
            onClick={() => ExploitationService.emit('editExploitation', data)}
          >
            <img src={icEdit} alt='icon edit' width='18' height='auto' />
          </button>
          <button
            className='delete btn btn-icon px-2'
            // NEED FIX
            // eslint-disable-next-line no-alert
            onClick={() => window.confirm(`Supprimer ${data.company} ?`) && ExploitationService.emit('deleteExploitation', data.id)}
          >
            <img src={icDelete} alt='icon delete' width='11' height='auto' />
          </button>
        </>
      )}
    </td>
  </tr>
);

FarmItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    company: PropTypes.string.isRequired,
    address: PropTypes.string,
    address2: PropTypes.string,
    postcode: PropTypes.string.isRequired,
    city: PropTypes.string,
    email: PropTypes.string,
    campain: PropTypes.number.isRequired,
  }).isRequired,
};

export default FarmItem;
