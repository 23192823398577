import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Sidebar from '../components/Sidebar';

import icParameters from '../images/ic_page_parameters.png';
import AdminMenu from '../components/AdminMenu';
import ParamPilotsEditSubs from '../components/Admin/ParamPilotsEditSubs';
import SubscriptionService from '../services/SubscriptionService';

const ParametersPilotsEditSub = () => {
  const { t } = useTranslation('generics');
  const [items, setItems] = useState(null);

  useEffect(() => {
    async function fetch() {
      SubscriptionService.fetchAll()
        .then(res => setItems(res.data.subscriptions.map(sub => ({
          label: `${sub.universName} - ${sub.year}`,
          value: sub.id,
        }))))
        .catch(err => console.log('SubscriptionService@fetchAll', err.message));
    }
    fetch();
  }, [setItems]);

  return (
    <div className='page'>
      <Sidebar title={t('pages.parameters.Parameters')} icon={icParameters}>
        <AdminMenu />
      </Sidebar>
      <div className='page-content'>
        <ParamPilotsEditSubs items={items} />
      </div>
    </div>
  );
};


export default ParametersPilotsEditSub;
