import PropTypes from 'prop-types';
import React from 'react';
import v3 from 'uuid';

import { useTranslation } from 'react-i18next';
import PilotRow from './PilotRow';

const PilotsTable = ({ items, handleDelete, userList, subscriptionList }) => {
  const { t } = useTranslation('generics');

  return (
    <table className='table table-striped'>
      <thead className='thead-dark'>
        <tr>
          <th>{t('pages.parameters.pilots.Id')}</th>
          <th>{t('pages.parameters.pilots.Serial number')}</th>
          <th>{t('pages.parameters.pilots.Users')}</th>
          <th>{t('pages.parameters.pilots.Subscriptions')}</th>
          <th>{t('pages.parameters.pilots.Actions')}</th>
        </tr>
      </thead>
      <tbody>
        {items.map(row => (
          <PilotRow
            data={row}
            key={v3()}
            onDelete={id => handleDelete(id)}
            userList={userList}
            subscriptionList={subscriptionList}
          />
        ))}
      </tbody>
    </table>
  );
};

PilotsTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    serialNumber: PropTypes.string.isRequired,
    subscriptions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      year: PropTypes.number.isRequired,
      isActive: PropTypes.bool.isRequired,
      universId: PropTypes.number.isRequired,
      universName: PropTypes.string.isRequired,
      universCode: PropTypes.string.isRequired,
    })).isRequired,
    users: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      lastname: PropTypes.string.isRequired,
      firstname: PropTypes.string.isRequired,
    })).isRequired,
  })).isRequired,
  userList: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  })).isRequired,
  subscriptionList: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  })).isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default PilotsTable;
