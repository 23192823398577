import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';

// INITIALIZE I18N
// ----------------------------------------------------------------------------
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
	  defaultNS: 'generics',
	  lang: 'fr',
	  fallbackLng: 'en',
	  interpolation: {
		  escapeValue: false,
		  format(value, format, lng) {
		  	  if (format === 'lowercase') return value.toLowerCase();
			  if (format === 'uppercase') return value.toUpperCase();
			  if (value instanceof Date) return moment(value).format(format);
			  return value;
		  },
	  },
  })
  .then(() => console.log('I18N initialized'));

export default i18n;
