import PropTypes from 'prop-types';
import React from 'react';

import { useTranslation } from 'react-i18next';
import EntityRelationsDisplay from '../EntityRelationsDisplay';
import icDelete from '../../images/ic_delete.png';

const PilotRow = ({ data, onDelete }) => {
  const { t } = useTranslation('generics');

  return (
    <tr className='text-info'>
      <td>{data.id}</td>
      <td>{data.serialNumber}</td>
      <td>
        <EntityRelationsDisplay
          values={data.users
            .map(user => ({
              id: user.id,
              name: `${user.firstname} ${user.lastname}`,
            }))}
          link={`/parameters/pilots/${data.id}/users`}
          buttonTitle={t('pages.parameters.pilots.Edit users')}
        />
      </td>
      <td>
        <EntityRelationsDisplay
          values={data.subscriptions
            .map(sub => ({
              id: sub.id,
              name: `${sub.universName} - ${sub.year}`,
            }))}
          link={`/parameters/pilots/${data.id}/subscriptions`}
          buttonTitle={t('pages.parameters.pilots.Edit subscriptions')}
        />
      </td>
      <td className='text-right'>
        <button className='delete btn btn-icon px-2' onClick={() => onDelete(data.id)}>
          <img src={icDelete} alt='icon delete' width='11' height='auto' />
        </button>
      </td>
    </tr>
  );
};

PilotRow.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    serialNumber: PropTypes.string.isRequired,
    subscriptions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      year: PropTypes.number.isRequired,
      isActive: PropTypes.bool.isRequired,
      universId: PropTypes.number.isRequired,
      universName: PropTypes.string.isRequired,
      universCode: PropTypes.string.isRequired,
    })).isRequired,
    users: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      lastname: PropTypes.string.isRequired,
      firstname: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default PilotRow;
