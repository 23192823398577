import PropTypes from 'prop-types';
import React from 'react';
import ReactLoading from 'react-loading';

import { useTranslation } from 'react-i18next';
import CampaignExport from './CampaignExport';

const ParamCampaign = ({ items = null }) => {
  const { t } = useTranslation('generics');
  if (items) {
    return (
      <div>
        <div className='p-5 has-bg-rgba rounded export-bloc'>
          <div className='h3 font-weight-bold text-uppercase text-white mb-3'>
            {t('pages.parameters.menu.Campaign')}
          </div>
          <CampaignExport campaigns={items} />
        </div>
      </div>
    );
  }
  return (
    <div className='d-flex justify-content-center vh-100'>
      <ReactLoading type='bubbles' color='#8f8f8f' />
    </div>
  );
};

ParamCampaign.propTypes = {
  items: PropTypes.arrayOf(PropTypes.number),
};

export default ParamCampaign;
