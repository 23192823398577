import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button from '../components/Button';
import PageTitle from '../components/PageTitle';
import InputCheckbox from '../components/InputCheckbox';

import PrivacyFR from '../images/Privacy_FR.pdf';
import PrivacyDE from '../images/Privacy_DE.pdf';
import PrivacyENG from '../images/Privacy_ENG.pdf';

import ConditionFR from '../images/Condition_FR.pdf';
import ConditionDE from '../images/Condition_DE.pdf';
import ConditionENG from '../images/Condition_ENG.pdf';

const CGU = () => { 
  const history = useHistory();
  const { t, i18n } = useTranslation('generics'); 
  const [cookies, setCookies, removeCookie] = useCookies();
  const [error, setError] = useState(null);
  const [privacyFile, setPrivacyFile] = useState(PrivacyENG)
  const [conditionFile, setConditionFile] = useState(ConditionENG)
  const [conditionState, setConditionState] = useState(false)
  const [courrielState, setCourrielState] = useState(false)

  useEffect(() => {
    console.log('language', i18n.language)
    switch(i18n.language) {
      case 'fr' :
        setConditionFile(ConditionFR);
        setPrivacyFile(PrivacyFR);
        break;
      case 'de' : 
        setConditionFile(ConditionDE);
        setPrivacyFile(PrivacyDE);
        break;
      default :
        setConditionFile(ConditionENG);
        setPrivacyFile(PrivacyENG);
    }
  }, [i18n.language]);

  return (
    <div className='page'>
      <div className='page-content'>
        <PageTitle title={t('pages.cgu.generals Terms and conditions')} />
        <p className='text-info'>{t('pages.cgu.i understand that')}</p>
        <p className='text-info'>{t('pages.cgu.for the use of N Pilot') + `,`} <a href={conditionFile} download='Conditions.pdf'>{t('pages.cgu.our general terms and conditions apply')}</a>{` (` + t('pages.cgu.please click to open the document') + `).`}</p>
        <p className='text-info'>{t('pages.cgu.you can access to the N Pilot privacy notice') }<a href={privacyFile} download='Privacy.pdf'>{ ' ' + t('pages.cgu.here') }</a>{` (` + t('pages.cgu.please click to open the document') + `).`}</p>
        <div>
          <InputCheckbox
            name={`cgu`}
            checked={conditionState}
            onChange={val => setConditionState(val)}
            />
          <span className='text-info'>{t('pages.cgu.i have read the general conditions of use and accept these including the data protection information')}</span>
        </div>
        <div>
          <InputCheckbox
            name={`courriel`}
            checked={courrielState}
            onChange={val => setCourrielState(val)}
            />
          <span className='text-info'>{t('pages.cgu.i wish to receive the Newsletter of Borealis LAT via email to the email address provided by myself')}</span>
        </div>
        {error && <p className='text-danger'>{error}</p>}
        <Button
          title={t('pages.cgu.i agree continue')}
          onClick={() => {
            if(conditionState) {
              setCookies('cgu', true);
              history.push('/');
            } else {
              setError(t('check error'));
            }
          }}
        />
        {/* <Button 
          title='debugg'
          onClick={() => {
            removeCookie('cgu');
          }}
        /> */}
      </div>
    </div>
  )
};

export default CGU;
