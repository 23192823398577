import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import PlotSelector from '../components/pages/Plot/Selector/PlotSelector';
import PlotDetails from '../components/pages/Plot/Details/PlotDetails';
import AddPlot from '../components/pages/Plot/Add/AddPlot';
import AddTelepac from '../components/pages/Plot/Add/AddTelepac';
import icPlot from '../images/ic_page_plot.png';
import Sidebar from '../components/Sidebar';
import { PlotContext, PlotProvider } from '../components/pages/Plot/Plot.Context';

function Plot() {
  const { t } = useTranslation('generics');
  const { selectedPlotDetail } = useContext(PlotContext)
  const userCountry = useSelector(state => state.user.country);
  const [editedMode, setEditedMode] = useState(false);
  const [polygoneEditable, setPolygonEditable] = useState(false);

  return (
    <div className='page'>
      <Sidebar className={clsx(!selectedPlotDetail?.id && 'min-vh-100 overflow-auto')} title={t('Plot')} icon={icPlot}>
        <PlotSelector setEditedMode={setEditedMode} setPolygonEditable={setPolygonEditable} />
        <div className='text-center mt-2 p-3 px-5'>
          {(userCountry.id === 1 || userCountry.id === 4 || userCountry.id === 6) && (
            <AddTelepac idCountry={userCountry.id} />
          )}
          <AddPlot theme='primary' />
        </div>
      </Sidebar>
      <div className={clsx('page-content p-0', { details: selectedPlotDetail?.id })}>
        <PlotDetails
          editedMode={editedMode}
          setEditedMode={setEditedMode}
          polygoneEditable={polygoneEditable}
          setPolygonEditable={setPolygonEditable}
        />
      </div>
    </div>
  );
}

Plot.displayName = 'Borealis-Plot';

export default Plot;
