import PropTypes from 'prop-types';
import React from 'react';

const Alert = ({ message, theme = 'danger' }) => (
  <div className={`alert alert-${theme}`} role='alert'>{ message }</div>
);

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  theme: PropTypes.string,
};

export default Alert;
