import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import icDelete from '../../../../images/ic_delete.png';
import icWheat from '../../../../images/ic_crop_wheat.png';
import { PlotContext } from '../Plot.Context';

function PlotItem({ data, onClick, onDelete }) {
  const { selectedPlotTest } = useContext(PlotContext)

  return (
    <div
      className={clsx('plot-item py-2', {
        active: data.id === selectedPlotTest?.id,
      })}
      onClick={() => onClick(data.id)}
      role='button'
      tabIndex={0}
      onKeyPress={() => onClick(data.id)}
    >
      <div className='icon'>
        <img src={icWheat} alt='icon' width='auto' height='30' />
      </div>
      <div className='w-100'>
        {/* eslint-disable-next-line no-restricted-globals */}
        <div className='title text-info'><b>{data.name}</b> | {data.surface && !isNaN(data.surface) ? parseFloat(data.surface).toFixed(2) : 0} ha</div>
        <div className='crop-label text-grey'>{`${data?.pilotage[0]?.cropName} - ${data?.pilotage[0]?.varietyName}`}</div>
      </div>
      <div className='icon'>
        <button className='delete btn btn-icon px-2' onClick={onDelete}>
          <img src={icDelete} alt='icon delete' width='11' height='auto' />
        </button>
      </div>
    </div>
  );
}

PlotItem.displayName = 'Borealis-Plot-Item';
PlotItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    surface: PropTypes.oneOfType([
      PropTypes.number.isRequired,
      PropTypes.string.isRequired,
    ]),
    ilot: PropTypes.string,
    texture: PropTypes.string,
    stone: PropTypes.string,
    groundDepth: PropTypes.string,
    organicMaterial: PropTypes.string,
    polygon: PropTypes.string,
    pilotage: PropTypes.arrayOf(PropTypes.shape({
      overallDose: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      performanceObjective: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      cropName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      varietyName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })),
  }),
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default PlotItem;
