import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import Alert from '../Alert';
import CampaignService from '../../services/CampaignService';
import ButtonDownload from '../ButtonDownload';
import InputSelectWithLabel from '../InputSelectWithLabel';

const CampaignExport = ({ campaigns }) => {
  const { t } = useTranslation('generics');
  const [selectedCampaign, selectCampaign] = useState(campaigns[0]);
  const types = [{ value: 'plots', label: t('pages.parameters.campaign.Plots') }, { value: 'measures', label: t('pages.parameters.campaign.Measures') }];
  const [type, setType] = useState('plots');
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetch() {
      CampaignService.export(campaigns[0], 'plots')
        .then(res => setFile(new Blob([res.data])))
        .catch(err => setError(err.message));
    }
    fetch();
  }, [setError, setFile, campaigns]);

  const handleOnChange = (field, value) => {
    setError(null);
    setFile(null);
    let data = { campaign: selectedCampaign, type };

    if (field === 'type') {
      data = { ...data, type: value };
      setType(value);
    } else {
      data = { ...data, campaign: value };
      selectCampaign(value);
    }

    CampaignService.export(data.campaign, data.type)
      .then(res => setFile(new Blob([res.data])))
      .catch(err => {
        console.log('CampaignService@export', { err }); // To check for CORS issues
        setError(err.message);
      });
  };

  return (
    <div className='has-input-white'>
      <InputSelectWithLabel
        label={t('pages.parameters.menu.Campaign')}
        name='campaign'
        options={campaigns.map(item => ({ value: item, label: String(item) }))}
        value={selectedCampaign}
        onChange={val => handleOnChange('campaign', parseInt(val, 10))}
      />
      <InputSelectWithLabel
        label={t('pages.parameters.campaign.Plot/Measure')}
        name='type'
        options={types}
        value={type}
        onChange={val => handleOnChange('type', val)}
      />
      <div className='mt-3 mb-2 text-white'>
        {file
          ? (
            <ButtonDownload file={file} fileName={`export-${selectedCampaign}-${type}.csv`}>
              {t('form.Export')}
            </ButtonDownload>
          )
          : (<p>{t('pages.parameters.campaign.Processing')}</p>)}
        { error && !file && (<Alert message={error} />)}
      </div>
    </div>
  );
};

CampaignExport.propTypes = {
  campaigns: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default CampaignExport;
