import EventEmitter from '../domain/EventEmitter';

import { API } from '../api/request';

class ProfileService extends EventEmitter {
  getProfile = () => API.get('front/users/');

  edit = data => API.patch(`front/users/edit/${data.id}`, data, {
    headers: { 'Content-Type': 'application/merge-patch+json' },
  });
}

const instance = new ProfileService();

export default instance;
