/* eslint-disable no-plusplus */
/* eslint-disable no-lonely-if */
import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import v3 from 'uuid';
import { Document, Page, Text, View, Image, Font } from '@react-pdf/renderer';
import { formatCoords } from '../domain/utils';

import Roboto from '../sass/Roboto-Regular.ttf';
import RobotoBold from '../sass/Roboto-Bold.ttf';

import HeaderPDF from './HeaderPDF';
import FooterPDF from './FooterPDF';
import puceLogo from '../images/puceLogo.png';
import oneStars from '../images/1stars.png';
import twoStars from '../images/2stars.png';
import threeStars from '../images/3stars.png';
import forthStars from '../images/4stars.png';
import fiveStars from '../images/5stars.png';

import styles from './stylePDF';

const MeasurePDF = ({ data }) => {
  const { t } = useTranslation('generics');
  // const t = d => (d);

  Font.register({
    family: 'Roboto',
    fonts: [
      { src: Roboto },
      { src: RobotoBold, fontStyle: 'bold', fontWeight: '700' },
    ],
  });

  const averageRef = val => {
    let index = 0;
    let total = 0;
    val.measures.forEach(measure => {
      if (measure.percentReference) {
        index++;
        total += measure.percentReference;
      }
    });
    return (Math.round(total / index));
  };

  const markers = val => {
    let formatedData = '';
    val.filter(item => !item.areaReference).forEach((mes, index) => {
      if (mes.arrivalCoordinates) {
        if (mes.arrivalCoordinates[0] !== 0 && mes.arrivalCoordinates[1] !== 0) {
          formatedData += `&markers=color:orange|label:${index + 1}|${mes.arrivalCoordinates.reverse().join(',')}`;
        }
      }
    });
    return (formatedData.substring(0, formatedData.length - 1));
  };

  const generatePATH = path => {
    if (path !== '' && path) {
      let formatedData = '&path=fillcolor:0xFFFF0033|color:0xFFFF00|weight:3|';
      formatedData += formatCoords(JSON.parse(path));
      return formatedData;
    }
    return '';
  };

  const qualityIndice = (value, univers) => {
    let score = null;
    if (univers === 2) {
      if (value > 40) {
        score = <Image src={oneStars} style={{ width: 100 }} />;
      } else if (value > 35) {
        score = <Image src={twoStars} style={{ width: 100 }} />;
      } else if (value > 30) {
        score = <Image src={threeStars} style={{ width: 100 }} />;
      } else if (value > 25) {
        score = <Image src={forthStars} style={{ width: 100 }} />;
      } else if (value >= 0) {
        score = <Image src={fiveStars} style={{ width: 100 }} />;
      }
    } else {
      if (value > 0.8) {
        score = <Image src={oneStars} style={{ width: 100 }} />;
      } else if (value > 0.6) {
        score = <Image src={twoStars} style={{ width: 100 }} />;
      } else if (value > 0.4) {
        score = <Image src={threeStars} style={{ width: 100 }} />;
      } else if (value > 0.2) {
        score = <Image src={forthStars} style={{ width: 100 }} />;
      } else if (value >= 0) {
        score = <Image src={fiveStars} style={{ width: 100 }} />;
      }
    }
    return score;
  };

  return (
    <Document>
      {data?.organization?.map(orga => (
        orga?.exploitation?.map(exploit => (
          exploit?.plot?.map(p => (
            p?.pilotage?.map(pilot => (
              pilot?.measureGroup?.map(measureG => (
                <Page style={styles.body}>
                  <HeaderPDF headline={t('pages.plot.pdf.headline')} />
                  <View style={{ flexDirection: 'row', maxHeight: 100 }}>
                    <View style={[styles.borders, { flex: 1, marginRight: 10 }]}>
                      <View style={{ flexDirection: 'row' }}>
                        <Image src={puceLogo} style={{ width: 13, height: 13, marginRight: 4 }} />
                        <Text style={styles.title}>{t('pages.parameters.menu.Organizations')}</Text>
                      </View>
                      <Text style={{ fontStyle: 'bold' }}>
                        {orga.name}
                      </Text>
                      <Text>{orga.address}</Text>
                      <Text>
                        {`${orga.postcode} ${orga.city}`}
                      </Text>
                    </View>
                    <View style={[styles.borders, orga?.logo !== '' && { marginLeft: 10 }, { flex: 1 }]}>
                      <View style={{ flexDirection: 'row' }}>
                        <Image src={puceLogo} style={{ width: 13, height: 13, marginRight: 4 }} />
                        <Text style={styles.title}>{t('pages.plot.pdf.Exploitation')}</Text>
                      </View>
                      <Text style={{ fontStyle: 'bold' }}>
                        {exploit.company}
                      </Text>
                      <Text>{exploit.address}</Text>
                      <Text>
                        {`${exploit.postcode} ${exploit.city}`}
                      </Text>
                    </View>
                  </View>
                  <View style={{ flex: 1, marginTop: 10, maxHeight: 150 }}>
                    <Image
                      alt='google'
                      src={`https://maps.googleapis.com/maps/api/staticmap?&maptype=satellite&size=600x200${markers(measureG.measures)}${generatePATH(p.polygon)}&key=${process.env.REACT_APP_GOOGLE_MAPS_API}`}
                    />
                  </View>
                  <Text style={{ opacity: 0, display: 'none' }}>{JSON.stringify(markers(measureG.measures))}</Text>
                  {/* ^^^^^^^^^^^^^^^^^^ TODO FIX THIS ^^^^^^^^^^^^^^^^^^ */}
                  <View style={[styles.borders, { marginTop: 20 }]}>
                    <View style={{ flexDirection: 'row' }}>
                      <Image src={puceLogo} style={{ width: 13, height: 13, marginRight: 4 }} />
                      <Text style={styles.title}>{t('pages.plot.pdf.Informations')}</Text>
                    </View>
                    <View style={[styles.informationRow, { fontStyle: 'bold' }]}>
                      <Text style={{ flex: 1 }}>{t('pages.plot.pdf.Plot')} :</Text>
                      <Text style={{ flex: 1 }}>{p.name}</Text>
                      <Text style={{ flex: 1 }}>{t('pages.plot.pdf.Date')} :</Text>
                      <Text style={{ flex: 1 }}>
                        {moment(measureG.date.date).format('DD-MM-YYYY HH:mm')}
                      </Text>
                    </View>
                    <View style={[styles.informationRow, { fontStyle: 'bold' }]}>
                      <Text style={{ flex: 1 }}>{t('pages.plot.pdf.Operator')} :</Text>
                      <Text style={{ flex: 1 }}>{measureG.operator}</Text>
                      <Text style={{ flex: 1 }}>N Pilot :</Text>
                      <Text style={{ flex: 1 }}>{measureG.gpnNumber}</Text>
                    </View>
                    <View style={styles.informationRow}>
                      <Text style={{ flex: 1 }}>{t('pages.plot.pdf.Crop')} :</Text>
                      <Text style={{ flex: 1 }}>{pilot.cropName}</Text>
                      <Text style={{ flex: 1 }}>{t('pages.plot.pdf.Variety')} :</Text>
                      <Text style={{ flex: 1 }}>{pilot.varietyName}</Text>
                    </View>
                    <View style={styles.informationRow}>
                      <Text style={{ flex: 1 }}>{t('pages.plot.pdf.Stade')} :</Text>
                      <Text style={{ flex: 1 }}>{measureG.stageName}</Text>
                      <Text style={{ flex: 1 }}>{t('pages.reports.pdf.overallDose')} :</Text>
                      <Text style={{ flex: 1 }}>{measureG.cetiomAdvice === '' ? 'N/A' : measureG.cetiomAdvice}</Text>
                    </View>
                  </View>
                  <View style={[styles.borders, { marginTop: 20 }]}>
                    <View style={{ flexDirection: 'row' }}>
                      <Image src={puceLogo} style={{ width: 13, height: 13, marginRight: 4 }} />
                      <Text style={styles.title}>{t('pages.plot.pdf.Result')}</Text>
                    </View>
                    {pilot.univers === 2 ? (
                      <View style={[styles.informationRow, { paddingVertical: 10, fontStyle: 'bold', alignItems: 'center' }]}>
                        <Text style={{ flex: 1 }}>{t('pages.plot.pdf.Height')} : {measureG.height}cm</Text>
                        <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Text>
                            {t('pages.plot.pdf.Average biomass')} :
                          </Text>
                          <Text style={styles.greenHighlight}>
                            {(measureG.advice).toFixed(2)} kg/m2
                          </Text>
                        </View>
                      </View>
                    ) : (
                      <View style={[styles.informationRow, { paddingVertical: 10, fontStyle: 'bold', alignItems: 'center' }]}>
                        <Text style={{ flex: 1, alignItems: 'center' }}>
                          % {t('pages.plot.pdf.Reference')} : {averageRef(measureG)}%
                        </Text>
                        <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                          <Text style={{ flex: 2 }}>
                            {t('pages.plot.pdf.Average plot advice')} :
                          </Text>
                          <Text style={[{ flex: 1 }, styles.greenHighlight]}>
                            {(measureG.advice).toFixed(0)} kgN/ha
                          </Text>
                        </View>
                      </View>
                    )}
                    <View wrap={false}>
                      <Text>{t('pages.plot.pdf.plot measurements')} :</Text>
                      <View style={[styles.headerTab, styles.informationRow, { fontStyle: 'bold' }]}>
                        <View style={[styles.cell, { flex: 1 }]} />
                        {pilot.univers !== 2 && (
                          <Text style={[styles.cell, { flex: 3 }]}>
                            % {t('pages.plot.pdf.Ref')}
                          </Text>
                        )}
                        <Text style={[styles.cell, { flex: 3 }]}>
                          {t('pages.plot.pdf.Quality index')}
                        </Text>
                        <Text style={[styles.cell, { flex: 3 }]}>
                          {pilot.univers === 2 ? (
                            t('pages.plot.pdf.Biomass')) : (t('pages.plot.pdf.Advice'))}
                        </Text>
                        <Text style={[styles.cell, { flex: 3 }]}>{t('pages.plot.pdf.Comment')}</Text>
                      </View>
                      {measureG.measures.filter(item => !item.areaReference).map((value, i) => (
                        <View key={v3()} style={[styles.rowTab, styles.informationRow]}>
                          <View break style={[styles.cell, { flex: 1, alignItems: 'center' }]}>
                            <View style={styles.pinsContainer}>
                              <View style={styles.pinsOrange} />
                              <Text style={styles.pinsValue}>{i + 1}</Text>
                            </View>
                          </View>
                          {pilot.univers !== 2
                          && (
                            <Text style={[styles.cell, { flex: 3 }]}>
                              {value.percentReference !== null ? (
                                `${value.percentReference}%`
                              ) : (
                                ' '
                              )}
                            </Text>
                          )}
                          <Text style={[styles.cell, { flex: 3 }]}>
                            {qualityIndice(
                              value.qualityIndex,
                              pilot.univers,
                            )}
                          </Text>
                          <Text style={[styles.cell, { flex: 3 }]}>
                            {value.gpnAdvice !== null ? (
                              `${(value.gpnAdvice).toFixed(2)} ${pilot.univers === 2 ? (
                                'kg/m2'
                              ) : (
                                'kgN/ha'
                              )}`
                            ) : (
                              ' '
                            )}
                          </Text>
                          <Text style={[styles.cell, { flex: 3 }]}>{value.comment}</Text>
                        </View>
                      ))}
                    </View>
                  </View>
                  <Text
                    style={{
                      textAlign: 'center',
                      fontSize: 25,
                      paddingTop: 10,
                      fontStyle: 'bold',
                    }}
                  >
                    {t('pages.plot.pdf.Signature')} :
                  </Text>
                  <FooterPDF />
                </Page>
              ))
            ))
          ))
        ))
      ))}
    </Document>
  );
};

export default MeasurePDF;
