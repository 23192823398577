import React from 'react';
import { Switch, Route, NavLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import Plot from '../routes/Plot';
import Contact from '../routes/Contact';
import Login from '../routes/Login';
import Parameters from '../routes/Parameters';
import ParametersCampaign from '../routes/ParametersCampaign';
import ParametersUsers from '../routes/ParametersUsers';
import ParametersUsersInfo from '../routes/ParametersUsersInfo';
import ParametersCountries from '../routes/ParametersCountries';
import ParametersCultureStep from '../routes/ParametersCultureStep';
import ParametersGroups from '../routes/ParametersGroups';
import ParametersDetailsGroup from '../routes/ParametersDetailsGroup';
import ParametersPilots from '../routes/ParametersPilots';
import ParametersPilotsEditSub from '../routes/ParametersPilotsEditSub';
import ParametersPilotUsers from '../routes/ParametersPilotUsers';
import ParametersPilotSubscriptions from '../routes/ParametersPilotSubscriptions';
import ParametersSignatures from '../routes/ParametersSignatures';
import PrivateRoute from '../containers/PrivateRoute';
import Profile from '../routes/Profile';
import Register from '../routes/Register';
import Reports from '../routes/Reports';
import Route404 from '../routes/Route404';
import Synthesis from '../routes/Synthesis';
import Welcome from '../routes/Welcome';
import Azure from '../routes/Azure';
import ViewerPDF from '../routes/ViewerPDF';
import CGU from '../routes/CGU';
import { PlotProvider } from './pages/Plot/Plot.Context';

const Main = () => {
  const { t } = useTranslation('generics');

  return (
      <main>
        <PlotProvider>
          <Switch>
            <Route path='/CGU' component={CGU} />
            <Route path='/login' component={Login} />
            <Route path='/contact' component={Contact} />
            <PrivateRoute exact path='/' component={Welcome} />
            <PrivateRoute path='/plot' component={Plot} />
            <PrivateRoute path='/parameters/users/:id' component={ParametersUsersInfo} />
            <PrivateRoute path='/parameters/users' component={ParametersUsers} />
            <PrivateRoute path='/parameters/campaign' component={ParametersCampaign} />
            <PrivateRoute path='/parameters/countries' component={ParametersCountries} />
            <PrivateRoute
              path='/parameters/culture-base/:idUnivers/subscription/:idSubscription/crop/:idCrop'
              component={ParametersCultureStep}
            />
            <PrivateRoute
              path='/parameters/culture-base/:idUnivers/subscription/:idSubscription'
              component={ParametersCultureStep}
            />
            <PrivateRoute path='/parameters/culture-base/:idUnivers' component={ParametersCultureStep} />
            <PrivateRoute path='/parameters/culture-base' component={ParametersCultureStep} />
            <PrivateRoute path='/parameters/group/:idGroup' component={ParametersDetailsGroup} />
            <PrivateRoute path='/parameters/groups' component={ParametersGroups} />
            <PrivateRoute path='/parameters/pilots/update-subscription' component={ParametersPilotsEditSub} />
            <PrivateRoute path='/parameters/pilots/:id/users' component={ParametersPilotUsers} />
            <PrivateRoute path='/parameters/pilots/:id/subscriptions' component={ParametersPilotSubscriptions} />
            <PrivateRoute path='/parameters/pilots' component={ParametersPilots} />
            <PrivateRoute path='/parameters/signatures' component={ParametersSignatures} />
            <PrivateRoute path='/parameters' component={Parameters} />
            <PrivateRoute path='/profile' component={Profile} />
            <Route path='/azure_connect' component={Azure} />
            <Route path='/register' component={Register} />
            <PrivateRoute path='/reports' component={Reports} />
            <PrivateRoute path='/synthesis' component={Synthesis} />
            <Route path='/pdf' component={ViewerPDF} />
            <Route component={Route404} />
          </Switch>
        </PlotProvider>
        <NavLink className='cgu' to='/cgu'>{t('Terms and conditions')}</NavLink>
      </main>
  );
};

export default Main;
