import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Sidebar from '../components/Sidebar';

import AdminMenu from '../components/AdminMenu';
import icParameters from '../images/ic_page_parameters.png';
import ParamPilotSubscriptions from '../components/Admin/ParamPilotSubscriptions';
import SubscriptionService from '../services/SubscriptionService';
import PilotService from '../services/PilotService';

const ParametersPilotSubscriptions = ({ match }) => {
  const { t } = useTranslation('generics');
  const [pilot, setPilot] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const { params } = match;

  useEffect(() => {
    async function fetch() {
      await axios.all([
        SubscriptionService.fetchAll(),
        PilotService.fetchAll(),
      ]).then(axios.spread((...responses) => {
        const data = responses[1].data.pilots
          .find(item => item.id === parseInt(params.id, 10));
        setSubscriptions(responses[0].data.subscriptions.map(sub => ({
          ...sub,
          label: `${sub.universName} - ${sub.year}`,
          value: sub.id,
        })));
        if (data) setPilot(data);
      })).catch(errors => console.log('ParamPilots@fetchData', errors));
    }
    fetch();
  }, [params, setPilot, setSubscriptions]);

  const handleOnAdd = data => PilotService.edit({
    ...data, subscriptions: data.subscriptions.map(item => item.id),
  })
    .then(() => setPilot({
      ...pilot,
      subscriptions: [...pilot.subscriptions, ...data.subscriptions],
    }))
    .catch(err => console.log('ParamPilots@edit', err.message));

  const handleOnDelete = data => PilotService.edit(data)
    .then(() => setPilot({
      ...pilot,
      subscriptions: pilot.subscriptions
        .filter(item => item.id !== data.subscriptions_to_remove[0]),
    }))
    .catch(err => console.log('ParamPilots@edit', err.message));

  return (
    <div className='page'>
      <Sidebar title={t('pages.parameters.Parameters')} icon={icParameters}>
        <AdminMenu />
      </Sidebar>
      <div className='page-content'>
        <ParamPilotSubscriptions
          subscriptionList={subscriptions}
          item={pilot}
          handleOnAdd={data => handleOnAdd(data)}
          handleOnDelete={data => handleOnDelete(data)}
        />
      </div>
    </div>
  );
};

export default ParametersPilotSubscriptions;
