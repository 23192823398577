import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddPlotForm from '../../../../containers/AddPlotForm';
import PlotService from '../../../../services/PlotService';
import Button from '../../../Button';
import ModalEntity from '../../../ModalEntity';

function AddPlot({ theme = 'white', homePage = false, withoutMargin = true }) {
  const { t } = useTranslation('generics');
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    PlotService.on('plotAdded', () => setVisible(false));
  }, []);

  return (
    <>
      <Button
        withoutMargin={withoutMargin}
        className={!homePage && 'w-100 mt-2'}
        title={`${t('form.Add a plot')}`}
        onClick={() => setVisible(true)}
        theme={theme}
      />
      <ModalEntity title1={t('form.Add a plot')} visible={visible} onClose={() => setVisible(false)}>
        <AddPlotForm />
      </ModalEntity>
    </>
  );
}

AddPlot.displayName = 'Borealis-Add-Plot';
AddPlot.propTypes = {
  theme: PropTypes.string,
};

export default AddPlot;
