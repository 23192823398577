import PropTypes from 'prop-types';
import React from 'react';

const TextareaWithLabel = ({
  label,
  name,
  onChange,
  placeholder = '',
  value = '',
}) => (
  <label htmlFor={name}>
    {label}
    <textarea
      className='form-control'
      name={name}
      onChange={event => onChange(event.target.value)}
      placeholder={placeholder}
      value={value}
    />
  </label>
);

TextareaWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default TextareaWithLabel;
