import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';

import { useTranslation } from 'react-i18next';
import Sidebar from '../components/Sidebar';
import AdminMenu from '../components/AdminMenu';
import PageTitle from '../components/PageTitle';
import ParamSignatures from '../components/Admin/ParamSignatures';
import EditSignature from '../components/Admin/Signatures/EditSignature';
import SignatureService from '../services/SignatureService';
import icParameters from '../images/ic_page_parameters.png';
import signaturesImg from '../images/signaturesImg.png';

const ParametersSignatures = () => {
  const { t } = useTranslation('generics');
  const [signatures, setSignatures] = useState([]);
  const [signatureSelected, setSelectedSignature] = useState(null);

  useEffect(() => {
    SignatureService.fetchAll()
      .then(response => {
        setSignatures(response.data.signatures);
      });
  }, []);

  const changeSignatureState = data => {
    SignatureService.edit(data)
      .then(() => {
        SignatureService.fetchAll()
          .then(response => {
            setSignatures(response.data.signatures);
          });
      });
  };

  const selectedSignature = sign => {
    setSelectedSignature(sign);
  };

  const saveSignature = data => {
    const newSign = {
      id: signatureSelected.id,
      country: signatureSelected.country.id,
      link: data.imgLink,
      text: data.content,
      isActive: signatureSelected.isActive,
    };
    if (typeof data.image === 'object') {
      newSign.image = data.image;
    }
    SignatureService.edit(newSign)
      .then(() => {
        SignatureService.fetchAll()
          .then(response => {
            setSignatures(response.data.signatures);
          });
      });
  };

  return (
    <div className='page'>
      <Sidebar title={t('pages.parameters.Parameters')} icon={icParameters}>
        <AdminMenu />
      </Sidebar>
      <div className='page-content p-0 short bg-light'>
        <div className='my-4 p-5'>
          <PageTitle title={t('pages.parameters.menu.Administration of email signatures')} />
        </div>
        {signatures.length ? (
          <ParamSignatures
            active={signatureSelected && signatureSelected.id}
            items={signatures}
            changeActivation={data => changeSignatureState(data)}
            selectSignature={id => selectedSignature(id)}
          />
        ) : (
          <ReactLoading type='bubbles' color='#8f8f8f' />
        )}
      </div>
      <div className='page-content mt-5'>
        <div className='row mx-0'>
          <div className='col-9'>
            { signatureSelected && (
              <EditSignature
                data={signatures.filter(sign => (sign.id === signatureSelected.id))[0]}
                onSave={data => saveSignature(data)}
              />
            )}
          </div>
          <div className='col-3 pr-0'>
            <img src={signaturesImg} alt='signatures asset' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParametersSignatures;
