/* eslint-disable no-underscore-dangle */
import swal from 'sweetalert';
import { API, setSession } from '../api/request';
import EventEmitter from '../domain/EventEmitter';

class AppService extends EventEmitter {
  init = () => API.get(`${process.env.REACT_APP_API_URL}front/users/get-user-datas/`);

  loginPost = credentials => API.post('login_azure', credentials);

  contact = data => API.post('front/contact', data);

  getAzureToken = code => (
    API.get(`${process.env.REACT_APP_AZURE_URL}token?p=b2c_1_n-pilot_siupin&client_secret=${process.env.REACT_APP_CLIENT_SECRET}&grant_type=authorization_code&client_id=${process.env.REACT_APP_CLIENT_ID}&code=${code}`)
  )

  login = () => API.get('login_azure');

  logout = () => API.get(`${process.env.REACT_APP_AZURE_URL}logout?p=b2c_1_n-pilot_siupin`);
}

const instance = new AppService();

API.interceptors.response.use(
  response => response,
  err => new Promise(() => {
    if (err.response.status === 401 && err.response.data.message !== 'User not found. You need to create a new account.') {
      swal({
        text: 'Session expired',
        button: 'Reconnect',
      })
        .then(() => {
          setSession();
          window.location.href = '/';
        });
    }
    throw err;
  }),
);

export default instance;
