import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

import { useTranslation } from 'react-i18next';
import CultureService from '../../../services/CultureService';

const PreviousButton = () => {
  const { t } = useTranslation('generics');

  return (
    <button
      className='btn btn-link text-secondary text-uppercase'
      onClick={() => CultureService.emit('goToPrevious')}
    >
      <FontAwesomeIcon icon={faAngleLeft} /> {t('form.Previous')}
    </button>
  );
};

export default PreviousButton;
