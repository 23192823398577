
import { connect } from 'react-redux';

import CountryService from '../services/CountryService';
import { editAdminCountry, setAdminCountries } from '../redux/actions';
import ParamCountries from '../components/Admin/ParamCountries';

const mapStateToProps = state => ({
  items: state.aCountries,
});

const mapDispatchtoProps = dispatch => ({
  changeActivation: data => {
    CountryService.edit(data)
      .then(() => dispatch(editAdminCountry(data)))
      .catch(err => console.log('CountryService@edit', { err }));
  },
  fetchData: () => {
    setAdminCountries(null);
    CountryService.fetchAll()
      .then(res => dispatch(setAdminCountries(res.data.countries)))
      .catch(err => console.log('CountryService@fetchAll', err.message));
  },
});

export default connect(mapStateToProps, mapDispatchtoProps)(ParamCountries);
