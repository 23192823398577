import PropTypes from 'prop-types';
import React from 'react';

import Signatures from './Signatures/Signatures';

const ParamSignatures = ({ selectSignature, changeActivation, items = null, active }) => (
  <div className='pt-lg-5'>
    <Signatures
      active={active}
      items={items}
      onChangeActivation={data => changeActivation(data)}
      onSelect={data => selectSignature(data)}
    />
  </div>
);

ParamSignatures.propTypes = {
  changeActivation: PropTypes.func.isRequired,
  selectSignature: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    country: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    }).isRequired,
    isActive: PropTypes.bool.isRequired,
    link: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  })),
};

export default ParamSignatures;
