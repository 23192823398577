import React from 'react';
import PropTypes from 'prop-types';

const InputImageWithLabel = ({ label, name, onChange, value = '' }) => (
  <div>
    <label htmlFor={name}>
      {label}
      <input
        className='form-control'
        name={name}
        type='file'
        onChange={event => onChange(event.target.files[0])}
      />
    </label>
    {value && value !== '' && value.length && (
      <div>
        <img src={value} alt={name} />
      </div>
    )}
  </div>
);

InputImageWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default InputImageWithLabel;
