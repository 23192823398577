/* eslint-disable react/jsx-no-target-blank */
import PropTypes from 'prop-types';
import React from 'react';
import PageTitle from './PageTitle';
import logo from '../images/logo_app.png';

const Sidebar = ({ title, icon, children, className = '' }) => (
  <div id='sidebar' className={className}>
    <div className='app-logo text-center bg-light p-5'>
      <a target='_blank' href='https://www.lat-nitrogen.com'>
        <img src={logo} alt='N-pilot logo' />
      </a>
    </div>
    <PageTitle title={title} icon={icon} />
    {children}
  </div>
);

Sidebar.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Sidebar;
