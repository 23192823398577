import PropTypes from 'prop-types';
import React from 'react';
import v3 from 'uuid';

import { useTranslation } from 'react-i18next';
import FarmItem from './FarmItem';

const FarmTable = ({ farms, adminMode }) => {
  const { t } = useTranslation('generics');

  return (
    <table className='table table-striped my-4'>
      <thead className='bg-light text-info font-weight-light'>
        <tr>
          <th>{t('pages.profile.farm.table.Legal Structure')}</th>
          <th>{t('pages.profile.farm.table.firstname')}</th>
          <th>{t('pages.profile.farm.table.lastname')}</th>
          <th>{t('pages.profile.farm.table.Postal code')}</th>
          <th>{t('pages.profile.farm.table.City')}</th>
          <th>{t('pages.profile.farm.table.Email')}</th>
          <th>{t('pages.profile.farm.table.Campaign')}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {farms?.map(farm => (
          <FarmItem data={farm} key={v3()} adminMode={adminMode} />
        ))}
      </tbody>
    </table>
  );
};

FarmTable.propTypes = {
  farms: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    company: PropTypes.string.isRequired,
    address: PropTypes.string,
    address2: PropTypes.string,
    postcode: PropTypes.string.isRequired,
    city: PropTypes.string,
    email: PropTypes.string,
    campain: PropTypes.number.isRequired,
  }).isRequired).isRequired,
};

export default FarmTable;
