import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import Alert from '../Alert';
import ExploitationService from '../../services/ExploitationService';
import InputWithLabel from '../InputWithLabel';
import Button from '../Button';

const AddExploitationForm = ({ onSubmit = () => {} }) => {
  const { t } = useTranslation('generics');
  const initialData = {
    company: '',
    firstname: '',
    lastname: '',
    postalCode: '',
    city: '',
    email: '',
  };
  const [data, setData] = useState(initialData);

  const [error, setError] = useState(null);

  useEffect(() => {
    ExploitationService.on('exploitationAdded', () => setData(initialData));
  }, [initialData]);

  useEffect(() => {
    ExploitationService.on('addExploitationError', message => setError(message));
  }, []);

  const handleSubmit = () => {
    setError(null);
    const formOk = data.company.trim().length && data.postalCode.trim().length;

    if (!formOk) {
      setError(t('fieldsError.Fields are required'));
    } else {
      onSubmit(data);
    }
  };

  const handleChange = (field, value) => setData({ ...data, [field]: value });

  return (
    <div className='row'>
      {error && (<div className='col-12'><Alert message={error} /></div>)}
      <div className='col-md-6 mb-3'>
        <InputWithLabel
          name='company'
          placeholder={t('pages.profile.organization.modal.fields.Company')}
          label={t('pages.profile.organization.modal.fields.Company')}
          onChange={val => handleChange('company', val)}
          value={data.company}
        />
      </div>
      <div className='col-md-6 mb-3'>
        <InputWithLabel
          name='firstname'
          placeholder={t('pages.profile.organization.modal.fields.Firstname')}
          label={t('pages.profile.organization.modal.fields.Firstname')}
          onChange={val => handleChange('firstname', val)}
          value={data.firstname}
        />
      </div>
      <div className='col-md-6 mb-3'>
        <InputWithLabel
          name='lastname'
          placeholder={t('pages.profile.organization.modal.fields.Lastname')}
          label={t('pages.profile.organization.modal.fields.Lastname')}
          onChange={val => handleChange('lastname', val)}
          value={data.lastname}
        />
      </div>
      <div className='col-md-6 mb-3'>
        <InputWithLabel
          name='postalCode'
          placeholder={t('pages.profile.organization.modal.fields.Postal code')}
          label={t('pages.profile.organization.modal.fields.Postal code')}
          onChange={val => handleChange('postalCode', val)}
          value={data.postalCode}
        />
      </div>
      <div className='col-md-6 mb-3'>
        <InputWithLabel
          name='city'
          placeholder={t('pages.profile.organization.modal.fields.City')}
          label={t('pages.profile.organization.modal.fields.City')}
          onChange={val => handleChange('city', val)}
          value={data.city}
        />
      </div>
      <div className='col-md-6 mb-3'>
        <InputWithLabel
          name='email'
          placeholder={t('pages.profile.organization.modal.fields.Email')}
          label={t('pages.profile.organization.modal.fields.Email')}
          onChange={val => handleChange('email', val)}
          value={data.email}
        />
      </div>
      <div className='col-md-12 mb-3 text-center'>
        <Button title={t('form.Add')} onClick={() => handleSubmit()} />
      </div>
    </div>
  );
};

AddExploitationForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default AddExploitationForm;
