import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import v3 from 'uuid';
import { useTranslation } from 'react-i18next';
import PlotMeasureItem from './PlotMeasureItem';
import { useHistory, useLocation  } from "react-router-dom";

function PlotMeasures({ items, onSelectMeasure, measureSelected, setMeasureSelected }) {
  const { t } = useTranslation('generics');
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    let urlSplited = location.pathname.split("/").splice(1)
    let measureIndex = urlSplited.indexOf("measure")
    let measures = items.organization[0]?.exploitation[0]?.plot[0]?.pilotage[0]?.measureGroup
    if(measureIndex !== -1) {
      onSelectMeasure(measures.find((m) => m.id === parseInt(urlSplited[measureIndex + 1], 10)))
    }
  }, [])

  const handleSelectMeasure = measure => {
    const result = measureSelected.filter(measureItem => measureItem.id === measure.id);

    if (result.length === 0) {
      setMeasureSelected([...measureSelected, measure]);
    } else {
      const newData = measureSelected.filter(measureItem => measureItem.id !== measure.id);
      setMeasureSelected(newData);
    }
  };

  const updateLocation = (measure) => {
    //If no measureId is define in url, we put the /measure/:id. If there is an id, we change it
    let urlSplited = location.pathname.split("/").splice(1)
    let indexMeasure = urlSplited.indexOf("measure")
    if(indexMeasure !== -1) {
      urlSplited[indexMeasure + 1] = measure.id
      history.push(`/${urlSplited.join("/")}`)
    } else {
      history.push(`${location.pathname}/measure/${measure.id}`)
    }
  }

  return (
    <>
      <table className='table table-borderless table-striped'>
        <thead className='thead-dark'>
          <tr>
            <th />
            <th>{t('pages.plot.measure.details.Growth stage')}</th>
            <th>{t('pages.plot.measure.details.Date')}</th>
            <th>{t('pages.plot.measure.details.Advice')} (kgN/ha)</th>
            <th>{t('pages.plot.measure.details.Details')}</th>
          </tr>
        </thead>
        <tbody>
          {items.organization[0]
          ?.exploitation[0]
          ?.plot[0]
          ?.pilotage[0]
          ?.measureGroup
          ?.map(measure => (
            <PlotMeasureItem
              key={v3()}
              measure={measure}
              onSelect={data => onSelectMeasure(data) & updateLocation(data)}
              handleSelectMeasure={() => handleSelectMeasure(measure)}
              measureSelected={measureSelected}
            />
          ))}
        </tbody>
      </table>
    </>
  );
}

PlotMeasures.displayName = 'Borealis-Plot-Measures';
PlotMeasures.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    advice: PropTypes.number.isRequired,
    stageName: PropTypes.string.isRequired,
    date: PropTypes.shape({
      date: PropTypes.string.isRequired,
    }).isRequired,
  })).isRequired,
  onSelectMeasure: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  measureSelected: PropTypes.array.isRequired,
  setMeasureSelected: PropTypes.func.isRequired,
};

export default PlotMeasures;
