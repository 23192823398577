import PropTypes from 'prop-types';
import React from 'react';

const HeaderAction = ({ action, description, image }) => (
  <div className='header-action mb-3'>
    <div className='image' style={{ backgroundImage: `url(${image})` }} />
    <div className='content bg-white text-info shadow text-center'>
      <div className='action text-uppercase font-weight-bold'>{action}</div>
      <div className='description'>{description}</div>
    </div>
  </div>
);

HeaderAction.propTypes = {
  action: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default HeaderAction;
