import PropTypes from 'prop-types';
import React from 'react';
import v3 from 'uuid';

import { useTranslation } from 'react-i18next';
import VarietyRow from './VarietyRow';

const VarietiesTable = ({ items }) => {
  const { t } = useTranslation('generics');

  return (
    <table className='table table-striped'>
      <thead className='thead-dark'>
        <tr>
          <th>{t('pages.parameters.culture.Name')}</th>
          <th>c1</th>
          <th>c2</th>
          <th>{t('pages.parameters.culture.Class')}</th>
        </tr>
      </thead>
      <tbody>
        {items.map(item => (
          <VarietyRow
            data={item}
            key={v3()}
          />
        ))}
      </tbody>
    </table>
  );
};

VarietiesTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    class: PropTypes.number.isRequired,
    c1: PropTypes.number.isRequired,
    c2: PropTypes.number.isRequired,
  })).isRequired,
};

export default VarietiesTable;
