import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getVarietiesOptions, STONE_OPTIONS } from '../pages/Plot/constants';
import Alert from '../Alert';
import Button from '../Button';
import InputWithLabel from '../InputWithLabel';
import InputSelectWithLabel from '../InputSelectWithLabel';
import { isFloat } from '../../domain/utils';
import PlotService from '../../services/PlotService';
import { PlotContext } from '../pages/Plot/Plot.Context';
import TextureService from '../../services/TextureService'

const AddPlotForm = ({ exploitations, onSubmit = () => {} }) => {
  const { plotsTest, setPlotsTest } = useContext(PlotContext)
  const [textures, setTextures] = useState([])
  const [textureOption, setTextureOption] = useState([])
  
  const { t, i18n } = useTranslation('generics');
  const initialData = {
    name: '',
    surface: '',
    ilot: '',
    texture: '',
    stone: '',
    groundDepth: '',
    organicMaterial: '',
    cropName: '',
    varietyName: '',
    exploitation: exploitations[0] ? exploitations[0].value : '',
  };
  const [data, setData] = useState(initialData);
  const [error, setError] = useState(null);
  const cropOptions = useMemo(() => {
    const CROP_OPTIONS = [
      t('pages.plot.modal.options.crops.Strong wheat'),
      t('pages.plot.modal.options.crops.Durum wheat'),
      t('pages.plot.modal.options.crops.Soft wheat'),
      t('pages.plot.modal.options.crops.Quality soft wheat'),
      t('pages.plot.modal.options.crops.Rape'),
      t('pages.plot.modal.options.crops.Barley winter'),
      t('pages.plot.modal.options.crops.Spring barley'),
      t('pages.plot.modal.options.crops.Triticale'),
    ];

    return CROP_OPTIONS.map(cOptions => ({
      label: cOptions.toUpperCase(),
      value: cOptions.toUpperCase(),
    }));
  }, [t]);
  // eslint-disable-next-line no-unused-vars
  const varietiesOptions = useMemo(() => {
    if (data.cropName) {
      const varieties = getVarietiesOptions(t, data.cropName);

      return varieties.map(vOptions => ({
        label: vOptions.toUpperCase(),
        value: vOptions.toUpperCase(),
      })) || [];
    }

    return [];
  }, [data.cropName, t]);

  useEffect(() => {
    PlotService.on('plotAdded', () => setData(initialData));
  }, [initialData]);


  useEffect(() => {
    PlotService.on('addPlotError', message => setError(message));
    //Get all textures
    TextureService.fetchAll()
      .then((result) => {
        console.log(result)
        setTextures(result.data.data)
        formatTextureOption(result.data.data)
      })
      .catch((e) => {
        console.error(e.message)
        
      })
    // Define default selectors
    setData({
      ...data,
      cropName: cropOptions[0].value,
    });
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const handleSubmit = () => {
    setError(null);
    const formOk = data.name.trim().length && data.surface.trim().length;

    if (!formOk) {
      setError(t('fieldsError.Fields are required'));
    } else if (!isFloat(data.surface)) {
      setError(`${t('fieldsError.Surface must be a number or a float')} (ex: 2.1)`);
    } else {
      const { cropName, varietyName, ...rest } = data;
      const formatedData = {
        ...rest,
        pilotage: [
          {
            overallDose: 0,
            performanceObjective: 0,
            cropName,
            varietyName,
          },
        ],
      };

      let newPlot = {
        ...formatedData,
        exploitation: parseInt(data.exploitation, 10),
      }

      PlotService.store(newPlot)
      .then(res => {
        const plotStructure = {
          id: 0,
          name: data.name,
          surface: 0,
          ilot: '',
          texture: '',
          stone: '',
          groundDepth: '',
          organicMaterial: '',
          polygon: '',
          pilotage: [{
            overallDose: '0',
            performanceObjective: '0',
          }],
          ...data,
        };
        setPlotsTest([...plotsTest, { ...plotStructure, id: res.data.plot.id }])
        PlotService.emit('plotAdded');
      })
      .catch(err => PlotService.emit('addPlotError', err.message));
      
      PlotService.emit('plotAdded');
    }
  };

  const formatTextureOption = (options) => {
    console.log(i18n.language.substring(0,2))
    let currentLang = i18n.language ? i18n.language.substring(0,2) : "en"
    let opts = []
    options.forEach((opt) => {
      let objectEntries = new Map(Object.entries(opt))
      opts.push({value: objectEntries.get("id"), label: objectEntries.get(`${currentLang}_${currentLang.toUpperCase()}`)})
    })
console.log(opts)
    setTextureOption([{id: '', value: ''}, ...opts])
  }

  const handleChange = (field, value, onlyNumber = false) => {
    console.log((value > 0));
    if (onlyNumber && Number.isNaN(value) || (value < 0)) {
      return;
    }
    setData({ ...data, [field]: value });
  };

  return (
    <div className='row'>
      {error && (<div className='col-12'><Alert message={error} /></div>)}
      <div className='col-md-6 mb-3'>
        <InputWithLabel
          name='name'
          placeholder={t('pages.plot.modal.Name')}
          label={t('pages.plot.modal.Name')}
          onChange={val => handleChange('name', val)}
          value={data.name}
        />
      </div>
      <div className='col-md-6 mb-3'>
        <InputWithLabel
          name='surface'
          type='number'
          placeholder={t('pages.plot.modal.Surface')}
          label={t('pages.plot.modal.Surface')}
          onChange={val => handleChange('surface', val, true)}
          value={data.surface}
        />
      </div>
      <div className='col-md-6 mb-3'>
        <InputWithLabel
          name='ilot'
          type='number'
          placeholder={t('pages.plot.modal.Ilot')}
          label={t('pages.plot.modal.Ilot')}
          onChange={val => handleChange('ilot', val, true)}
          value={data.ilot}
        />
      </div>
      <div className='col-md-6 mb-3'>
        <InputWithLabel
          name='groundDepth'
          type='number'
          placeholder={t('pages.plot.modal.Ground depth')}
          label={t('pages.plot.modal.Ground depth')}
          onChange={val => handleChange('groundDepth', val, true)}
          value={data.groundDepth}
        />
      </div>
      <div className='col-md-6 mb-3'>
        <InputWithLabel
          name='organicMaterial'
          type='number'
          placeholder={t('pages.plot.modal.Organic material')}
          label={t('pages.plot.modal.Organic material')}
          onChange={val => handleChange('organicMaterial', val, true)}
          value={data.organicMaterial}
        />
      </div>
      {/* <div className='col-md-6 mb-3 pt-1'>
        <InputSelectWithLabel
          name='cropName'
          label={t('pages.synthesis.Crop')}
          onChange={val => handleChange('cropName', val)}
          options={cropOptions}
          value={data.cropName}
        />
      </div>
      <div className='col-md-6 mb-3 pt-1'>
        <InputSelectWithLabel
          name='varietyName'
          label={t('pages.synthesis.Varieties')}
          onChange={val => handleChange('varietyName', val)}
          options={varietiesOptions}
          value={data.varietyName}
        />
      </div> */}
      <div className='col-md-6 mb-3 pt-1'>
        <InputSelectWithLabel
          name='texture'
          label={t('pages.plot.content.Texture')}
          onChange={val => handleChange('texture', val)}
          options={textureOption}
          value={data.texture}
        />
      </div>
      <div className='col-md-6 mb-3 pt-1'>
        <InputSelectWithLabel
          name='stone'
          label={t('pages.plot.content.Stone')}
          onChange={val => handleChange('stone', val)}
          options={STONE_OPTIONS}
          value={data.stone}
        />
      </div>
      <div className='col-md-6 mb-3 pt-1'>
        <InputSelectWithLabel
          name='exploitation'
          label={t('pages.plot.telepac.exploitation')}
          onChange={val => handleChange('exploitation', val)}
          options={exploitations}
          value={data.exploitation}
        />
      </div>
      <div className='col-md-12 mb-3 text-center'><Button title={t('form.Create')} onClick={() => handleSubmit()} /></div>
    </div>
  );
};

AddPlotForm.propTypes = {
  exploitations: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  })).isRequired,
  onSubmit: PropTypes.func,
};

export default AddPlotForm;
