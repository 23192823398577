import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import v4 from 'uuid';

import { useTranslation } from 'react-i18next';
import { menuItems, adminItem } from '../router';

import icLogout from '../images/ic_menu_logout.png';
import icWelcome from '../images/ic_menu_welcome.png';

const Menu = ({ location, data }) => {
  const { t } = useTranslation('generics');
  const [items] = useState(menuItems);

  const hasMenu = location.pathname !== '/login';

  const addAdminPath = () => {
    if (items.length !== 5) { // todo: fix
      if (data.roles) {
        if (data.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
          items.push(adminItem);
        }
      }
    }
  };

  const onLogout = () => {
    localStorage.removeItem('jwt_token');
    window.location = `${process.env.REACT_APP_AZURE_URL}logout?p=${process.env.REACT_APP_AZURE_POLICY}&post_logout_redirect_uri=${process.env.REACT_APP_REDIRECT_URL}`;
    // AppService.emit('logout');
    // history.push('/login');
  };

  return hasMenu ? (
    <nav id='side-menu' className='bg-secondary'>
      <NavLink exact to='/' title={t('Welcome')} activeClassName='active'>
        <img src={icWelcome} alt='Welcome' />
      </NavLink>
      {addAdminPath()}
      { items.map(item => (
        <NavLink to={item.path} key={v4()} title={t(item.label)} activeClassName='active'>
          <img src={item.icon} alt={item.label} />
        </NavLink>
      )) }
      <button type='button' className='border-0' onClick={() => onLogout()}><img src={icLogout} alt='Logout' /></button>
    </nav>
  ) : ('');
};

Menu.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default withRouter(Menu);
