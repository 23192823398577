import PropTypes from 'prop-types';
import React, { useState } from 'react';
import v4 from 'uuid';

import DropdownButtom from './DropdownButton';

const languages = [
  { code: 'en', label: 'English' },
  { code: 'fr', label: 'Français' },
  { code: 'de', label: 'German' },
  { code: 'bg', label: 'Bulgaria' },
  { code: 'ro', label: 'Romania' },
  { code: 'hu', label: 'Hungarian' },
];

const getLangLabel = code => {
  const lang = languages.find(item => item.code === code);

  if (lang) {
    return lang.label;
  }

  return 'English';
};

const LangDropdown = ({ onLangChange, lang = 'en' }) => {
  const [open, setOpen] = useState(false);

  const handleLangChange = code => {
    setOpen(false);
    onLangChange(code);
  };

  const openClassName = open ? 'show' : '';
  return (
    <div className={`lang-selector d-inline-block dropdown ${openClassName}`}>
      <DropdownButtom title={getLangLabel(lang)} onClick={() => setOpen(!open)} />
      <div className={`dropdown-menu ${openClassName}`}>
        {languages.map(language => (
          <button className='dropdown-item text-info' type='button' href='#' key={v4()} onClick={() => handleLangChange(language.code)}>{language.label}</button>
        ))}
      </div>
    </div>
  );
};

LangDropdown.propTypes = {
  onLangChange: PropTypes.func.isRequired,
  lang: PropTypes.string,
};

export default LangDropdown;
