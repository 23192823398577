import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Sidebar from '../components/Sidebar';

import AdminMenu from '../components/AdminMenu';
import CultureMenu from '../containers/CultureMenu';
import CultureService from '../services/CultureService';
import icParameters from '../images/ic_page_parameters.png';
import PageTitle from '../components/PageTitle';
import CultureStep1 from '../containers/CultureStep1';
import CultureStep2 from '../components/Admin/Culturebase/CultureStep2';
import CultureStep3 from '../components/Admin/Culturebase/CultureStep3';

import imgCulture from '../images/imgCulture.png';

const ParametersCultureStep = ({ history, match }) => {
  const { t } = useTranslation('generics');
  const { params } = match;

  const goToStep2 = idSubscription => {
    const location = `/parameters/culture-base/${params.idUnivers}/subscription/${idSubscription}`;
    history.push(location);
  };

  const goToStep3 = idCrop => {
    const location = `/parameters/culture-base/${params.idUnivers}/subscription/${params.idSubscription}/crop/${idCrop}`;
    history.push(location);
  };

  const getStep = () => {
    if (params.idCrop && params.idSubscription && params.idUnivers) {
      return (
        <CultureStep3
          cropId={parseInt(params.idCrop, 10)}
          onSelectCrop={cropId => goToStep3(cropId)}
        />
      );
    }
    if (params.idSubscription && params.idUnivers) {
      return (
        <CultureStep2
          subscriptionId={parseInt(params.idSubscription, 10)}
          onSelectCrop={cropId => goToStep3(cropId)}
        />
      );
    }
    if (params.idUnivers) {
      return (
        <CultureStep1
          universId={parseInt(params.idUnivers, 10)}
          onSelectSubscription={idSubscription => goToStep2(idSubscription)}
        />
      );
    }

    return '';
  };

  useEffect(() => CultureService.on('goToPrevious', () => {
    let location = `/parameters/culture-base/${params.idUnivers}`;
    if (params.idCrop && params.idSubscription && params.idUnivers) {
      location = `/parameters/culture-base/${params.idUnivers}/subscription/${params.idSubscription}`;
    }
    history.push(location);
  }), [params, history]);

  return (
    <div className='page'>
      <Sidebar title={t('pages.parameters.Parameters')} icon={icParameters}>
        <AdminMenu />
      </Sidebar>
      <div className='page-content short bg-light'>
        <div className='mt-4 mb-4'>
          <PageTitle title={t('pages.parameters.menu.Culture Base')} />
        </div>
        <CultureMenu />
      </div>
      <div className='page-content mt-5'>
        <div className='row mx-0'>
          <div className='col-9'>
            {getStep()}
          </div>
          <div className='col-3 p-0'>
            <img src={imgCulture} alt='culture right side' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParametersCultureStep;
