import { combineReducers } from 'redux';

import { plotsReducer } from './plots/reducers';
import { ActionTypes } from './actions';
import { addExploitationIntoAGroup, addExploitationToFirstCampaign } from '../domain/utils';

export const userReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.SET_USER:
      return action.user;
    case ActionTypes.ADD_EXPLOITATION:
      return { ...state, groups: addExploitationIntoAGroup(state.groups, action.exploitation) };
    default:
      return state;
  }
};

export const loginReducer = (state = { error: null }, action) => {
  switch (action.type) {
    case ActionTypes.SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export const exploitationsReducer = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.SET_EXPLOITATIONS:
      return action.exploitations;
    case ActionTypes.ADD_EXPLOITATION:
      return addExploitationToFirstCampaign(state, action.exploitation);
    case ActionTypes.DELETE_EXPLOITATION:
      return state.filter(expl => expl.id !== action.exploitationId);
    default:
      return state;
  }
};

export const campaignsReducer = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.SET_CAMPAIGNS:
      return action.campaigns;
    default:
      return state;
  }
};

export const appReducer = (state = { lang: 'en' }, action) => {
  switch (action.type) {
    case ActionTypes.SET_LANGUAGE:
      return {
        ...state,
        lang: action.lang,
      };
    default:
      return state;
  }
};

export const filtersReducer = (state = { campaign: null, exploitation: null }, action) => {
  switch (action.type) {
    case ActionTypes.SET_FILTERS:
      return action.filters;
    default:
      return state;
  }
};

// export const plotsReducer = (state = [], action) => {
//   switch (action.type) {
//     case ActionTypes.SET_PLOTS:
//       return action.plots;
//     case ActionTypes.ADD_PLOT:
//       return [...state, action.plot];
//     case ActionTypes.DELETE_PLOT:
//       return state.filter(plot => plot.id !== action.plotId);
//     default:
//       return state;
//   }
// };

export const adminGroupsReducer = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.SET_ADMIN_GROUP:
      return action.groups;
    case ActionTypes.REMOVE_ADMIN_GROUP:
      return state.filter(item => item.id !== action.groupId);
    default:
      return state;
  }
};

export const adminCountriesReducer = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.SET_ADMIN_COUNTRIES:
      return action.countries;
    case ActionTypes.EDIT_ADMIN_COUNTRY:
      return state.map(country => {
        if (country.id === action.country.id) {
          return { ...country, isActive: action.country.isActive };
        }
        return country;
      });
    default:
      return state;
  }
};

export const adminPilotsReducer = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.SET_ADMIN_PILOTS:
      return action.pilots;
    case ActionTypes.DELETE_ADMIN_PILOT:
      return state.filter(pilot => pilot.id !== action.id);
    case ActionTypes.UPDATE_ADMIN_PILOT_SUBSCRIPTIONS:
      return state.map(pilot => {
        if (pilot.id === action.data.id) {
          return { ...pilot, subscriptions: action.data.subscriptions };
        }
        return pilot;
      });
    case ActionTypes.UPDATE_ADMIN_PILOT_USERS:
      return state.map(pilot => {
        if (pilot.id === action.data.id) {
          return { ...pilot, users: action.data.users };
        }
        return pilot;
      });
    case ActionTypes.DELETE_ADMIN_PILOT_USER:
      return state.map(pilot => {
        if (pilot.id === action.data.id) {
          return {
            ...pilot,
            users: pilot.users.filter(user => user.id !== action.data.user_to_remove),
          };
        }
        return pilot;
      });
    default:
      return state;
  }
};

export const adminUsersReducer = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.SET_ADMIN_USERS:
      return action.users;
    default:
      return state;
  }
};

export const adminSubscriptionsReducer = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.SET_ADMIN_SUBSCRIPTIONS:
      return action.subscriptions;
    default:
      return state;
  }
};

export const adminUniversReducer = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.SET_ADMIN_UNIVERS:
      return action.univers;
    case ActionTypes.UPDATE_ADMIN_UNIVERS_SUBSCRIPTION:
      return state.map(univers => ({
        ...univers,
        subscriptions: univers.subscriptions.map(sub => {
          if (sub.id === action.subscription.id) {
            return { ...sub, isActive: action.subscription.isActive };
          }
          return sub;
        }),
      }));
    case ActionTypes.ADD_ADMIN_UNIVERS_SUBSCRIPTION:
      return state.map(univers => {
        if (univers.id === action.data.univers) {
          return {
            ...univers,
            subscriptions: [...univers.subscriptions, action.data.subscription],
          };
        }

        return univers;
      });
    case ActionTypes.DELETE_ADMIN_UNIVERS_SUBSCRIPTION:
      return state.map(univers => {
        if (univers.id === action.data.univers) {
          return {
            ...univers,
            subscriptions: univers.subscriptions.filter(((s) => s.id !== action.data.subscription)),
          };
        }

        return univers;
      });
    default:
      return state;
  }
};

export const adminSignaturesReducer = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.SET_ADMIN_SIGNATURES:
      return action.signatures;
    case ActionTypes.UPDATE_ADMIN_SIGNATURE:
      return state.map(signature => {
        if (signature.id === action.signature.id) {
          return action.signature;
        }
        return signature;
      });
    default:
      return state;
  }
};

const combinedReducers = combineReducers({
  user: userReducer,
  login: loginReducer,
  exploitations: exploitationsReducer,
  campaigns: campaignsReducer,
  app: appReducer,
  filters: filtersReducer,
  plots: plotsReducer,
  aGroups: adminGroupsReducer,
  aCountries: adminCountriesReducer,
  aPilots: adminPilotsReducer,
  aUsers: adminUsersReducer,
  aSubscriptions: adminSubscriptionsReducer,
  aUnivers: adminUniversReducer,
  aSignatures: adminSignaturesReducer,
});

export const rootReducer = (state, action) => combinedReducers(
  action.type === 'LOGOUT' ? undefined : state,
  action,
);
