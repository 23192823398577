/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddTelepacForm from '../../../Forms/AddTelepacForm';
import ModalEntity from '../../../ModalEntity';
import ExploitationService from '../../../../services/ExploitationService';
import PlotService from '../../../../services/PlotService';

function AddTelepac({ idCountry }) {
  const { t } = useTranslation('generics');
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(null);
  const [exploitations, setExploitations] = useState(null);
  const [datas, setDatas] = useState(idCountry === 1 ? (
    { exploitation_id: null, xml: null, txt: null }
  ) : (
    { exploitation_id: null, xml: null }
  ));

  const formUpdated = data => {
    setDatas({ ...datas, [data.champ]: data.value });
  };

  useEffect(() => {
    ExploitationService.getAll()
      .then(response => {
        const formatedData = [];

        response.data.exploitations.forEach(val => {
          formatedData.push({ label: val.company, value: val.id });
        });

        setExploitations(formatedData);
        formUpdated({ champ: 'exploitation_id', value: formatedData[0].value });
      })
      .catch(() => setError(t('pages.plot.telepac.apiError')));
  }, []);

  const submit = () => {
    setError(null);
    if (idCountry === 1 && !datas.txt) {
      setError(t('pages.plot.telepac.missingField'));
      return;
    }
    if (datas.xml && datas.exploitation_id) {
      PlotService.importTelepac(datas)
        .then(response => {
          if (response.status === 200) {
            setDatas({ ...datas, xml: null, txt: null });
            setVisible(false);
          } else {
            setError(t('pages.plot.plot.fileError'));
          }
        })
        .catch(() => setError(t('pages.plot.plot.fileError')));
    } else {
      setError(t('pages.plot.telepac.missingField'));
    }
  };

  return (
    <div className='mt-2 w-100'>
      <button className='btn btn-yellow text-uppercase w-100' onClick={() => setVisible(true)}>
        {t('pages.plot.telepac.addTelepac')}
      </button>
      <ModalEntity title1={t('pages.plot.telepac.addTelepac')} visible={visible} onClose={() => setVisible(false)}>
        <AddTelepacForm
          idCountry={idCountry}
          onSubmit={submit}
          onChange={formUpdated}
          exploitations={exploitations}
          error={error}
        />
      </ModalEntity>
    </div>
  );
}

export default AddTelepac;
