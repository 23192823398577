import PropTypes from 'prop-types';
import React from 'react';
import v3 from 'uuid';

import { useTranslation } from 'react-i18next';
import CountryRow from './CountryRow';

const CountriesTable = ({ onChangeActivation, items }) => {
  const { t } = useTranslation('generics');

  return (
    <table className='table table-striped'>
      <thead className='thead-dark'>
        <tr>
          <th>{t('pages.parameters.countries.Country')}</th>
          <th>{t('pages.parameters.countries.Code')}</th>
          <th>{t('pages.parameters.countries.Activate')}</th>
        </tr>
      </thead>
      <tbody>
        {items.map(item => (
          <CountryRow
            data={item}
            key={v3()}
            onActivate={data => onChangeActivation(data)}
          />
        ))}
      </tbody>
    </table>
  );
};

CountriesTable.propTypes = {
  onChangeActivation: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    position: PropTypes.string,
    isActive: PropTypes.bool.isRequired,
  })).isRequired,
};

export default CountriesTable;
