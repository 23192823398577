import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import Button from './Button';
import ExploitationService from '../services/ExploitationService';
import InputWithLabel from './InputWithLabel';

const CLEAR_FORM_STATE = {
  id: null,
  company: '',
  firstname: '',
  lastname: '',
  postcode: '',
  city: '',
  email: '',
  campaign: '',
};

function EditExploitationForm({ onSave }) {
  const { t } = useTranslation('generics');
  const [data, setData] = useState(CLEAR_FORM_STATE);

  useEffect(() => {
    ExploitationService.on('editExploitation', explData => {
      const currentData = explData !== null ? explData : CLEAR_FORM_STATE;
      setData(currentData);
    });
  }, []);

  const handleOnChange = (field, val) => {
    setData({ ...data, [field]: val });
  };

  return (
    <div className='container-fluid'>
      {data && (
        <form>
          <div className='row'>
            <div className='col-md-6 py-1'>
              <InputWithLabel
                label={t('pages.profile.organization.modal.fields.Company')}
                name='company'
                value={data.company || ''}
                onChange={val => handleOnChange('company', val)}
              />
            </div>
            <div className='col-md-6 py-1'>
              <InputWithLabel
                label={t('pages.profile.organization.modal.fields.Firstname')}
                name='firstname'
                value={data.firstname || ''}
                onChange={val => handleOnChange('firstname', val)}
              />
            </div>
            <div className='col-md-6 py-1'>
              <InputWithLabel
                label={t('pages.profile.organization.modal.fields.Lastname')}
                name='lastname'
                value={data.lastname || ''}
                onChange={val => handleOnChange('lastname', val)}
              />
            </div>
            <div className='col-md-6 py-1'>
              <InputWithLabel
                label={t('pages.profile.organization.modal.fields.Postal code')}
                name='postcode'
                value={data.postcode || ''}
                onChange={val => handleOnChange('postcode', val)}
              />
            </div>
            <div className='col-md-6 py-1'>
              <InputWithLabel
                label={t('pages.profile.organization.modal.fields.City')}
                name='city'
                value={data.city || ''}
                onChange={val => handleOnChange('city', val)}
              />
            </div>
            <div className='col-md-6 py-1'>
              <InputWithLabel
                label={t('pages.profile.organization.modal.fields.Email')}
                name='email'
                value={data.email || ''}
                onChange={val => handleOnChange('email', val)}
                type='email'
              />
            </div>
          </div>
          <div className='row'>
            <div className='text-center col-12'>
              <Button title={t('form.Save')} onClick={() => onSave(data)} />
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

EditExploitationForm.propTypes = {
  onSave: PropTypes.func.isRequired,
};

export default EditExploitationForm;
