/* eslint-disable no-console */
import PropTypes from 'prop-types';
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { LoadScript, GoogleMap, Polygon, DrawingManager, Marker } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import v3 from 'uuid';
import Button from '../../../../Button';
import PlotMapLoading from './PlotMapLoading';
import { mapDefaultZoom } from '../../../../../domain/constants';
import PlotService from '../../../../../services/PlotService';
import { CENTER_BOUNDS_POSITION, GOOGLE_MAPS_OPTIONS, POLYGON_OPTIONS } from './constants';

const markerIcon = {
  path: 'M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0',
  fillOpacity: 0.4,
  strokeWeight: 1,
  strokeOpacity: 0.8,
  scale: 0.3,
};
const referenceMarkerColor = '#8000ff';
const noReferenceMarkerColor = '#ff8400';

function PlotOneMap({ plot, polygoneEditable, setPolygonEditable, measuresDetails }) {
  const { t, i18n } = useTranslation('generics');
  const [path, setPath] = useState([]);
  const [definePath, setDefinePath] = useState(false);

  // Define ref for google maps
  const mapsRef = useRef(null);

  // Define refs for Polygon instance, Drawer Polygon instance and listeners
  const polygonRef = useRef(null);
  const drawerPolygonRef = useRef(null);
  const listenersRef = useRef([]);

  // Method for make bounds
  // ----------------------------------------------------------------------------
  const makeBounds = useCallback(() => {
    if (mapsRef.current && window.google) {
      const bounds = new window.google.maps.LatLngBounds();

      if (path && path.length >= 1) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < path.length; i++) {
          bounds.extend(path[i]);
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < CENTER_BOUNDS_POSITION.length; i++) {
          bounds.extend(CENTER_BOUNDS_POSITION[i]);
        }
      }

      mapsRef.current.fitBounds(bounds);
      mapsRef.current.setCenter(bounds.getCenter());
    }
  }, [path]);

  // Edit path
  // ----------------------------------------------------------------------------
  const onEditPath = useCallback(() => {
    setPolygonEditable(true);
    setTimeout(() => {
      makeBounds();
    }, 100);
  }, [makeBounds, setPolygonEditable]);

  // Save path after edit
  // ----------------------------------------------------------------------------
  const onSavePath = useCallback(() => {
    const apiPolygoneData = path.map(p => [p.lng, p.lat]);
    PlotService.edit(
      plot.id,
      { polygon: JSON.stringify(apiPolygoneData) },
    )
      .then(() => {
        setPolygonEditable(false);
        setTimeout(() => {
          makeBounds();
        }, 100);
      })
      .catch(err => console.error('PlotMap', err));
  }, [makeBounds, path, plot.id, setPolygonEditable]);

  // Define and draw path
  // ----------------------------------------------------------------------------
  const onDefinePath = useCallback(() => {
    setDefinePath(true);
  }, []);

  // Save define path
  // ----------------------------------------------------------------------------
  const onSaveDefinePath = useCallback(() => {
    setDefinePath(false);

    if (drawerPolygonRef.current) {
      const drawerPolygone = drawerPolygonRef.current;
      // Get polygon path
      const polygonPath = drawerPolygone
        .getPath()
        .getArray()
        .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }));

      // Save current path
      const apiPolygoneData = polygonPath.map(p => [p.lng, p.lat]);
      PlotService.edit(
        plot.id,
        { polygon: JSON.stringify(apiPolygoneData) },
      )
        .then(() => {
          // Save current path for edit this
          setPath(polygonPath);

          // Remove current shape
          drawerPolygone.setMap(null);
        })
        .catch(err => console.error('PlotMap', err));
    }
  }, [plot.id]);

  // Call setPath with new edited path
  // ----------------------------------------------------------------------------
  const onEditPolygon = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }));
      setPath(nextPath);
    }
  }, [setPath]);

  // Bind refs to current Polygon and listeners
  // ----------------------------------------------------------------------------
  const onLoadPolygon = useCallback(
    polygon => {
      polygonRef.current = polygon;
      const currentPath = polygon.getPath();
      listenersRef.current.push(
        currentPath?.addListener('set_at', onEditPolygon),
        currentPath?.addListener('insert_at', onEditPolygon),
        currentPath?.addListener('remove_at', onEditPolygon),
      );
    },
    [onEditPolygon],
  );

  // Clean up refs
  // ----------------------------------------------------------------------------
  const onUnmountPolygon = useCallback(() => {
    listenersRef.current.forEach(listener => listener.remove());
    polygonRef.current = null;
  }, []);

  // On load drawer manager
  // ----------------------------------------------------------------------------
  const onUnloadDrawer = () => {
    drawerPolygonRef.current = null;
  };

  // On polygon is complete
  // ----------------------------------------------------------------------------
  const onPolygonCompleteDrawing = polygon => {
    drawerPolygonRef.current = polygon;
  };

  // On load for google map
  // ----------------------------------------------------------------------------
  const onLoadMap = useCallback(map => {
    if (map) {
      mapsRef.current = map;
      makeBounds();
    }
  }, [makeBounds]);

  // On unmount for google map
  // ----------------------------------------------------------------------------
  const onUnmountMap = useCallback(() => {
    if (mapsRef.current) {
      mapsRef.current = null;
    }
  }, []);

  // Load path on mounted
  // ----------------------------------------------------------------------------
  useEffect(() => {
    if (plot.polygon && plot.polygon.length) {
      const data = JSON.parse(plot.polygon);
      const tempPath = data.map(latLng => ({
        lat: latLng[1],
        lng: latLng[0],
      }));
      setPath(tempPath);
    } else {
      setPath([]);
    }
  }, [plot, plot.polygon]);

  // Call make bounds if change plot
  // ----------------------------------------------------------------------------
  useEffect(() => {
    makeBounds();
  }, [makeBounds, plot.id]);

  // Render
  // ----------------------------------------------------------------------------
  return (
    <div className='col-12 px-0'>
      <div className='plot-map-actions'>
        {
              path.length >= 1 && (
                <>
                    {
                      polygoneEditable ? (
                        <Button title={t('form.Save change')} onClick={onSavePath} theme='primary' />
                      ) : (
                        <Button title={t('pages.plot.maps.Edit path')} onClick={onEditPath} theme='primary' />
                      )
                    }
                </>
              )
          }
        {
              path.length < 1 && (
                <>
                    {
                        definePath ? (
                          <Button title={t('form.Save change')} onClick={onSaveDefinePath} theme='primary' />
                        ) : (
                          <Button title={t('pages.plot.maps.Define path')} onClick={onDefinePath} theme='primary' />
                        )
                    }
                </>
              )
          }
      </div>
      <LoadScript
        id='script-loader'
        libraries={['drawing']}
        language={i18n.language}
        loadingElement={<PlotMapLoading />}
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
        preventGoogleFonts
      >
        <GoogleMap
          mapContainerClassName='plot-map'
          version='weekly'
          onLoad={onLoadMap}
          onUnmount={onUnmountMap}
          zoom={mapDefaultZoom}
          options={GOOGLE_MAPS_OPTIONS}
          mapContainerStyle={{
            height: polygoneEditable || definePath ? '90vh' : 400,
          }}
          on
        >
          {
            path?.length >= 1 && (
              <Polygon
                // Make the Polygon editable / draggable
                editable={polygoneEditable}
                paths={path}
                // Event used when manipulating and adding points
                onMouseUp={onEditPolygon}
                // Event used when dragging the whole Polygon
                onDragEnd={onEditPolygon}
                onLoad={onLoadPolygon}
                onUnmount={onUnmountPolygon}
                options={{
                  ...POLYGON_OPTIONS,
                  clickable: false,
                }}
              />
            )
          }
          {
            (path?.length < 1 && definePath) && (
              <DrawingManager
                onUnmount={onUnloadDrawer}
                onPolygonComplete={onPolygonCompleteDrawing}
                options={{
                  polygonOptions: {
                    ...POLYGON_OPTIONS,
                    clickable: false,
                  },
                  drawingControlOptions: {
                    drawingModes: ['polygon'],
                  },
                }}
              />
            )
          }
          {
            measuresDetails !== null ? (measuresDetails?.measures?.length >= 1)
              && measuresDetails?.measures?.map(measure => {
                if (measure.startCoordinates[0] !== 0 && measure.startCoordinates[1] !== 0) {
                  return (
                    <Marker
                      clickable={false}
                      icon={{
                        ...markerIcon,
                        fillColor: measure.areaReference
                          ? referenceMarkerColor
                          : noReferenceMarkerColor,
                        strokeColor: measure.areaReference
                          ? referenceMarkerColor
                          : noReferenceMarkerColor,
                      }}
                      key={v3()}
                      position={{
                        lat: measure.startCoordinates[1],
                        lng: measure.startCoordinates[0],
                      }}
                    />
                  );
                }
                return null;
              }
            )
              : (plot?.pilotage[0]?.measureGroup?.length >= 1)
                && plot?.pilotage[0]?.measureGroup?.map(measureGp => {
                  if (measureGp.measures.length >= 1) {
                    return measureGp?.measures.map(measure => {
                      if (measure.startCoordinates[0] !== 0 && measure.startCoordinates[1] !== 0) {
                        return (
                          <Marker
                            clickable={false}
                            icon={{
                              ...markerIcon,
                              fillColor: measure.areaReference
                                ? referenceMarkerColor
                                : noReferenceMarkerColor,
                              strokeColor: measure.areaReference
                                ? referenceMarkerColor
                                : noReferenceMarkerColor,
                            }}
                            key={v3()}
                            position={{
                              lat: measure.startCoordinates[1],
                              lng: measure.startCoordinates[0],
                            }}
                          />
                        );
                      }
                      return null;
                    });
                  }
                  return null;
                })
           }
        </GoogleMap>
      </LoadScript>
    </div>
  );
}

PlotOneMap.displayName = 'Borealis-Plot-One-Map';
PlotOneMap.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  plot: PropTypes.object.isRequired,
  polygoneEditable: PropTypes.bool.isRequired,
  setPolygonEditable: PropTypes.func.isRequired,
};

export default PlotOneMap;
