import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import Button from '../Button';
import InputImageWithLabel from '../InputImageWithLabel';
import InputWithLabel from '../InputWithLabel';
import OrganizationService from '../../services/OrganizationService';
import ModalEntity from '../ModalEntity';
import AddUserForm from '../Forms/AddUserForm';

const OrganizationDetails = ({ data, onError, onSuccess, adminMode }) => {
  const { t } = useTranslation('generics');
  const [orgData, setOrgData] = useState(data);
  const [visible, setVisible] = useState(false);

  const handleFieldChange = (field, value) => {
    setOrgData({
      ...orgData,
      [field]: value,
    });
  };

  const handleAddLogo = file => {
    setOrgData({
      ...orgData,
      newLogo: file,
    });
  };

  const handleSave = () => {
    let formData = {
      id: orgData.id,
      name: orgData.name,
      company: orgData.company,
      phone: orgData.phone,
      address1: orgData.address1,
      city: orgData.city,
      email: orgData.email,
      postcode: orgData.postcode,
    };

    if (orgData.newLogo) formData = { ...formData, logo: orgData.newLogo };

    OrganizationService.edit(formData)
      .then(() => onSuccess())
      .catch(err => onError(err.message));
  };

  return (
    <div className='row px-5'>
      <div className='col-md-3 py-3'>
        <InputWithLabel
          label={t('pages.profile.organization.fields.GroupName')}
          name='name'
          onChange={val => handleFieldChange('name', val)}
          value={orgData.name}
        />
      </div>
      <div className='col-md-3 py-3'>
        <InputWithLabel
          label={t('pages.profile.organization.fields.Company')}
          name='company'
          onChange={val => handleFieldChange('company', val)}
          value={orgData.company}
        />
      </div>
      <div className='col-md-3 py-3'>
        <InputWithLabel
          label={t('pages.profile.organization.fields.phone')}
          name='phone'
          onChange={val => handleFieldChange('phone', val)}
          value={orgData.phone}
        />
      </div>
      <div className='col-md-3 py-3'>
        <InputWithLabel
          label={t('pages.profile.organization.fields.address')}
          name='address1'
          onChange={val => handleFieldChange('address1', val)}
          value={orgData.address1}
        />
      </div>
      <div className='col-md-3 py-3'>
        <InputWithLabel
          label={t('pages.profile.organization.fields.Postal code')}
          name='postcode'
          onChange={val => handleFieldChange('postcode', val)}
          value={orgData.postcode}
        />
      </div>
      <div className='col-md-3 py-3'>
        <InputWithLabel
          label={t('pages.profile.organization.fields.City')}
          name='city'
          onChange={val => handleFieldChange('city', val)}
          value={orgData.city}
        />
      </div>
      <div className='col-md-3 py-3'>
        <InputWithLabel
          label={t('pages.profile.organization.fields.Email')}
          name='email'
          onChange={val => handleFieldChange('email', val)}
          type='email'
          value={orgData.email}
        />
      </div>
      <div className='col-md-3 py-3'>
        <InputImageWithLabel
          label={t('pages.profile.organization.fields.Logo')}
          name='logo'
          value={orgData.logo}
          onChange={logo => handleAddLogo(logo)}
        />
      </div>
      { !adminMode && (
        <div className='col-md-12 text-center'>
          <Button onClick={() => setVisible(true)} title={t('pages.profile.organization.modal.Add a user')} />
          <Button onClick={() => handleSave()} title={t('form.Save change')} />
        </div>
      )}

      <ModalEntity title1={t('form.register')} visible={visible} onClose={() => setVisible(false)}>
        <AddUserForm closeModal={() => setVisible(false)} organization={data.id} />
      </ModalEntity>
    </div>
  );
};

OrganizationDetails.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    postcode: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default OrganizationDetails;
