import EventEmitter from '../domain/EventEmitter';
import { API } from '../api/request';

class PilotService extends EventEmitter {
  fetchAll = () => API.get('front/pilots/all');

  delete = id => API.delete(`front/pilots/${id}`);

  edit = data => API.patch(`front/pilots/${data.id}`, data, {
    headers: { 'Content-Type': 'application/merge-patch+json' },
  });
}

const instance = new PilotService();

export default instance;
