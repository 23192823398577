import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import HeaderAction from '../components/HeaderAction';
import ReportsFilters from '../containers/ReportsFilters';
import ReportsService from '../services/ReportsService';
import Results from '../components/Reports/Results';
import Sidebar from '../components/Sidebar';

import icReports from '../images/ic_page_reports.png';
import reportsImage from '../images/header_reports.png';

const Reports = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    ReportsService.on('resultReceived', results => {
      setData(results);
    });
  }, []);

  const { t } = useTranslation('generics');

  return (
    <div className='page'>
      <Sidebar title={t('pages.reports.Reports')} icon={icReports}>
        <ReportsFilters onSearch={datas => {
          setData(null);
          ReportsService.getData(datas);
        }}
        />
      </Sidebar>
      <div className='page-content p-0'>
        <HeaderAction
          action={t('pages.reports.Choose your data')}
          description={t('pages.reports.Select the filters in the left sidebar')}
          image={reportsImage}
        />
        <Results data={data} />
      </div>
    </div>
  );
};

export default Reports;
