import React from 'react';
import v3 from 'uuid';

// import { useTranslation } from 'react-i18next';
import UserItem from './UserItem';

// eslint-disable-next-line arrow-body-style
const UserTable = ({ users, changeActiveUser, deleteUser }) => {
  // const { t } = useTranslation('generics');
  // TRADUCTION

  return (
    <table className='table table-striped my-4'>
      <thead className='bg-light text-info font-weight-light'>
        <tr>
          <th>Email</th>
          <th>Login</th>
          <th>N-pilot</th>
          <th>Active</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {users.map(user => (
          <UserItem
            data={user}
            key={v3()}
            changeActiveUser={(id, value) => changeActiveUser(id, value)}
            deleteUser={deleteUser}
          />
        ))}
      </tbody>
    </table>
  );
};

export default UserTable;
