import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import icDelete from '../../images/ic_delete.png';

const OrganizationRow = ({ data, onDelete }) => (
  <tr>
    <td>{data.id}</td>
    <td>
      <Link
        title={data.name}
        to={`/parameters/group/${data.id}`}
      >
        {data.name}
      </Link>
    </td>
    <td>{data.company}</td>
    <td>{data.usersNumber}</td>
    <td>{data.exploitationsNumber}</td>
    <td className='text-right'>
      <button className='delete btn btn-icon px-2' onClick={() => onDelete()}>
        <img src={icDelete} alt='icon delete' width='11' height='auto' />
      </button>
    </td>
  </tr>
);

OrganizationRow.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    usersNumber: PropTypes.number.isRequired,
    exploitationsNumber: PropTypes.number.isRequired,
  }),
  onDelete: PropTypes.func.isRequired,
};

export default OrganizationRow;
