import PropTypes from 'prop-types';
import React from 'react';

import { useTranslation } from 'react-i18next';
import icWelcome from '../images/ic_welcome_block.png';

const WelcomeBlock = ({ children, username = '' }) => {
  const { t } = useTranslation('generics');

  return (
    <div className='welcome-block px-4 pb-4 mt-4 border text-info'>
      <div className='h1 font-weight-bold text-center w-100'>
        <span className='d-inline-block bg-white'>{t('Welcome')}</span>
      </div>
      <div className='username font-weight-light'>
        <img src={icWelcome} alt='icon user' width='29' height='auto' className='mr-4' />
        {username}
      </div>
      <div className='pt-4'>
        {children}
      </div>
    </div>
  );
};

WelcomeBlock.propTypes = {
  username: PropTypes.string,
};

export default WelcomeBlock;
