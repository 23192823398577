import React from 'react';

import { useTranslation } from 'react-i18next';
import AddExploitation from '../components/AddExploitation';
import AddPlot from '../components/pages/Plot/Add/AddPlot';
import PlotFilters from '../components/pages/Plot/Selector/PlotFilters';
import LangSelector from '../components/LangSelector';
import QuickAccess from '../components/QuickAccess';
import WelcomeBlock from '../containers/WelcomeBlock';

import imgPlot from '../images/access_plot.png';
import imgReports from '../images/access_reports.png';
import imgSynthesis from '../images/access_synthesis.png';
import logo from '../images/logo_app.png';
import { PlotProvider } from '../components/pages/Plot/Plot.Context';


function Welcome() {
  const { t } = useTranslation('generics');
  const plotLinks = [
    { text: t('form.Manage'), path: '/plot' },
  ];

  const reportsLinks = [
    { text: t('form.Consult'), path: '/reports' },
  ];

  const synthesisLinks = [
    { text: t('form.Consult'), path: '/synthesis' },
  ];

  return (
    <div className='vh-100'>
      <header className='container-fluid clearfix p-5 text-center text-lg-left'>
        <div className='float-lg-right mb-4 mb-lg-0'>
          <LangSelector />
        </div>
        <div className='float-lg-left'>
          <a href='https://www.lat-nitrogen.com'>
            <img className='app-logo' src={logo} alt='N-pilot logo' />
          </a>
        </div>
      </header>
      <div className='container pt-5'>
        <div className='row'>
          <div className='col-md-4 mb-4'>
            <WelcomeBlock>
              <PlotFilters />
              <AddExploitation />
            </WelcomeBlock>
          </div>
          <div className='col-md-8 mb-4'>
            <QuickAccess title={t('Plot')} links={plotLinks} image={imgPlot}>
              <AddPlot withoutMargin={false} homePage />
            </QuickAccess>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-6 mb-4'>
            <QuickAccess title={t('Reports')} links={reportsLinks} image={imgReports} />
          </div>
          <div className='col-md-6'>
            <QuickAccess title={t('Synthesis')} links={synthesisLinks} image={imgSynthesis} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
