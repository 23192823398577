import PropTypes from 'prop-types';
import React from 'react';

import ModalHeader from './ModalHeader';

const ModalEntity = ({ children, onClose, title1, title2 = '', visible = false }) => {
  const visibilityClassName = visible ? 'd-block' : '';

  return (
    <div className={`hc_modal modal ${visibilityClassName} text-left`} tabIndex='-1' role='dialog'>
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content px-3 pb-3'>
          <div className='text-right pr-2 actions'>
            <button type='button' className='close float-none' data-dismiss='modal' aria-label='Fermer' onClick={onClose}>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='bg-light mt-4 px-4'>
            <ModalHeader line1={title1} line2={title2} />
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

ModalEntity.propTypes = {
  onClose: PropTypes.func,
  title1: PropTypes.string.isRequired,
  title2: PropTypes.string,
  visible: PropTypes.bool,
};

export default ModalEntity;
