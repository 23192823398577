/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import set from 'lodash/fp/set';

import PlotMeasureDetails from './measures/PlotMeasureDetails';
import PlotContent from './PlotContent';
import PlotOneMap from './map/PlotOneMap';
import PlotGlobalMap from './map/PlotGlobalMap';
import InputSelect from '../../../InputSelect';
import Input from '../../../Input';
import PlotService from '../../../../services/PlotService';
import { PlotContext } from '../Plot.Context';

const PlotDetails = ({ editedMode, setEditedMode, polygoneEditable, setPolygonEditable }) => {
  const { selectedPlotDetail } = useContext(PlotContext)
  const { filter } = useContext(PlotContext)
  const { t } = useTranslation('generics');
  const [measureDetails, setMeasure] = useState(null);
  const [error, setError] = useState(null);
  const { editFieldSelectedPlot } = useContext(PlotContext)
  const { plotsTest, setPlotsTest } = useContext(PlotContext)

  const formatData = () => (
    set('organization[0].exploitation[0].plot[0].pilotage[0].measureGroup', [measureDetails], selectedPlotDetail)
  );

  useEffect(() => {
    setMeasure(null);
  }, [selectedPlotDetail]);

  // Global plot handle change
  const handleChange = (field, value, onlyNumber = false) => {
	console.log(value)    
if (onlyNumber && Number.isNaN(value)) {
      return;
    }
	console.log(selectedPlotDetail)
    if (field === 'organization[0].exploitation[0].plot[0].pilotage[0].cropName') {
      editFieldSelectedPlot('organization[0].exploitation[0].plot[0].pilotage[0].univers', 
      selectedPlotDetail?.crops.filter(c => (c.label.replace("  ", " ") === value))[0].univers, 
      selectedPlotDetail?.crops.filter(c => (c.label.replace("  ", " ") === value))[0].univers)
      editFieldSelectedPlot('organization[0].exploitation[0].plot[0].pilotage[0].varietyName', 
      selectedPlotDetail?.crops.filter(c => (c.label.replace("  ", " ") === value))[0]?.varieties[0]?.label || null, 
      selectedPlotDetail?.organization[0].exploitation[0].plot[0])


    }

    editFieldSelectedPlot(field, 
      value, 
      selectedPlotDetail?.organization[0].exploitation[0].plot[0])
  };

  // Global plot handle submit
  const handleSubmit = () => {
    setEditedMode(false);

    const data = {
      name: selectedPlotDetail?.organization[0].exploitation[0].plot[0].name,
      surface: selectedPlotDetail?.organization[0].exploitation[0].plot[0].surface,
      texture: selectedPlotDetail?.organization[0].exploitation[0].plot[0].texture?.id ? selectedPlotDetail?.organization[0].exploitation[0].plot[0].texture?.id : selectedPlotDetail?.organization[0].exploitation[0].plot[0].texture,
      ilot: selectedPlotDetail?.organization[0].exploitation[0].plot[0].ilot,
      stone: selectedPlotDetail?.organization[0].exploitation[0].plot[0].stone,
      organicMaterial: selectedPlotDetail?.organization[0].exploitation[0].plot[0].organicMaterial,
      groundDepth: selectedPlotDetail?.organization[0].exploitation[0].plot[0].groundDepth,
      campaign : filter.campaign,
    };
    if (selectedPlotDetail?.crops) {
      data.cropId = selectedPlotDetail?.crops?.filter(c => (
        c.label.replace("  ", " ") === selectedPlotDetail?.organization[0]?.exploitation[0]?.plot[0]?.pilotage[0]?.cropName
      ))[0]?.val || selectedPlotDetail?.crops[0]?.val || null;
      data.universId = selectedPlotDetail?.organization[0]?.exploitation[0]?.plot[0]?.pilotage[0]?.univers || null;
      data.varietyName = selectedPlotDetail?.organization[0]?.exploitation[0]?.plot[0]?.pilotage[0]?.varietyName || selectedPlotDetail?.crops[0]?.varieties[0]?.val || null;
      data.overallDose = selectedPlotDetail?.organization[0]?.exploitation[0]?.plot[0]?.pilotage[0]?.overallDose || null;
      data.performanceObjective = selectedPlotDetail?.organization[0]?.exploitation[0]?.plot[0]?.pilotage[0]?.performanceObjective || null;
    }

    PlotService.edit(selectedPlotDetail?.organization[0].exploitation[0].plot[0].id, data)
      .then(() => {
        PlotService.emit('pages.plot.edited');
        setError(null);
        plotsTest.forEach((plot, idx) => {
          if(selectedPlotDetail.organization[0].exploitation[0].plot[0].id === plot.id) {
            console.log(selectedPlotDetail)
            let editedPlots = [...plotsTest]
            editedPlots[idx] = selectedPlotDetail.organization[0].exploitation[0].plot[0]
            console.log(editedPlots)
            setPlotsTest([...editedPlots])
          }
        })
      })
      .catch(err => setError(err.message));
  };

  // Render
  return selectedPlotDetail?.organization[0].exploitation[0].plot[0].id ? (
    <div>
      <div className='plot-details container-fluid p-0'>
        <div className='bg-dark row plot-map-container'>
          <PlotOneMap
            plot={selectedPlotDetail?.organization[0].exploitation[0].plot[0]}
            polygoneEditable={polygoneEditable}
            setPolygonEditable={setPolygonEditable}
            measuresDetails={measureDetails}
          />
        </div>
        {error && (
          <div className='row plot-error m-0'>
            {error}
          </div>
        )}
        <div className='row bg-light mb-3 px-lg-0 plot-content'>
          <div className='col-lg-3 p-5'>
            <div className='text-primary'>{t('pages.plot.labels.Name')}</div>
            {
              !editedMode
                ? <div className='h4 text-info'><b>{selectedPlotDetail?.organization[0].exploitation[0].plot[0].name}</b></div>
                : (
                  <Input
                    className='plot-input'
                    name='name'
                    placeholder={t('pages.plot.modal.Name')}
                    onChange={val => handleChange('organization[0].exploitation[0].plot[0].name', val)}
                    value={selectedPlotDetail?.organization[0].exploitation[0].plot[0].name}
                  />
                )
            }
          </div>
          <div className='col-lg-3 p-5 border-left'>
            <div className='text-primary'>{t('pages.plot.labels.Surface')}</div>
            {
              !editedMode
                ? <div className='h4 text-info'><b>{parseFloat(selectedPlotDetail?.organization[0].exploitation[0].plot[0].surface).toFixed(2)} ha</b></div>
                : (
                  <div className='d-flex align-items-center p-0'>
                    <div className='flex-1'>
                      <Input
                        className='plot-input'
                        type='number'
                        step={0.1}
                        min={0}
                        name='surface'
                        placeholder={t('pages.plot.modal.Surface')}
                        onChange={val => handleChange('organization[0].exploitation[0].plot[0].surface', val, true)}
                        value={selectedPlotDetail?.organization[0].exploitation[0].plot[0].surface}
                      />
                    </div>
                    <div className='text-info ml-3'><b>ha</b></div>
                  </div>
                )
            }
          </div>
          <div className='col-lg-3 p-5 border-left mb-lg-3'>
            <div className='text-primary'>{t('pages.plot.labels.Crop')}</div>
            {
              !editedMode || !selectedPlotDetail?.crops
                ? (
                  <div className='h4 text-info'>
                    <b>
                      {
                        selectedPlotDetail?.organization[0].exploitation[0].plot[0].pilotage
                          && selectedPlotDetail?.organization[0].exploitation[0].plot[0].pilotage[0] ? (
                            selectedPlotDetail?.organization[0].exploitation[0].plot[0].pilotage[0].cropName
                          ) : ('')
                      }
                    </b>
                  </div>
                )
                : (
                  <InputSelect
                    className='plot-input'
                    name='cropName'
                    onChange={val => handleChange('organization[0].exploitation[0].plot[0].pilotage[0].cropName', val)}
                    options={selectedPlotDetail?.crops}
                    value={selectedPlotDetail?.organization[0]?.exploitation[0]?.plot[0]?.pilotage[0]?.cropName || selectedPlotDetail?.crops[0]?.val || 0}
                  />
                )
            }
          </div>
          <div className='col-lg-3 p-5 border-left'>
            <div className='text-primary'>{t('pages.plot.labels.Variety')}</div>
            {
              !editedMode || !selectedPlotDetail?.crops
                ? (
                  <div className='h4 text-info'>
                    <b>{selectedPlotDetail?.organization[0].exploitation[0].plot[0].pilotage && selectedPlotDetail?.organization[0].exploitation[0].plot[0].pilotage[0] ? selectedPlotDetail?.organization[0].exploitation[0].plot[0].pilotage[0].varietyName : ''}</b>
                  </div>
                )
                : (
                  <InputSelect
                    className='plot-input'
                    name='variety'
                    onChange={val => handleChange('organization[0].exploitation[0].plot[0].pilotage[0].varietyName', val)}
                    options={selectedPlotDetail?.crops.filter(c => (
                      c.label.replace("  ", " ") === selectedPlotDetail?.organization[0]?.exploitation[0]?.plot[0]?.pilotage[0]?.cropName
                    ))[0]?.varieties || selectedPlotDetail?.crops[0]?.varieties || []}
                    value={selectedPlotDetail?.organization[0]?.exploitation[0]?.plot[0]?.pilotage[0]?.varietyName || selectedPlotDetail?.crops[0]?.varieties[0]?.val || 0 }
                  />
                )
            }
          </div>
        </div>
      </div>
      {measureDetails ? (
        <PlotMeasureDetails
          data={formatData()}
          idUnivers={selectedPlotDetail?.organization[0].exploitation[0].plot[0].pilotage[0].univers}
          onReturn={() => setMeasure(null)}
        />
      ) : (
        <PlotContent
          data={selectedPlotDetail}
          onSelectMeasure={data => {
            setMeasure(data);
          }}
          editedMode={editedMode}
          setEditedMode={setEditedMode}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
        />
      )}
    </div>
  ) : <PlotGlobalMap />;
};

PlotDetails.displayName = 'Borealis-Plot-Details';
PlotDetails.propTypes = {
  editedMode: PropTypes.bool.isRequired,
  polygoneEditable: PropTypes.bool.isRequired,
  setEditedMode: PropTypes.func.isRequired,
  setPolygonEditable: PropTypes.func.isRequired,
};

export default PlotDetails;
